import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel8Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_8_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm.008 17.192c-.939 0-1.76-.139-2.464-.416-.704-.288-1.254-.688-1.648-1.2-.395-.523-.592-1.13-.592-1.824s.192-1.28.576-1.76c.29-.36.663-.654 1.12-.882a3.043 3.043 0 0 1-.768-.67c-.352-.459-.528-1.003-.528-1.632 0-.661.181-1.227.544-1.696.362-.48.87-.848 1.52-1.104.65-.267 1.397-.4 2.24-.4.864 0 1.616.133 2.256.4.65.256 1.157.624 1.52 1.104.373.47.56 1.035.56 1.696 0 .63-.182 1.173-.544 1.632a2.888 2.888 0 0 1-.76.67c.46.228.83.522 1.112.882.394.48.592 1.067.592 1.76s-.198 1.301-.592 1.824c-.395.512-.95.912-1.664 1.2-.704.277-1.53.416-2.48.416zm0-1.904c.65 0 1.162-.144 1.536-.432.384-.288.576-.683.576-1.184s-.192-.89-.576-1.168c-.374-.288-.886-.432-1.536-.432-.64 0-1.147.144-1.52.432-.374.277-.56.667-.56 1.168s.186.896.56 1.184c.373.288.88.432 1.52.432zm0-4.992c.544 0 .97-.123 1.28-.368.32-.245.48-.581.48-1.008 0-.448-.166-.795-.496-1.04-.32-.245-.742-.368-1.264-.368-.512 0-.928.123-1.248.368-.32.245-.48.592-.48 1.04 0 .427.154.763.464 1.008.31.245.73.368 1.264.368zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm.008 17.192c-.939 0-1.76-.139-2.464-.416-.704-.288-1.254-.688-1.648-1.2-.395-.523-.592-1.13-.592-1.824s.192-1.28.576-1.76c.29-.36.663-.654 1.12-.882a3.043 3.043 0 0 1-.768-.67c-.352-.459-.528-1.003-.528-1.632 0-.661.181-1.227.544-1.696.362-.48.87-.848 1.52-1.104.65-.267 1.397-.4 2.24-.4.864 0 1.616.133 2.256.4.65.256 1.157.624 1.52 1.104.373.47.56 1.035.56 1.696 0 .63-.182 1.173-.544 1.632a2.888 2.888 0 0 1-.76.67c.46.228.83.522 1.112.882.394.48.592 1.067.592 1.76s-.198 1.301-.592 1.824c-.395.512-.95.912-1.664 1.2-.704.277-1.53.416-2.48.416zm0-1.904c.65 0 1.162-.144 1.536-.432.384-.288.576-.683.576-1.184s-.192-.89-.576-1.168c-.374-.288-.886-.432-1.536-.432-.64 0-1.147.144-1.52.432-.374.277-.56.667-.56 1.168s.186.896.56 1.184c.373.288.88.432 1.52.432zm0-4.992c.544 0 .97-.123 1.28-.368.32-.245.48-.581.48-1.008 0-.448-.166-.795-.496-1.04-.32-.245-.742-.368-1.264-.368-.512 0-.928.123-1.248.368-.32.245-.48.592-.48 1.04 0 .427.154.763.464 1.008.31.245.73.368 1.264.368zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_8_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel8Icon

