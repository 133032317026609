import BackIcon from './BackIcon'
import {
  AddFolderIcon,
  AlertIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AudioIcon,
  BadgeGradientIcon,
  BellIcon,
  BranchingIcon,
  CalendarIcon,
  CameraGradientIcon,
  CameraIcon,
  ChatNormalIcon,
  ChatUnreadIcon,
  ChevronIcon,
  CircleCheckmarkIcon,
  CircleWithIcons,
  CloseIcon,
  CommentIcon,
  CommunityIcon,
  CopyIcon,
  DebugIcon,
  DoorIcon,
  DoubleBarIcon,
  DownloadsOutlineIcon,
  DuplicateIcon,
  EllipseIcon,
  EmailIcon,
  ExercisePinIcon,
  ExercisesIcon,
  ExpandIcon,
  EyeCrossedIcon,
  EyeIcon,
  FbIcon,
  FilterIcon,
  FolderMoveIcon,
  GoogleClassroomIcon,
  GoogleIcon,
  GridIcon,
  HelpIcon,
  IgIcon,
  InfoIcon,
  JSIcon,
  LanguageIcon,
  Level10Icon,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  Level4Icon,
  Level5Icon,
  Level6Icon,
  Level7Icon,
  Level8Icon,
  Level9Icon,
  LevelAddGradientIcon,
  LevelGenericIcon,
  LinkIcon,
  ListIcon,
  LockGradientIcon,
  LogoutIcon,
  LongerArrowDownIcon,
  LongerArrowUpIcon,
  MapWithPinIcon,
  MessageFailIcon,
  MinusIcon,
  MoreIcon,
  MsTeamsIcon,
  MultipleUsersIcon,
  OrganizationGradientIcon,
  OrgLibraryIcon,
  PeopleIcon,
  PhotoIcon,
  PinAddIcon,
  PinFilledIcon,
  PinMultipleIcon,
  PinPlain,
  PlayIcon,
  PlusIcon,
  PodiumIcon,
  ProfileCircledIcon,
  ProfileIcon,
  RadarIcon,
  ReceiptIcon,
  RecentsIcon,
  ReportIcon,
  ScoreGradientIcon,
  ScoreHeaderIcon,
  ScoreIcon,
  SearchIcon,
  SelectMapIcon,
  SettingsGradientIcon,
  SettingsIcon,
  ShareIcon,
  StarIcon,
  StarOutlineIcon,
  TaskCreativeIcon,
  TaskFlashGradientIcon,
  TaskFlashIcon,
  TaskHideGradientIcon,
  TaskLocationLockedGradientIcon,
  TaskMatchPairsIcon,
  TaskMissingWordIcon,
  TaskMultipleChoiceIcon,
  TextFileIcon,
  TextIcon,
  TimerGradientIcon,
  TransparentStarIcon,
  TrashIcon,
  TripleBarIcon,
  TwitterIcon,
  UserIdleIcon,
  VideoIcon,
  ViewIcon,
} from './index'

const ICON_MAP = {
  addFolder: <AddFolderIcon color='currentColor' />,
  alert: <AlertIcon />,
  arrowDown: <ArrowDownIcon color='currentColor' />,
  arrowLeft: <ArrowLeftIcon />,
  arrowRight: <ArrowRightIcon color='currentColor' />,
  audio: <AudioIcon />,
  badgeGradient: <BadgeGradientIcon />,
  backIcon: <BackIcon color='currentColor' />,
  bell: <BellIcon color='currentColor' />,
  branching: <BranchingIcon color='currentColor' />,
  calendar: <CalendarIcon color='currentColor' />,
  camera: <CameraIcon />,
  cameraGradient: <CameraGradientIcon />,
  chatNormal: <ChatNormalIcon color='currentColor' />,
  chatUnread: <ChatUnreadIcon color='currentColor' />,
  chevron: <ChevronIcon color='currentColor' />,
  circleCheckmark: <CircleCheckmarkIcon color='currentColor' />,
  CircleWithIcons: <CircleWithIcons />,
  close: <CloseIcon color='currentColor' />,
  comment: <CommentIcon color='currentColor' />,
  community: <CommunityIcon color='currentColor' />,
  copy: <CopyIcon color='currentColor' />,
  debug: <DebugIcon />,
  door: <DoorIcon />,
  doubleBar: <DoubleBarIcon />,
  downloadsOutline: <DownloadsOutlineIcon color='currentColor' />,
  duplicate: <DuplicateIcon color='currentColor' />,
  ellipse: <EllipseIcon color='currentColor' />,
  email: <EmailIcon color='currentColor' />,
  exercisePin: <ExercisePinIcon />,
  exercises: <ExercisesIcon color='currentColor' />,
  expand: <ExpandIcon color='currentColor' />,
  eye: <EyeIcon />,
  eyeCrossed: <EyeCrossedIcon />,
  fb: <FbIcon color='currentColor' />,
  filter: <FilterIcon color='currentColor' />,
  folderMove: <FolderMoveIcon color='currentColor' />,
  google: <GoogleIcon />,
  googleClassroom: <GoogleClassroomIcon color='currentColor' />,
  grid: <GridIcon color='currentColor' />,
  help: <HelpIcon color='currentColor' />,
  ig: <IgIcon color='currentColor' />,
  info: <InfoIcon color='currentColor' />,
  js: <JSIcon color='currentColor' />,
  language: <LanguageIcon color='currentColor' />,
  level1: <Level1Icon />,
  level10: <Level10Icon />,
  level2: <Level2Icon />,
  level3: <Level3Icon />,
  level4: <Level4Icon />,
  level5: <Level5Icon />,
  level6: <Level6Icon />,
  level7: <Level7Icon />,
  level8: <Level8Icon />,
  level9: <Level9Icon />,
  levelAdd: <LevelAddGradientIcon />,
  levelGeneric: <LevelGenericIcon />,
  link: <LinkIcon color='currentColor' />,
  list: <ListIcon color='currentColor' />,
  lockGradient: <LockGradientIcon />,
  logout: <LogoutIcon color='currentColor' />,
  longerArrowDown: <LongerArrowDownIcon />,
  longerArrowUp: <LongerArrowUpIcon />,
  mapWithPin: <MapWithPinIcon color='currentColor' />,
  messageFail: <MessageFailIcon color='currentColor' />,
  minus: <MinusIcon color='currentColor' />,
  more: <MoreIcon color='currentColor' />,
  msTeams: <MsTeamsIcon color='currentColor' />,
  multipleUsers: <MultipleUsersIcon color='currentColor' />,
  organizationGradient: <OrganizationGradientIcon color='currentColor' />,
  orgLibrary: <OrgLibraryIcon color='currentColor' />,
  people: <PeopleIcon color='currentColor' />,
  photo: <PhotoIcon color='currentColor' />,
  pinAdd: <PinAddIcon color='currentColor' />,
  pinFilled: <PinFilledIcon color='currentColor' />,
  pinMultiple: <PinMultipleIcon color='currentColor' />,
  pinPlain: <PinPlain color='currentColor' />,
  play: <PlayIcon color='currentColor' />,
  plus: <PlusIcon color='currentColor' />,
  podium: <PodiumIcon color='currenColor' />,
  profile: <ProfileIcon color='currentColor' />,
  profileCircled: <ProfileCircledIcon color='currentColor' />,
  radar: <RadarIcon color='currentColor' />,
  receipt: <ReceiptIcon color='currentColor' />,
  recents: <RecentsIcon color='currentColor' />,
  reports: <ReportIcon color='currentColor' />,
  scoreGradient: <ScoreGradientIcon />,
  scoreHeader: <ScoreHeaderIcon color='currentColor' />,
  scoreIcon: <ScoreIcon color='currentColor' />,
  search: <SearchIcon color='currentColor' />,
  selectMap: <SelectMapIcon color='currentColor' />,
  settings: <SettingsIcon color='currentColor' />,
  settingsGradient: <SettingsGradientIcon />,
  share: <ShareIcon color='currentColor' />,
  star: <StarIcon color='currentColor' />,
  starOutline: <StarOutlineIcon color='currentColor' />,
  taskCreative: <TaskCreativeIcon />,
  taskFlash: <TaskFlashIcon />,
  taskFlashGradient: <TaskFlashGradientIcon />,
  taskHideGradient: <TaskHideGradientIcon />,
  taskLocationLockedGradient: <TaskLocationLockedGradientIcon />,
  taskMatchPairs: <TaskMatchPairsIcon />,
  taskMissingWord: <TaskMissingWordIcon />,
  taskMultipleChoice: <TaskMultipleChoiceIcon />,
  text: <TextIcon color='currentColor' />,
  textFile: <TextFileIcon color='currentColor' />,
  timerGradient: <TimerGradientIcon />,
  transparentStar: <TransparentStarIcon color='currentColor' />,
  trash: <TrashIcon color='currentColor' />,
  tripleBar: <TripleBarIcon />,
  twitter: <TwitterIcon color='currentColor' />,
  userIdle: <UserIdleIcon color='currentColor' />,
  video: <VideoIcon />,
  view: <ViewIcon color='currentColor' />,
} as const

export type IconName = keyof typeof ICON_MAP

export const getIcon = (iconName: IconName) => {
  return ICON_MAP[iconName]
}
