import classNames from 'classnames'
import mapboxgl from 'mapbox-gl'
import { TaskIconType } from '../../../../api/gameTypes'
import { Task, TaskConnection } from '../../../../types/commonTypes'
import { PIN_ANCHOR_Y_FIX, PIN_HEIGHT, PIN_WIDTH } from './DraggableMarker'
import styles from './DraggableMarker.module.css'
import { TaskPinRawSvgMap, getPinIdClass } from './TaskPinHelper'

const getIcon = (task: Task, isCrossing: boolean, isReachable: boolean) => {
  const iconElement = document.createElement('div')
  iconElement.className = getPinIdClass(task.id)
  iconElement.style.width = `${PIN_WIDTH}px`
  iconElement.style.height = `${PIN_HEIGHT}px`
  const innerElement = document.createElement('span')
  innerElement.innerHTML =
    TaskPinRawSvgMap[isCrossing ? TaskIconType.crossing : task.advanced.iconType || TaskIconType.seppo]
  innerElement.className = classNames(styles.icon, !isReachable && styles.notReachable, `noHoverId_${task.id}`)
  iconElement.appendChild(innerElement)
  return iconElement
}

let currentMarkers: mapboxgl.Marker[] = []

export const refreshDraggableMarkersToMap = (
  tasks: Task[],
  map: mapboxgl.Map,
  moveTask: (taskId: number, x: number, y: number) => Promise<void>,
  taskConnections: TaskConnection[],
  isReachable: (taskId: number) => boolean,
) => {
  currentMarkers.forEach((m) => {
    m.getElement().innerHTML = ''
    m.getElement().remove()
    m.remove()
  })
  currentMarkers = []
  tasks.forEach((aTask) => {
    const isCrossing = taskConnections.filter((conn) => conn.fromId === aTask.id).length > 1

    const marker = new mapboxgl.Marker(getIcon(aTask, isCrossing, isReachable(aTask.id)))
      .setLngLat([aTask.y, aTask.x])
      .addTo(map)
    marker.setDraggable(true)
    marker.setOffset([0, -(PIN_HEIGHT / 2 - PIN_ANCHOR_Y_FIX)])

    function onDragEnd() {
      const lngLat = marker.getLngLat()
      const id = parseInt(marker.getElement().className.split('_')[1])
      moveTask(id, lngLat.lat, lngLat.lng)
    }
    marker.on('dragend', onDragEnd)
    currentMarkers.push(marker)
  })
}
