import React, { useEffect, useMemo, useState } from 'react'
import { MapType } from '../../../../api/gameTypes'
import { useUser } from '../../../../contexts/userContext'
import { Game } from '../../../../types/commonTypes'
import { MapInstanceID } from '../../../../util/map'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { GameBoard } from '../../types'
import { MapboxContainer } from '../MapboxContainer/MapboxContainer'
import styles from './BoardSection.module.css'
import { PanoramaBoard } from './PanoramaBoard'
import { StaticBoard } from './StaticBoard'

export type BoardSectionProps = {
  activeBoardIndex: number
  gameData: Game | undefined
  isEdit: boolean
  forceBoardSectionRefresh: number
}

const getMissingMapUrl = (language: string) => {
  return `https://smartfeet-assets-staging.s3.amazonaws.com/assets/default_map_${language}.jpeg`
}

export const BoardSection: React.FC<BoardSectionProps> = ({
  activeBoardIndex,
  gameData,
  isEdit,
  forceBoardSectionRefresh,
}) => {
  const { user } = useUser()
  const [refreshDone, setRefreshDone] = useState(0)

  useEffect(() => {
    //Force the pannellum renderer of the main 360 map to reload
    //Simply adding random number to end of the board url is not enough, need to remove the element temporarily
    if (refreshDone !== forceBoardSectionRefresh) {
      setRefreshDone(forceBoardSectionRefresh)
    }
  }, [forceBoardSectionRefresh, refreshDone])

  const currentBoard = useMemo<GameBoard | null>(() => {
    if (isEdit && gameData == null) {
      return null
    }
    return gameData == null || safeIsNullOrEmpty(gameData?.gameBoardSettings?.gameBoards?.[0])
      ? {
          mapIndex: 0,
          url: getMissingMapUrl(user?.language ?? 'en'),
          fileName: '',
          name: '',
          dimensions: { x: 1394, y: 1045 },
        }
      : gameData.gameBoardSettings.gameBoards[activeBoardIndex]
  }, [activeBoardIndex, gameData, user?.language, isEdit])

  if (currentBoard == null) {
    return null
  }

  return gameData?.gameBoardSettings?.gameBoardType === MapType.LIVE ? (
    <div className={styles.mapboxContainer}>
      <MapboxContainer
        mapInstanceId={MapInstanceID.EDITOR}
        mapOptions={gameData.gameBoardSettings.mapOptions}
        boardType={gameData.gameBoardSettings.gameBoardType}
        showMapbox={gameData.gameBoardSettings.gameBoardType === MapType.LIVE}
      />
    </div>
  ) : gameData?.gameBoardSettings?.gameBoardType === MapType.PANORAMA ? (
    refreshDone === forceBoardSectionRefresh ? (
      <PanoramaBoard
        activeBoardIndex={activeBoardIndex}
        boardUrl={currentBoard.url + '?' + forceBoardSectionRefresh}
        currentBoard={currentBoard}
      />
    ) : (
      <div />
    )
  ) : (
    <StaticBoard
      activeBoardIndex={activeBoardIndex}
      board={currentBoard}
    />
  )
}
