import * as React from 'react'
import { SVGProps } from 'react'
const SvgTaskFlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 18 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M11.272.034A1 1 0 0 1 12 .996v11h3.636a2.364 2.364 0 0 1 2.013 3.603L7.852 31.52A1 1 0 0 1 6 30.996v-11H2.364a2.364 2.364 0 0 1-2.013-3.601L10.148.472a1 1 0 0 1 1.124-.438zM10 4.529 2.054 17.443a.363.363 0 0 0 .31.553H7a1 1 0 0 1 1 1v8.467l7.946-12.912a.364.364 0 0 0-.31-.555H11a1 1 0 0 1-1-1z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgTaskFlashIcon

