import * as React from 'react'
import { SVGProps } from 'react'
const SvgScoreCircledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={6.272} cy={6.748} r={6} fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.772 6.748a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-2.363-.743a.266.266 0 0 1 .234.185.265.265 0 0 1-.085.292l-1.03.891.31 1.328a.262.262 0 0 1-.104.279.284.284 0 0 1-.157.05.264.264 0 0 1-.14-.041l-1.165-.702-1.17.702a.274.274 0 0 1-.297-.014.278.278 0 0 1-.104-.279l.31-1.327-1.03-.891c-.08-.072-.112-.185-.08-.288a.266.266 0 0 1 .233-.185l1.36-.117.526-1.255c.085-.198.414-.198.5 0l.53 1.255 1.36.117Z'
      fill='#fff'
    />
  </svg>
)
export default SvgScoreCircledIcon

