import { PropsWithChildren } from 'react'
import { getIcon, IconName } from '../../../common/components/icons/utils'
import styles from './LoginCard.module.css'
import classNames from 'classnames'

type LoginCardProps = {
  icon: IconName
  title: string
  smallDeviceStyles?: boolean
}
export const LoginCard: React.FC<PropsWithChildren<LoginCardProps>> = ({
  children,
  icon,
  title,
  smallDeviceStyles,
}) => {
  return (
    <div className={classNames(styles.cardContainer, smallDeviceStyles && styles.cardContainerSmallDevice)}>
      <div>
        <span className={classNames(styles.icon, smallDeviceStyles && styles.iconSmallDevice)}>{getIcon(icon)}</span>
        <h1 className={smallDeviceStyles && styles.titleSmalDevice}>{title}</h1>
      </div>
      {children}
    </div>
  )
}
