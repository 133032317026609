import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import styles from './DirectChat.module.css'
import { Message } from '../Message/Message'
import { getDirectChat } from '../../helpers'
import { useUser } from '../../../../../../contexts/userContext'
import { Footer } from '../Footer/Footer'
import { MessageSubtypeEnum } from '../../../../../../api/messagingTypes'
import { useChat } from '../../../../../../contexts/ChatContextProvider'
import { TMessage } from '../../../../../../types/commonTypes'

type DirectChatProps = {
  messageText: string | undefined
  messages: TMessage[]
  selectedPersonId: number
  onSubmit: (messageSubtype: MessageSubtypeEnum, personId: number) => void
  onSubmitAudio: (messageSubtype: MessageSubtypeEnum, receiverId: number, audioUrl: string,) => void
  onChangeMessage: (message: string, receiverId: number) => void
  onDeleteMessage: (messageId: number) => void
}

export const DirectChat: React.FC<DirectChatProps> = ({
  messageText,
  messages,
  selectedPersonId,
  onChangeMessage,
  onSubmit,
  onSubmitAudio,
  onDeleteMessage,
}) => {
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)
  const latestMessageRef = useRef<HTMLLIElement | null>(null)
  const { user } = useUser()
  const { updateChatRoom } = useChat()

  useEffect(() => {
    setIsInitialLoad(false)
    updateChatRoom(selectedPersonId, true)
    return () => {
      if (selectedPersonId != null) {
        updateChatRoom(selectedPersonId, false)
      }
    }
  }, [updateChatRoom, messages, selectedPersonId])

  useLayoutEffect(() => {
    if (latestMessageRef.current != null && selectedPersonId != null) {
      latestMessageRef.current.scrollIntoView(
        isInitialLoad ? false : { behavior: 'smooth', block: 'end', inline: 'center' },
      )
    }
  }, [isInitialLoad, messages, selectedPersonId])

  const onChangeMessageInternal = useCallback(
    (messageText: string) => {
      onChangeMessage(messageText, selectedPersonId!)
    },
    [onChangeMessage, selectedPersonId],
  )

  const onSubmitInternal = useCallback(
    (messageSubtype: MessageSubtypeEnum) => (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSubmit(messageSubtype, selectedPersonId)
    },
    [onSubmit, selectedPersonId],
  )

  const onSubmitAudioInternal = useCallback(
    (messageSubtype: MessageSubtypeEnum) => (audioUrl: string) => {
      onSubmitAudio(messageSubtype, selectedPersonId, audioUrl)
    },
    [onSubmitAudio, selectedPersonId],
  )

  const onDeleteInternal = useCallback((messageId: number) => {
    onDeleteMessage(messageId)
  }, [onDeleteMessage])

  if (user == null) {
    return null
  }

  return (
    <>
      <ul className={styles.directChatListContainer}>
        {getDirectChat(user.id, selectedPersonId, messages).map(({ id, from, text }) => {
          return <Message ref={latestMessageRef} key={id} id={id} fromId={from!.id} name={from!.name} text={text} onDelete={() => onDeleteInternal(id)}/>
        })}
      </ul>
      <Footer
        onSubmit={onSubmitInternal(MessageSubtypeEnum.PRIVATE_MESSAGE)}
        onSubmitAudio={onSubmitAudioInternal(MessageSubtypeEnum.PRIVATE_MESSAGE)}
        onChangeMessage={onChangeMessageInternal}
        value={messageText}
      />
    </>
  )
}
