import * as React from 'react'
import { SVGProps } from 'react'
const SvgLongerArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='currentColor'
    className='longer_arrow_down_icon_svg__w-6 longer_arrow_down_icon_svg__h-6'
    width='1em'
    height='1em'
    {...props}
  >
    <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3' />
  </svg>
)
export default SvgLongerArrowDownIcon

