import * as React from 'react'
import { SVGProps } from 'react'
const SvgBranchingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.75 17.3a2.95 2.95 0 0 0-1.963.747l-5.021-5.021c.04-.107.073-.216.1-.326h12.268a2.951 2.951 0 0 0 5.816-.7 2.95 2.95 0 0 0-5.816-.7H5.866c-.027-.11-.06-.22-.1-.326l5.021-5.022a2.95 2.95 0 1 0-.803-1.176L4.962 9.797a2.95 2.95 0 1 0 0 4.405l5.022 5.022A2.95 2.95 0 1 0 12.75 17.3ZM11.654 2.654a1.55 1.55 0 1 1 2.192 2.192 1.55 1.55 0 0 1-2.192-2.192Zm-9.247 7.914a1.55 1.55 0 1 1 1.186 2.864 1.55 1.55 0 0 1-1.186-2.864Zm17.497.336a1.55 1.55 0 1 1 2.192 2.192 1.55 1.55 0 0 1-2.192-2.192Zm-8.25 8.25a1.55 1.55 0 1 1 2.192 2.192 1.55 1.55 0 0 1-2.192-2.192Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.75 17.3a2.95 2.95 0 0 0-1.963.747l-5.021-5.021c.04-.107.073-.216.1-.326h12.268a2.951 2.951 0 0 0 5.816-.7 2.95 2.95 0 0 0-5.816-.7H5.866c-.027-.11-.06-.22-.1-.326l5.021-5.022a2.95 2.95 0 1 0-.803-1.176L4.962 9.797a2.95 2.95 0 1 0 0 4.405l5.022 5.022A2.95 2.95 0 1 0 12.75 17.3ZM11.654 2.654a1.55 1.55 0 1 1 2.192 2.192 1.55 1.55 0 0 1-2.192-2.192Zm-9.247 7.914a1.55 1.55 0 1 1 1.186 2.864 1.55 1.55 0 0 1-1.186-2.864Zm17.497.336a1.55 1.55 0 1 1 2.192 2.192 1.55 1.55 0 0 1-2.192-2.192Zm-8.25 8.25a1.55 1.55 0 1 1 2.192 2.192 1.55 1.55 0 0 1-2.192-2.192Z'
      fill='url(#branching_icon_svg__a)'
      fillOpacity={0.25}
    />
    <defs>
      <linearGradient id='branching_icon_svg__a' x1={12} y1={0.8} x2={12} y2={23.2} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgBranchingIcon

