import React from 'react'
import { getIcon } from '../../../../../common/components/icons/utils'
import QRCode from 'qrcode.react'
import styles from '../AddPerson.module.css'

type PinCodeAreProps = {
  pinCode?: string
  gameName: string
}

export const PinCodeArea: React.FC<PinCodeAreProps> = ({ pinCode, gameName }) => {
  return (
    <div className={styles.pinContainer}>
      <div className={styles.pinTitle}>
        <span>{gameName}</span>
      </div>
      <div className={styles.pinContent}>
        <div className={styles.qrCode}>
          <div className={styles.pinCode}>{pinCode}</div>
          <div className={styles.playSeppo}>
            <span className={styles.pinIcon}>{getIcon('exercisePin')}</span> play.seppo.io
          </div>
        </div>
        <span className={styles.qr}>
          <QRCode value={`${process.env.REACT_APP_OLD_UI_LOGIN_URL}/login/${pinCode}`} renderAs='canvas' size={62} />
        </span>
      </div>
    </div>
  )
}
