import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { EditorFormField } from '../../../../../../common/components/Form/EditorFormField/EditorFormField'
import { Button } from '../../../../../../common/components/button/Button'
import { IconTooltip } from '../../../../../../common/components/tooltip/IconTooltip'
import { useGame } from '../../../../../../contexts/GameContext'
import { useNotification } from '../../../../../../contexts/NotificationContext'
import { Game } from '../../../../../../types/commonTypes'
import commonStyles from '../../EditorSidebar.module.css'
import styles from './StoryAndRules.module.css'

export type StoryAndRulesForm = Pick<Game, 'storyHtml' | 'rulesHtml' | 'showStoryAndRules'>

const FORM_ID = 'game_story_and_rules_form'

type StoryAndRulesProps = {
  game: Game
  viewOnly: boolean
}

export const StoryAndRules: React.FC<StoryAndRulesProps> = ({ game, viewOnly }) => {
  const { t } = useTranslation()
  const { updateStoryAndRules } = useGame()
  const { notifyError, notifySuccess } = useNotification()

  const onSubmit = async (values: StoryAndRulesForm) => {
    // NOTE: third value is currently hardcoded, but in dashboard2 there's
    // an option to set it to true or false
    const response = await updateStoryAndRules(values.storyHtml ?? '', values.rulesHtml ?? '', true)
    if (response) {
      notifySuccess({
        title: t(
          'game_editor.sidebar.build.story_and_rules.notifications.save_changes_success.title',
          'Story and rules update',
        ),
        content: t(
          'game_editor.sidebar.build.story_and_rules.notifications.save_changes_success.content',
          'You have successfully updated game story and rules.',
        ),
      })
    } else {
      notifyError({
        title: t(
          'game_editor.sidebar.build.story_and_rules.notifications.save_changes_failure.title',
          'Story and rules update',
        ),
        content: t(
          'game_editor.sidebar.build.story_and_rules.notifications.save_changes_failure.content',
          'Something went wrong, update failed.',
        ),
      })
    }
  }

  const initialValues: Partial<StoryAndRulesForm> = {
    storyHtml: game.storyHtml,
    rulesHtml: game.rulesHtml,
    showStoryAndRules: game.showStoryAndRules,
  }

  return (
    <Form<StoryAndRulesForm> initialValues={initialValues} onSubmit={onSubmit}>
      {({ submitting, handleSubmit }) => {
        return (
          <>
            <div className={commonStyles.sidebarMainContainer}>
              <form onSubmit={handleSubmit} className={styles.formContainer} id={FORM_ID}>
                <div className={styles.titleRow}>
                  <h3>{t('game_editor.sidebar.build.story_and_rules.story_title', 'Game story')}</h3>
                  <IconTooltip
                    tooltipContent={t(
                      'game_editor.sidebar.build.story_and_rules.story_tooltip',
                      'Tooltip testing text. A moderately long description about what Game story is will go here',
                    )}
                    iconColor='var(--grey-600)'
                    tooltipClassName={styles.titleTooltip}
                  />
                </div>
                <EditorFormField
                  name='storyHtml'
                  label={t('game_editor.sidebar.build.story_and_rules.story_title', 'Game story')}
                  placeholder={t('game_editor.sidebar.build.story_and_rules.story_placeholder', 'Write game story')}
                  onlyBasicOptions
                  initProps={{ height: 300, width: '100%' }}
                  validateOnlyIfDirty
                  srOnlyLabel
                  disabled={viewOnly}
                />
                <div className={styles.titleRow}>
                  <h3>{t('game_editor.sidebar.build.story_and_rules.rules_title', 'Rules')}</h3>
                  <IconTooltip
                    tooltipContent={t(
                      'game_editor.sidebar.build.story_and_rules.rules_tooltip',
                      'Tooltip testing text. A moderately long description about what Game rules are will go here',
                    )}
                    iconColor='var(--grey-600)'
                    tooltipClassName={styles.titleTooltip}
                  />
                </div>
                <EditorFormField
                  name='rulesHtml'
                  label={t('game_editor.sidebar.build.story_and_rules.rules_title', 'Rules')}
                  placeholder={t('game_editor.sidebar.build.story_and_rules.rules_placeholder', 'Write game rules')}
                  onlyBasicOptions
                  initProps={{ height: 300, width: '100%' }}
                  validateOnlyIfDirty
                  srOnlyLabel
                  disabled={viewOnly}
                />
              </form>
            </div>
            {!viewOnly && (
              <div className={commonStyles.sidebarBottomContainer}>
                <Button form={FORM_ID} disabled={submitting} type='submit'>
                  {t('game_editor.sidebar.build.story_and_rules.save_changes_button', 'Save changes')}
                </Button>
              </div>
            )}
          </>
        )
      }}
    </Form>
  )
}
