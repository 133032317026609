import * as React from 'react'
import { SVGProps } from 'react'
const SvgTaskMissingWordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17-.003a1 1 0 1 0 0 2 5 5 0 0 1 5 5v1H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h19v1a5 5 0 0 1-5 5 1 1 0 1 0 0 2 6.997 6.997 0 0 0 6-3.392 6.997 6.997 0 0 0 6 3.392 1 1 0 1 0 0-2 5 5 0 0 1-5-5v-1h5a3 3 0 0 0 3-3v-10a3 3 0 0 0-3-3h-5v-1a5 5 0 0 1 5-5 1 1 0 1 0 0-2 6.997 6.997 0 0 0-6 3.393 6.997 6.997 0 0 0-6-3.393Zm5 10H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h19v-12Zm2 12v-12h5a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-5Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgTaskMissingWordIcon

