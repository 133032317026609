import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { Message } from '../Message/Message'
import styles from './GeneralChat.module.css'
import { useTranslation } from 'react-i18next'
import { Footer } from '../Footer/Footer'
import { MessageSubtypeEnum } from '../../../../../../api/messagingTypes'
import { useChat } from '../../../../../../contexts/ChatContextProvider'
import { TMessage } from '../../../../../../types/commonTypes'

type GeneralChatProps = {
  messages: TMessage[]
  messageText: string | undefined
  onSubmit: (messageSubtype: MessageSubtypeEnum) => void
  onSubmitAudio: (messageSubtype: MessageSubtypeEnum, receiverId?: number, audioUrl?: string, ) => void
  onChangeMessage: (message: string) => void
  onDeleteMessage: (messageId: number) => void
}

export const GeneralChat: React.FC<GeneralChatProps> = ({
  messages,
  messageText,
  onChangeMessage,
  onDeleteMessage,
  onSubmit,
  onSubmitAudio,
}) => {
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)
  const { t } = useTranslation()
  const latestMessageRef = useRef<HTMLLIElement | null>(null)
  const { updateChatRoom } = useChat()

  useEffect(() => {
    setIsInitialLoad(false)
    updateChatRoom(0, true)
    return () => {
      updateChatRoom(0, false)
    }
    // Messages come 1 by 1, so if GENERAL chat is ACTIVE, we trigger a rerender by a new message arrival
  }, [updateChatRoom, messages])

  useLayoutEffect(() => {
    if (latestMessageRef.current != null) {
      latestMessageRef.current.scrollIntoView(
        isInitialLoad ? false : { behavior: 'smooth', block: 'end', inline: 'center' },
      )
    }
  }, [isInitialLoad, messages])

  const onSubmitInternal = useCallback(
    (messageSubtype: MessageSubtypeEnum) => (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSubmit(messageSubtype)
    },
    [onSubmit],
  )

  const onSubmitAudioInternal = useCallback(
    (messageSubtype: MessageSubtypeEnum) => (audioUrl: string) => {
      onSubmitAudio(messageSubtype, undefined, audioUrl)
    },
    [onSubmitAudio],
  )

  const onDeleteInternal = useCallback((messageId: number) => {
    onDeleteMessage(messageId)
  }, [onDeleteMessage])

  if (messages == null) {
    return null
  }

  return (
    <>
      <ul className={styles.container}>
        {messages.length === 0 ? (
          <div className={styles.noMessagesContainer}>{t('game_editor.chat.no_messages', 'No messages')}</div>
        ) : (
          messages.map(({ from, id, text }, index) => {
            if (index === messages.length - 1) {
              return (
                <Message
                  key={`general_chat_message_${id}:${index}`}
                  id={id}
                  ref={latestMessageRef}
                  fromId={from?.id}
                  name={from?.name ?? t('game_editor.chat.message_no_name', 'no name')}
                  text={text}
                  onDelete={() => onDeleteInternal(id)}
                  style={index === messages.length - 1 ? { overflowAnchor: 'auto' } : {}}
                />
              )
            }
            return (
              <Message
                key={`general_chat_message_${id}:${index}`}
                id={id}
                fromId={from?.id}
                name={from?.name ?? t('game_editor.chat.message_no_name', 'no name')}
                text={text}
                onDelete={() => onDeleteInternal(id)}
              />
            )
          })
        )}
      </ul>
      <Footer
        onSubmit={onSubmitInternal(MessageSubtypeEnum.SHOUTBOX_MESSAGE)}
        onSubmitAudio={onSubmitAudioInternal(MessageSubtypeEnum.SHOUTBOX_MESSAGE)}
        onChangeMessage={onChangeMessage}
        value={messageText}
      />
    </>
  )
}
