import React from 'react'
import { Outlet } from 'react-router-dom'
import { CombinedSearchContextProvider } from '../contexts/CombinedSearchContextProvider'
import { CommunityGamesDataProvider } from '../contexts/CommunityGamesContextProvider'
import { ConfirmationContextProvider } from '../contexts/ConfirmationContext'
import { DebugProvider } from '../contexts/DebugContext'
import { NotificationProvider } from '../contexts/NotificationContext'
import { OrgLibraryGamesDataProvider } from '../contexts/OrgLibraryGamesContextProvider'
import { OwnGamesDataProvider } from '../contexts/OwnGamesContextProvider'
import { TemplateGamesDataProvider } from '../contexts/TemplateGamesContextProvider'
import { CombineComponents } from '../util/combineComponents'
import styles from './NoNavbarLayout.module.css'

export const NoNavbarLayout: React.FC<{}> = () => {
  return (
    <CombineComponents
      components={[
        DebugProvider,
        NotificationProvider,
        OwnGamesDataProvider,
        OrgLibraryGamesDataProvider,
        CommunityGamesDataProvider,
        TemplateGamesDataProvider,
        ConfirmationContextProvider,
        CombinedSearchContextProvider,
      ]}
    >
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </CombineComponents>
  )
}
