import { SortableContext } from '@dnd-kit/sortable'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DeepPartial, LevelCriteria, Task } from '../../../../../../../types/commonTypes'
import { GameBoard } from '../../../../../types'
import { LevelTasksMap, PreviousLevelsData, TaskActionFn } from '../../types'
import { SortableItemPlaceholder } from '../TaskCard/SortableItemPlaceholder'
import { SortableTask } from '../TaskCard/SortableTask'
import { LevelBoardTasks } from './LevelBoardTasks'
import { LevelHeader } from './LevelHeader'
import styles from './LevelTasks.module.css'
import { NoTasksPlaceholder } from './NoTasksPlaceholder'

type LevelTasksProps = {
  levelIndex: number
  maxLevelIndex: number
  levelCriteria: LevelCriteria
  tasksMap: LevelTasksMap
  isDragging: boolean
  noPointsGame?: boolean
  orderingEnabled?: boolean
  explorationMode?: boolean
  isCompact: boolean
  activeBoardIndex: number
  gameBoards: GameBoard[]
  onSetActiveBoard: (id: number) => void
  onTaskAction: TaskActionFn
  onClickEdit: (levelIndex: number) => void
  onClickRemove: (levelIndex: number) => void
  onAddTask: (initialTask?: DeepPartial<Task>) => void
  previousLevelsData?: PreviousLevelsData
  showAllBoards: boolean
  dragStartTask?: Task
  viewOnly: boolean
}

const doesLevelHaveTasks = (tasksMap: LevelTasksMap, levelIndex: number) => {
  if (!tasksMap[levelIndex])
    return false
  return Object.keys(tasksMap[levelIndex]).some((boardKey) => tasksMap[levelIndex][parseInt(boardKey)].length > 0)
}

const doHigherLevelsHaveTasks = (tasksMap: LevelTasksMap, levelIndex: number) => {
  return Object.keys(tasksMap).some(
    (levelKey) => parseInt(levelKey) > levelIndex && doesLevelHaveTasks(tasksMap, parseInt(levelKey)),
  )
}

export const LevelTasks: React.FC<LevelTasksProps> = ({
  levelIndex,
  maxLevelIndex,
  levelCriteria,
  tasksMap,
  activeBoardIndex,
  noPointsGame,
  orderingEnabled,
  explorationMode,
  isCompact,
  gameBoards,
  isDragging,
  onTaskAction,
  onSetActiveBoard,
  onClickEdit,
  onClickRemove,
  onAddTask,
  previousLevelsData,
  showAllBoards,
  dragStartTask,
  viewOnly,
}) => {
  const { t } = useTranslation()

  const hasAnyTasks = useMemo(() => doesLevelHaveTasks(tasksMap, levelIndex), [tasksMap, levelIndex])
  const hasAnyHigherLevelTasks = useMemo(() => doHigherLevelsHaveTasks(tasksMap, levelIndex), [tasksMap, levelIndex])
  const levelTasks = useMemo(() => tasksMap[levelIndex], [tasksMap, levelIndex])
  const isDragOriginLevel = useMemo(() => dragStartTask?.level === levelIndex, [dragStartTask, levelIndex])

  return (
    <div className={styles.levelTasks}>
      <LevelHeader
        index={levelIndex}
        criteria={levelCriteria}
        onClickEdit={onClickEdit}
        onClickRemove={onClickRemove}
        onAddTask={onAddTask}
        previousLevelsData={previousLevelsData}
        noPointsGame={noPointsGame}
        viewOnly={viewOnly}
      />
      {gameBoards.length > 1 ? (
        <>
          {!hasAnyTasks && explorationMode && !isDragOriginLevel && (
            <div className={styles.levelContentContainer}>
              <NoTasksPlaceholder showHigherLevelWarning={!isDragging && hasAnyHigherLevelTasks} />
              {isDragging && (
                <SortableItemPlaceholder levelIndex={levelIndex} boardIndex={dragStartTask?.mapIndex ?? 0} />
              )}
            </div>
          )}
          <ul className={styles.levelBoardsList}>
            {levelTasks && Object.keys(levelTasks).map((boardKey) => {
              const currentBoardIndex = parseInt(boardKey)
              const isDragOriginLevelBoard = isDragOriginLevel && dragStartTask?.mapIndex === currentBoardIndex
              const isDefaultBoard = currentBoardIndex === levelCriteria.defaultBoardIndex
              if (
                showAllBoards ||
                levelTasks[currentBoardIndex].length !== 0 ||
                isDragOriginLevelBoard ||
                isDefaultBoard
              ) {
                const { url } = gameBoards.find((board) => (board.mapIndex ?? 0) === currentBoardIndex)!
                return (
                  <LevelBoardTasks
                    boardUrl={url}
                    maxBoardIndex={gameBoards.length - 1}
                    isActive={activeBoardIndex === currentBoardIndex}
                    tasks={levelTasks[currentBoardIndex]}
                    key={`levelBoardTasks_${currentBoardIndex}`}
                    boardIndex={currentBoardIndex}
                    levelIndex={levelIndex}
                    title={t('game_editor.game_boards.board', 'Board') + ' ' + (currentBoardIndex + 1)}
                    compact={isCompact}
                    onSetActiveBoard={onSetActiveBoard}
                    noPointsGame={noPointsGame}
                    isDragging={isDragging}
                    onTaskAction={onTaskAction}
                    orderingEnabled={orderingEnabled}
                    maxLevelIndex={maxLevelIndex}
                    isDefaultBoard={isDefaultBoard}
                    showHigherLevelWarning={hasAnyHigherLevelTasks && !hasAnyTasks}
                    viewOnly={viewOnly}
                  />
                )
              }
              return null
            })}
          </ul>
        </>
      ) : levelTasks[0].length === 0 ? (
        <div className={styles.levelContentContainer}>
          <NoTasksPlaceholder showHigherLevelWarning={!isDragging && hasAnyHigherLevelTasks} />
          {isDragging && <SortableItemPlaceholder levelIndex={levelIndex} boardIndex={0} />}
        </div>
      ) : (
        <SortableContext id={`level-${levelIndex}`} items={levelTasks[0]} disabled={viewOnly}>
          <ul className={classNames(styles.levelTasksList, styles.listVerticalPadding)}>
            {levelTasks[0].map((task, index) => {
              return (
                <SortableTask
                  key={`taskCard_${task.id}`}
                  task={task}
                  compact={isCompact}
                  onAction={onTaskAction}
                  noPointsGame={noPointsGame}
                  showLevel
                  index={index}
                  maxIndex={levelTasks[0].length - 1}
                  maxLevelIndex={maxLevelIndex}
                  maxBoardIndex={gameBoards.length - 1}
                  showOrder={orderingEnabled}
                  viewOnly={viewOnly}
                />
              )
            })}
          </ul>
        </SortableContext>
      )}
    </div>
  )
}
