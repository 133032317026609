import { arrayMove } from '@dnd-kit/sortable'
import { LevelTasksOrderData, MapType, TaskOrderData } from '../../../../../../../api/gameTypes'
import { Game, Task } from '../../../../../../../types/commonTypes'
import { getTotalPointsForTasks, levelOrderTasksSort } from '../../../../../../../util/game'
import { BoardTasksMap, LevelTasksMap, PreviousLevelsDataMap } from '../../types'

const getEmptyLevelTasks = (indices: number[]): BoardTasksMap => {
  return indices.reduce((boardMap, boardIndex) => ({ ...boardMap, [boardIndex]: [] }), { 0: [] })
}

export const getTasksMap = (game: Game) => {
  const gameBoardIndices =
    game.gameBoardSettings.gameBoardType === MapType.LIVE
      ? []
      : game.gameBoardSettings.gameBoards.map((board) => board.mapIndex ?? 0)
  const emptyLevelTasks = getEmptyLevelTasks(gameBoardIndices)
  const initial: LevelTasksMap = {
    0: { 0: [] },
  }
  for (let index = 1; index < getMaxLevel(game); index++) {
    initial[index] = { ...emptyLevelTasks }
  }

  return game.tasks
    .filter((t) => !t.advanced.isFlash)
    .sort(levelOrderTasksSort)
    .reduce((tasksMap: LevelTasksMap, task: Task) => {
      return {
        ...tasksMap,
        [task.level ?? 1]: {
          ...tasksMap[task.level ?? 1],
          [task.mapIndex ?? 0]: [...tasksMap[task.level ?? 1][task.mapIndex ?? 0], task],
        },
      }
    }, initial)
}

export const changeTaskLevelAndBoard = (
  tasksMap: LevelTasksMap,
  task: Task,
  newLevel: number,
  newBoardIndex: number,
) => {
  const safeNewBoardIndex = newLevel === 0 ? 0 : newBoardIndex
  return {
    ...tasksMap,
    // remove active task from it's current level & board group
    [task.level ?? 1]: {
      ...tasksMap[task.level ?? 1],
      [task.mapIndex ?? 0]: tasksMap[task.level ?? 1][task.mapIndex ?? 0].filter((aTask) => aTask.id !== task.id),
    },
    // add active task to the new level & board group
    [newLevel]: {
      ...tasksMap[newLevel],
      [safeNewBoardIndex]: [
        ...tasksMap[newLevel][safeNewBoardIndex],
        {
          ...task,
          level: newLevel,
          mapIndex: safeNewBoardIndex,
        },
      ],
    },
  }
}

export const changeTaskBoard = (tasksMap: LevelTasksMap, task: Task, newBoardIndex: number) => {
  return {
    ...tasksMap,
    [task.level ?? 1]: {
      ...tasksMap[task.level ?? 1],
      // remove task from it's current board group
      [task.mapIndex ?? 0]: tasksMap[task.level ?? 1][task.mapIndex ?? 0].filter((aTask) => aTask.id !== task.id),
      // add task to the new board group
      [newBoardIndex]: [
        ...tasksMap[task.level ?? 1][newBoardIndex],
        {
          ...task,
          mapIndex: newBoardIndex,
        },
      ],
    },
  }
}

export const changeTaskOrderInGroup = (tasksMap: LevelTasksMap, task: Task, oldIndex: number, newIndex: number) => {
  return {
    ...tasksMap,
    [task.level ?? 1]: {
      ...tasksMap[task.level ?? 1],
      [task.mapIndex ?? 0]: arrayMove(tasksMap[task.level ?? 1][task.mapIndex ?? 0], oldIndex, newIndex),
    },
  }
}

export const getPreviousLevelsDataMap = (game: Game): PreviousLevelsDataMap => {
  const dataMap: PreviousLevelsDataMap = {}
  for (let index = 2; index < getMaxLevel(game); index++) {
    const previousTasks = game.tasks.filter((task) => !task.advanced.isFlash && (task.level ?? 1) < index)
    dataMap[index] = {
      pointsSum: getTotalPointsForTasks(previousTasks),
      tasksCount: previousTasks.length,
    }
  }
  return dataMap
}

export const getLevelsTaskData = (tasksMap: LevelTasksMap): LevelTasksOrderData => {
  return Object.keys(tasksMap).reduce((levelTasks: LevelTasksOrderData, levelIndexKey: string) => {
    const levelIndex = parseInt(levelIndexKey)
    levelTasks[levelIndex] = Object.keys(tasksMap[levelIndex]).reduce(
      (orderTaskData: TaskOrderData[], boardIndexKey: string) => {
        const boardIndex = parseInt(boardIndexKey)
        return [
          ...orderTaskData,
          ...tasksMap[levelIndex][boardIndex].map((task) => ({ taskId: task.id, mapId: boardIndex - 1 })),
        ]
      },
      [],
    )
    return levelTasks
  }, {})
}

export const getMaxLevel = (game: Game) => {
  // level 0 + 4 for levels only, + 10 for exploration mode
  return game.advancedSettings.explorationMode ? 11 : 5
}
