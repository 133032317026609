import classNames from 'classnames'
import React from 'react'
import styles from './ToggleSwitch.module.css'

export type ToggleSwitchProps = React.HTMLProps<HTMLInputElement> & {
  small?: boolean
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  small = false,
  ...rest
}) => {
  return (
    <label className={classNames(small ? styles.switchSmall : styles.switch)}>
      <input
        className={styles.originalInput}
        type='checkbox'
        {...rest}
      />
      <span className={classNames(styles.slider, small && styles.sliderSmall)} />
    </label>
  )
}
