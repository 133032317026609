import classNames from 'classnames'
import React from 'react'

import { LibrarySource } from '../../api/gameTypes'
import { ModalPortal } from '../../common/components/Portal/Portal'
import { CloseButton } from '../../common/components/button/CloseButton'
import { useFadeAnimation } from '../../hooks/FadeAnimation/useFadeAnimation'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import { useOnEscPress } from '../../hooks/useOnEscPress'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import { GameAction, TGameCard } from '../../types/commonTypes'
import styles from './GamePreviewModal.module.css'
import { GamePreview } from './preview/GamePreview'
import { GamePreviewModalSidebar } from './sidebar/GamePreviewModalSidebar'

type GamePreviewModalProps = {
  game: TGameCard
  onClose: () => void
  onGameAction: (action: GameAction, game: TGameCard) => void
}

export const GamePreviewModal: React.FC<GamePreviewModalProps> = ({ game, onClose, onGameAction }) => {
  const { librarySource, id } = game
  const modalRef = useTrapFocus<HTMLDivElement>()
  useOnEscPress(onClose)
  useDisableBodyScroll()

  const { fadeTransition, closeWithDelay } = useFadeAnimation()

  const onCloseInternal = () => {
    closeWithDelay(350, onClose)
  }

  const onGameActionInternal = (gameAction: GameAction) => {
    onGameAction(gameAction, game)
  }

  return (
    <ModalPortal>
      <div ref={modalRef} className={classNames(styles.modalContainer, fadeTransition)}>
        <div className={styles.modalLeft}>
          <div className={styles.modalTop}>
            <h4>{game.gameName}</h4>
          </div>
          <div className={styles.modalContent}>
            <GamePreview gameId={Number.parseInt(id)} isLibrary={librarySource !== LibrarySource.OWN} />
          </div>
        </div>
        <div className={styles.modalRight}>
          <CloseButton className={styles.closeButton} autoFocus onClick={onCloseInternal}/>
          <GamePreviewModalSidebar game={game} onGameAction={onGameActionInternal} />
        </div>
      </div>
    </ModalPortal>
  )
}
