import classNames from 'classnames'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useCallback } from 'react'
import { Polyline, Marker } from 'react-leaflet'
import { Task } from '../../../../types/commonTypes'
import { BoardCoords } from '../BoardSection/BoardImage'
import styles from './ConnectionLine.module.css'

type ConnectionLineProps = {
  taskFrom: Task
  taskTo: Task
  boardCoords: BoardCoords
  notReachable: boolean
}

export const arrowIcon =
  '<svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0,0 L0,16 L24,8 Z" fill="currentColor"></path></svg>'

const getRotation = (taskFrom: Task, taskTo: Task) => {
  const dy = taskTo.y - taskFrom.y
  const dx = taskTo.x - taskFrom.x
  return Math.atan2(dy, dx)
}

const getIcon = (rotation: number, notReachable: boolean) => {
  return new L.DivIcon({
    html: arrowIcon.replace('<svg', `<svg style="transform: rotate(${rotation}rad);"`),
    iconSize: [24, 18],
    iconAnchor: [12, 9],
    className: classNames(styles.icon, notReachable && styles.notReachable),
  })
}

export const ConnectionLine: React.FC<ConnectionLineProps> = ({ taskFrom, taskTo, boardCoords, notReachable }) => {
  const getX = useCallback(
    (x: number) => {
      return boardCoords.xZero + boardCoords.xMultiplier * x
    },
    [boardCoords],
  )

  const getY = useCallback(
    (y: number) => {
      return boardCoords.yMax - (boardCoords.yZero + boardCoords.yMultiplier * y) + boardCoords.yZero
    },
    [boardCoords],
  )

  if (!taskFrom || !taskTo) return null

  return (
    <>
      <Polyline
        key={taskFrom.id + '_' + taskTo.id + '_' + notReachable}
        weight={4}
        dashArray={'10'}
        className={classNames(
          'connection_' + taskFrom.id + '_' + taskTo.id,
          notReachable ? styles.notReachable : styles.normalLine,
        )}
        positions={[
          [getY(taskFrom.y), getX(taskFrom.x)],
          [getY(taskTo.y), getX(taskTo.x)],
        ]}
      />
      <Marker
        key={taskTo.id}
        icon={getIcon(getRotation(taskFrom, taskTo), notReachable)}
        position={[getY((taskFrom.y + taskTo.y)/2), getX((taskFrom.x + taskTo.x)/2)]}
      />
    </>
  )
}
