import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { LoaderBlock } from '../../../common/components/loaders/LoaderBlock/LoaderBlock'
import { useBusinessSettings } from '../../../contexts/BusinessSettingsContext'
import { useUser } from '../../../contexts/userContext'
import { routes } from '../../../routes'
import { dateTimeToLocalDateString } from '../../../util/string'
import styles from '../Settings.module.css'
import { Members } from './components/Members'
import { OrganizationInfo } from './components/OrganizationInfo'
import { Players } from './components/Players'

export const OrganizationSettings: React.FC = () => {
  const { user } = useUser()
  const navigate = useNavigate()

  const { addMembers, removeMember, isFetchingData, changeMemberRole, data } = useBusinessSettings()
  const members = data?.members == null ? [] : data.members.length > 20 ? data?.members.slice(0, 20) : data.members

  if (user == null || user.isSponsoredUser) {
    return null
  }

  const handleViewAllPlayerAccounts = () => {
    navigate(routes.playersSettings)
  }

  const handleViewAllMembers = () => {
    navigate(routes.membersSettings)
  }

  const handleViewAllLicenseHolders = () => {
    navigate(routes.licenseHolderSettings)
  }

  return data == null && isFetchingData ? (
    <LoaderBlock />
  ) : (
    <div className={classNames(styles.sectionsContainer)}>
      <OrganizationInfo
        name={user.activeBusiness.name}
        membersCount={user.isBusinessAdmin ? data?.members?.length : undefined}
        licenseValidUntilDate={dateTimeToLocalDateString(data?.businessInfo?.validUntil, user.language)}
        maxMembersCount={data?.businessInfo.maxMembers || null}
        businessInfo={data?.businessInfo.newBusinessInfo || null}
      />
      {user.isBusinessAdmin && (
        <Members
          members={members}
          organizationName={user.activeBusiness.name}
          isLoading={isFetchingData}
          onRemoveMember={removeMember}
          onChangeMemberRole={changeMemberRole}
          onViewAllMembers={handleViewAllMembers}
          onAddMembers={addMembers}
        />
      )}
      {/* NOTE: commented out for now, to be revived once we define rules who can see players */}
      <Players
        newBusinessInfo={data?.businessInfo.newBusinessInfo}
        organizationName={user.activeBusiness.name}
        onViewAllPlayerAccounts={handleViewAllPlayerAccounts}
        onViewAllLicenseHolders={handleViewAllLicenseHolders}
      />
    </div>
  )
}
