import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../../../../../common/components/Modal/Modal'
import { Button } from '../../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { ThreeDots } from '../../../../../../common/components/loaders/ThreeDots/ThreeDots'
import { useGame } from '../../../../../../contexts/GameContext'
import { useNotification } from '../../../../../../contexts/NotificationContext'
import { useUser } from '../../../../../../contexts/userContext'
import { useTrapFocus } from '../../../../../../hooks/useTrapFocus'
import { isEmpty } from '../../../../../../util/functional'
import { LibraryTasksEmpty } from './LibraryTasksEmpty'
import { LibraryTasksList } from './LibraryTasksList'
import styles from './TaskLibraryModal.module.css'
import { TaskLibrarySearch } from './TaskLibrarySearch'
import { getLibsForUser, getSelectedSubtasks } from './helpers'
import { LibTaskSelectedMap } from './types'
import { useSearchLibraryTasks } from './useSearchLibraryTasks'

type TaskLibraryModalProps = {
  onClose: (subtaskId?: number) => void
}

export const TaskLibraryModal: React.FC<TaskLibraryModalProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const { gameData } = useGame()
  const { user } = useUser()
  const { notifyWarning } = useNotification()
  const { mutators } = useForm()

  const modalRef = useTrapFocus<HTMLDivElement>()
  const [selectedTaskIds, setSelectedTaskIds] = useState<LibTaskSelectedMap>({})

  const { hasMore, isLoading, libraryTasks, onLoadMore, onSearchChange, searchCount, searchParams } =
    useSearchLibraryTasks(gameData?.gameId ?? 0)

  const onSelectTask = useCallback((taskIndexAndId: string) => {
    setSelectedTaskIds((prev) => ({ ...prev, [taskIndexAndId]: !prev[taskIndexAndId] }))
  }, [])

  useEffect(() => setSelectedTaskIds({}), [searchParams])

  const onAddTasks = useCallback(() => {
    if (!Object.values(selectedTaskIds).some((val) => val)) {
      notifyWarning({
        title: t('game_editor.tasks.library_modal.select_tasks_before_adding_warning.title', 'Select tasks first'),
        content: t(
          'game_editor.tasks.library_modal.select_tasks_before_adding_warning.content',
          'You have to select one or more tasks before adding them',
        ),
      })
    } else if (libraryTasks) {
      const subtasks = getSelectedSubtasks(libraryTasks, selectedTaskIds)
      mutators.concat('subtasks', subtasks)
      onClose(subtasks[0].tempId)
    }
  }, [selectedTaskIds, libraryTasks, notifyWarning, t, onClose, mutators])

  const availableLibs = getLibsForUser(user)
  const showTaskList = libraryTasks && !isEmpty(libraryTasks)
  const onCloseInternal = () => onClose()

  return (
    <Modal noBackdrop>
      <div className={styles.taskLibraryModal} ref={modalRef}>
        {showTaskList && isLoading && (
          <div className={styles.taskLibraryResultsLoaderOverlay}>
            <ThreeDots />
          </div>
        )}
        <div className={styles.taskLibraryHeader}>
          <span className={styles.taskLibraryHeaderIcon}>{getIcon('plus')}</span>
          <span className={styles.taskLibraryTitle}>
          {t('game_editor.tasks.library_modal.title', 'Add tasks from library')}
          </span>
          <CloseButton onClick={onCloseInternal} autoFocus />
        </div>

        <div className={styles.taskLibraryMain}>
          <div className={classNames(styles.taskLibraryInfo, 'medium')}>
            {t(
              'game_editor.tasks.library_modal.info',
              'You can add tasks from the Community or Organization library to speed up game creation. Search and filter what you would like and find the best task for your needs.',
            )}
          </div>
          <TaskLibrarySearch onSearchChange={onSearchChange} availableLibs={availableLibs} />
          <div className={styles.taskLibraryResultsContainer}>
            {showTaskList ? (
              <LibraryTasksList
                count={searchCount}
                taskMap={libraryTasks}
                selectedTaskIds={selectedTaskIds}
                onSelectTask={onSelectTask}
                noPointsGame={gameData?.advancedSettings.noPointsGame}
                searchTerm={searchParams?.search}
              />
            ) : (
              <LibraryTasksEmpty isLoading={isLoading} hasAnySearchParams={!isEmpty(searchParams)} />
            )}
            {hasMore && (
              <div className={styles.loadMoreContainer}>
                <Button variant='outline-tiny' type='button' onClick={onLoadMore}>
                  {t('common.load_more', 'Load more')}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className={styles.taskLibraryFooter}>
          <Button variant='outline-normal' onClick={onCloseInternal}>
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button type='submit' onClick={onAddTasks}>
            {t('game_editor.tasks.library_modal.add_tasks_button', 'Add tasks')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
