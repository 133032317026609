import * as React from 'react'
import { SVGProps } from 'react'
const SvgExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g fill='#E0E0E0' clipPath='url(#expand_icon_svg__a)'>
      <path d='M14.25.125a.625.625 0 1 0 0 1.25h4.116l-5.808 5.808a.625.625 0 1 0 .884.884l5.808-5.808v4.116a.625.625 0 1 0 1.25 0V.75a.623.623 0 0 0-.625-.625H14.25ZM8.442 13.067a.625.625 0 1 0-.884-.884L1.75 17.991v-4.116a.625.625 0 1 0-1.25 0V19.5a.623.623 0 0 0 .625.625H6.75a.625.625 0 1 0 0-1.25H2.634l5.808-5.808Z' />
    </g>
    <defs>
      <clipPath id='expand_icon_svg__a'>
        <path fill='#fff' transform='translate(.5 .125)' d='M0 0h20v20H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgExpandIcon

