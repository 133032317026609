import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { Modal } from '../../../../../common/components/Modal/Modal'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useTrapFocus } from '../../../../../hooks/useTrapFocus'
import styles from './PlayersAddManuallyModal.module.css'
import { FormErrorType, Player, Task } from '../../../../../types/commonTypes'
import { areObjectsEqual, isEmpty } from '../../../../../util/functional'
import { Form } from 'react-final-form'
import classNames from 'classnames'
import { StartingTaskSelection } from './StartingTaskSelection'

export enum PlayerModalMode {
  CLOSED = 'CLOSED',
  NEW = 'NEW',
  FULL_EDIT = 'FULL_EDIT',
  STARTING_TASK = 'STARTING_TASK',
}

export type AddPlayerForm = {
  nickName: string
  teamMemberNames: string
  showStartingTaskSelection: boolean
  startingTask?: number
}

type PlayersAddManuallyModalProps = {
  player?: Player
  mode: PlayerModalMode
  tasks: Task[]
  isOrdered: boolean
  onClose: (hasChanges: boolean) => void
  onSubmit: (player: AddPlayerForm) => Promise<void>
}

export const PlayersAddManuallyModal: React.FC<PlayersAddManuallyModalProps> = ({
  player,
  mode,
  tasks,
  isOrdered,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const ref = useTrapFocus<HTMLFormElement>()

  const initialValues: AddPlayerForm = {
    nickName: player?.nickName ?? '',
    teamMemberNames: player?.teamMemberNames ?? '',
    startingTask: player?.startingTask ?? undefined,
    showStartingTaskSelection: isOrdered && !isEmpty(player?.startingTask),
  }

  const validate = (values: AddPlayerForm): FormErrorType<AddPlayerForm> => {
    return {
      ...((isEmpty(values.nickName) || values.nickName.length < 1) && {
        nickName: t('game_editor.add_people.add_manually.nickname_required', 'Player name is required'),
      }),
    }
  }

  return (
    <Modal>
      <Form<AddPlayerForm> initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit, submitting, initialValues, values }) => (
          <form
            className={classNames(
              styles.playersAddManuallyModal,
              mode === PlayerModalMode.STARTING_TASK && styles.playersAddManuallyModalStartingTask,
            )}
            ref={ref}
            onSubmit={handleSubmit}
          >
            <div className={styles.playersAddManuallyHeader}>
              <div className={styles.playersAddManuallyHeaderTitle}>
                <span>{mode === PlayerModalMode.STARTING_TASK ? getIcon('pinPlain') : getIcon('userIdle')}</span>
                <span>
                  {mode === PlayerModalMode.FULL_EDIT
                    ? t('game_editor.add_people.add_manually.modal_title_edit_player', 'Edit player details')
                    : mode === PlayerModalMode.STARTING_TASK
                    ? t('game_editor.add_people.add_manually.modal_title_edit_starting_task', 'Define first task')
                    : t('game_editor.add_people.add_manually.modal_title_add_player', 'Add player manually')}
                </span>
              </div>
              <CloseButton autoFocus onClick={() => onClose(!areObjectsEqual(values, initialValues))} />
            </div>
            <div className={styles.playersAddManuallyMainSection}>
              {mode === PlayerModalMode.STARTING_TASK && (
                <StartingTaskSelection tasks={tasks} values={values} selectOnly/>
              )}
              {mode !== PlayerModalMode.STARTING_TASK && (
                <>
                  <div className={styles.infoText}>
                    {t(
                      'game_editor.add_people.add_manually.top_info_text',
                      'Create a code for players to use. You can then tell players to use the code to join the game.',
                    )}
                  </div>
                  <InputFormField
                    name='nickName'
                    label={t('game_editor.add_people.add_manually.name_placeholder', 'Player name')}
                    placeholder={t('game_editor.add_people.add_manually.name_placeholder', 'Player name')}
                    icon='multipleUsers'
                    type='text'
                    maxLength={20}
                    showCharsCount
                    srOnlyLabel
                    fieldContainerClassName={styles.nameInputContainer}
                  />
                  <InputFormField
                    name='teamMemberNames'
                    label={t(
                      'game_editor.add_people.add_manually.team_member_names_placeholder',
                      'Team member names (optional)',
                    )}
                    placeholder={t(
                      'game_editor.add_people.add_manually.team_member_names_placeholder',
                      'Team member names (optional)',
                    )}
                    icon='multipleUsers'
                    type='text'
                    srOnlyLabel
                    fieldContainerClassName={styles.nameInputContainer}
                  />
                  {isOrdered && (
                    <>
                      <div className={styles.separator} />
                      <StartingTaskSelection tasks={tasks} values={values} />
                    </>
                  )}
                </>
              )}
            </div>
            <div className={styles.playersAddManuallyFooter}>
              <Button
                variant='outline-normal'
                disabled={submitting}
                onClick={() => onClose(!areObjectsEqual(values, initialValues))}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button type='submit' disabled={submitting}>
                {mode === PlayerModalMode.NEW
                  ? t('game_editor.add_people.add_manually.create_player_button', 'Create player')
                  : mode === PlayerModalMode.STARTING_TASK
                  ? t('game_editor.add_people.add_manually.define_task_button', 'Define task')
                  : t('common.save', 'Save')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  )
}
