import React from 'react'
import { useTranslation } from 'react-i18next'

import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { useGameData } from '../../contexts/GamePackageGamesContextProvider'
import { isNullOrZero } from '../../util/number'
import styles from './GamePackage.module.css'


type GamePackageProps = {
  packageId: number
}

export const GamePackage: React.FC<GamePackageProps> = ({packageId}) => {
  const { t } = useTranslation()
  const gameData = useGameData()

  return (
    <div className={styles.container}>
      {isNullOrZero(gameData.pageData?.length) && gameData.loading ? (
        <LoaderBlock variant='secondary' />
      ) : (
        <GamesOverview
          title={gameData.gamePackage?.name ?? t('game_package_page.title_if_missing','Unnamed package')}
          libraryMode
          gameData={gameData}
          emptyContent={{
            title: t('game_package_page.empty.title', 'There are no games in this package'),
            subtitle: t('game_package_page.empty.content', 'Contact the package provider to check the situation of the package'),
          }}
        />
      )}
    </div>
  )
}
