import classNames from 'classnames'
import { useField } from 'react-final-form'
import { FormFieldProps } from '../../../../types/commonTypes'
import { ToggleSwitch, ToggleSwitchProps } from '../../ToggleSwitch/ToggleSwitch'
import { ERROR_ROUNDED_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import styles from './ToggleFormField.module.css'

type ToggleFormFieldProps = Partial<ToggleSwitchProps> & {
  description?: string | JSX.Element
  descriptionChecked?: string | JSX.Element
}

export const ToggleFormField: React.FC<FormFieldProps<ToggleFormFieldProps>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  fieldContainerClassName,
  validateOnlyIfDirty,
  description,
  descriptionChecked,
  defaultChecked,
  errorClassName,
  controlWithLabelClassName,
  ...rest
}) => {
  const { input } = useField(name, { type: 'checkbox' })

  return (
    <FormFieldWithError
      name={name}
      errorClassName={classNames(styles.error, errorClassName)}
      wrapperClassName={fieldContainerClassName}
      validateOnlyIfDirty={validateOnlyIfDirty}
    >
      <div
        className={classNames(
          ERROR_ROUNDED_OUTLINE_CLASS_NAME,
          styles.controlWithLabelContainer,
          controlWithLabelClassName,
        )}
      >
        <ToggleSwitch {...input} {...rest} id={id ?? name} />
        <label htmlFor={id ?? name} className={srOnlyLabel ? 'sr-only' : styles.labelContainer}>
          {label}
          <span className={styles.description}>{input.checked ? descriptionChecked ?? description : description}</span>
        </label>
      </div>
    </FormFieldWithError>
  )
}
