import * as React from 'react'
import { SVGProps } from 'react'
const SvgTaskLocationLockedGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient
      id='task_location_locked_gradient_icon_svg__a'
      gradientUnits='userSpaceOnUse'
      x1={16.008}
      x2={16.008}
      y1={2.113}
      y2={29.127}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <path
      d='m4.12 19.532 7.598 7.17 2.657-2.507a.86.86 0 1 0-1.182-1.248l-1.475 1.401-6.314-5.994c-1.713-1.712-2.676-3.853-2.676-6.1s.963-4.388 2.569-5.994c3.532-3.318 9.311-3.318 12.843.107a9.037 9.037 0 0 1 1.58 2.124c.229.426.768.6 1.205.394.403-.19.627-.659.422-1.055a9.67 9.67 0 0 0-2.137-2.747c-4.174-3.96-10.917-3.96-15.09 0-2.034 1.927-3.104 4.495-3.104 7.17 0 2.784 1.07 5.352 3.103 7.279z'
      fill='currentColor'
    />
    <path
      d='m4.12 19.532 7.598 7.17 2.657-2.507a.86.86 0 1 0-1.182-1.248l-1.475 1.401-6.314-5.994c-1.713-1.712-2.676-3.853-2.676-6.1s.963-4.388 2.569-5.994c3.532-3.318 9.311-3.318 12.843.107a9.037 9.037 0 0 1 1.58 2.124c.229.426.768.6 1.205.394.403-.19.627-.659.422-1.055a9.67 9.67 0 0 0-2.137-2.747c-4.174-3.96-10.917-3.96-15.09 0-2.034 1.927-3.104 4.495-3.104 7.17 0 2.784 1.07 5.352 3.103 7.279z'
      fill='url(#task_location_locked_gradient_icon_svg__a)'
      fillOpacity={0.25}
    />
    <path d='M22.756 22.873a1.137 1.137 0 1 1 2.274 0 1.137 1.137 0 0 1-2.274 0z' fill='currentColor' />
    <path
      d='M22.756 22.873a1.137 1.137 0 1 1 2.274 0 1.137 1.137 0 0 1-2.274 0z'
      fill='url(#task_location_locked_gradient_icon_svg__a)'
      fillOpacity={0.25}
    />
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M20.671 11.595a4.833 4.833 0 0 1 3.222-1.23 4.834 4.834 0 0 1 4.833 4.832v1.99h.284a1.99 1.99 0 0 1 1.99 1.99v7.96a1.99 1.99 0 0 1-1.99 1.99H18.775a1.99 1.99 0 0 1-1.99-1.99v-7.96a1.99 1.99 0 0 1 1.99-1.99h.284v-1.99c0-1.431.623-2.717 1.612-3.602zm1.75.842a3.128 3.128 0 0 0-1.655 2.745v2.005h6.254v-1.99a3.128 3.128 0 0 0-4.6-2.76zm-3.645 6.456a.284.284 0 0 0-.284.284v7.96c0 .157.127.285.283.285H29.01a.285.285 0 0 0 .284-.285v-7.96a.285.285 0 0 0-.284-.284z'
        fill='currentColor'
      />
      <path
        d='M20.671 11.595a4.833 4.833 0 0 1 3.222-1.23 4.834 4.834 0 0 1 4.833 4.832v1.99h.284a1.99 1.99 0 0 1 1.99 1.99v7.96a1.99 1.99 0 0 1-1.99 1.99H18.775a1.99 1.99 0 0 1-1.99-1.99v-7.96a1.99 1.99 0 0 1 1.99-1.99h.284v-1.99c0-1.431.623-2.717 1.612-3.602zm1.75.842a3.128 3.128 0 0 0-1.655 2.745v2.005h6.254v-1.99a3.128 3.128 0 0 0-4.6-2.76zm-3.645 6.456a.284.284 0 0 0-.284.284v7.96c0 .157.127.285.283.285H29.01a.285.285 0 0 0 .284-.285v-7.96a.285.285 0 0 0-.284-.284z'
        fill='url(#task_location_locked_gradient_icon_svg__a)'
        fillOpacity={0.25}
      />
      <path
        d='M15.357 8.722a5.305 5.305 0 0 0-7.278 0 4.852 4.852 0 0 0 0 7.064c.429.428.964.32 1.285.214s.642-.321.749-.75c.107-.213.214-.427.428-.642a1.68 1.68 0 0 1 2.355 0c.214.215.32.429.428.643.107.32.428.642.749.749.428.214.856.107 1.284-.214a4.852 4.852 0 0 0 0-7.064zm-6.1 1.284a3.627 3.627 0 0 1 4.923 0c1.07 1.07 1.284 2.783.428 4.067-.107-.214-.321-.428-.535-.642-1.284-1.284-3.425-1.284-4.71 0-.213.214-.427.428-.534.642-.857-1.284-.75-2.996.428-4.067z'
        fill='currentColor'
      />
      <path
        d='M15.357 8.722a5.305 5.305 0 0 0-7.278 0 4.852 4.852 0 0 0 0 7.064c.429.428.964.32 1.285.214s.642-.321.749-.75c.107-.213.214-.427.428-.642a1.68 1.68 0 0 1 2.355 0c.214.215.32.429.428.643.107.32.428.642.749.749.428.214.856.107 1.284-.214a4.852 4.852 0 0 0 0-7.064zm-6.1 1.284a3.627 3.627 0 0 1 4.923 0c1.07 1.07 1.284 2.783.428 4.067-.107-.214-.321-.428-.535-.642-1.284-1.284-3.425-1.284-4.71 0-.213.214-.427.428-.534.642-.857-1.284-.75-2.996.428-4.067z'
        fill='url(#task_location_locked_gradient_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgTaskLocationLockedGradientIcon

