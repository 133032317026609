import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import WomanWithGlasses from '../../../assets/images/skirt_1.png'
import { Button } from '../../../common/components/button/Button'
import { getIcon } from '../../../common/components/icons/utils'
import { useNotification } from '../../../contexts/NotificationContext'
import { useTheme } from '../../../contexts/ThemeContext'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import { routes } from '../../../routes'
import sharedStyles from '../Login.module.css'
import styles from './LoginMethod.module.css'
import { orderPasswordResetLink, resetPassword } from '../../../api/userApiService'
import { FormErrorType } from '../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../util/string'
import { PasswordResetOrder } from './PasswordResetOrder'
import { PasswordResetSetPassword } from './PasswordResetSetPassword'

export type PasswordResetFormValues = {
  email?: string
  token?: string
  password?: string
  passwordConfirmation?: string
}

enum PasswordResetStep {
  ORDER = 'ORDER',
  SET_PASSWORD = 'SET_PASSWORD',
}

const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PasswordResetForm: React.FC = () => {
  const { t } = useTranslation()
  const { logoUrl } = useTheme()
  const { fadeTransition } = useFadeAnimation()
  const { token } = useParams()
  const navigate = useNavigate()
  const { notifySuccess, notifyError } = useNotification()
  const [step] = useState<PasswordResetStep>(
    safeIsNullOrEmpty(token) ? PasswordResetStep.ORDER : PasswordResetStep.SET_PASSWORD,
  )

  const onOrderLink = useCallback(
    async (values: PasswordResetFormValues) => {
      if (!values.email) return
      const result = await orderPasswordResetLink({ email: values.email })
      if (result.success) {
        notifySuccess({
          title: t('login.password_reset_link_order_success.title', 'Password change link sent'),
          content: t(
            'login.password_reset_link_order_success.content',
            'If a user account exists with the given email address, you should receive a link to reset password within a few minutes.',
          ),
        })
        navigate(routes.login)
      } else {
        notifyError({
          title: t('login.password_reset_link_order_failed.title', 'Password link order failed'),
          content: t(
            'login.password_reset_link_order_failed.content',
            'Please try again later or contact us for support.',
          ),
        })
      }
    },
    [notifySuccess, t, navigate, notifyError],
  )

  const onSetPassword = useCallback(
    async (values: PasswordResetFormValues) => {
      if (!token || !values.password || !values.passwordConfirmation) return
      const result = await resetPassword({
        token,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      })
      if (result.success) {
        if (result.value.success) {
          notifySuccess({
            title: t('login.password_change_success.title', 'Password changed'),
            content: result.value.msg ?? '',
          })
          navigate(routes.login)
        } else {
          notifyError({
            title: t('login.password_change_error.title', 'Password change failed'),
            content: result.value.msg ?? '',
          })
        }
      } else {
        notifyError({
          title: t('login.password_change_serror.title', 'Password change failed'),
          content: t('login.password_change_error.content', 'Please try again later or contact us for support.'),
        })
      }
    },
    [token, notifySuccess, t, navigate, notifyError],
  )

  const validate = useCallback(
    (values: Partial<PasswordResetFormValues>): FormErrorType<PasswordResetFormValues> => {
      if (step === PasswordResetStep.ORDER) {
        return validEmail.test(values.email + '')
          ? {}
          : { email: t('validation_errors.invalid_email', 'Invalid email') }
      } else {
        const err: FormErrorType<PasswordResetFormValues> = {}
        return {
          ...err,
          ...(safeIsNullOrEmpty(values.password) ? { password: t('validation_errors.required', 'Required') } : {}),
          ...(safeIsNullOrEmpty(values.passwordConfirmation)
            ? { passwordConfirmation: t('validation_errors.required', 'Required') }
            : {}),
        }
      }
    },
    [step, t],
  )

  const onClickBackInternal = useCallback(() => {
    navigate(routes.login)
  }, [navigate])

  return (
    <div className={classNames(styles.container, fadeTransition)}>
      <div className={classNames(styles.leftSideContainer)}>
        {step === PasswordResetStep.ORDER ? (
          <>
            <div className={styles.firstRow}>
              <button className={sharedStyles.backButton} onClick={onClickBackInternal}>
                <span>{getIcon('arrowRight')}</span>
                {t('login.instructor.left.back_button', 'Back')}
              </button>
            </div>
            <div className={styles.secondRow}>
              <h1>{t('login.instructor.left.title', 'Join an existing game')}</h1>
              <p>
                {t(
                  'login.instructor.left.body',
                  'Return to the previous page to enter a game code if you want to join a game.',
                )}
              </p>
              <Button
                variant='outline-tiny'
                onClick={onClickBackInternal}
                style={{
                  color: 'var(--primary-normal)',
                  maxWidth: 'fit-content',
                  padding: '0.5rem 1.25rem',
                  lineHeight: '1rem',
                }}
              >
                {t('login.instructor.left.enter_code_button', 'Enter game code')}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.imageWrapper}>
            <img src={WomanWithGlasses} alt='Woman with glasses in a long skirt' />
          </div>
        )}
      </div>
      <div className={styles.rightSideContainer}>
        <div className={styles.logoContainer}>
          <img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />
        </div>
        <Form<PasswordResetFormValues>
          onSubmit={step === PasswordResetStep.ORDER ? onOrderLink : onSetPassword}
          validate={validate}
        >
          {({ handleSubmit, submitting, submitError, modifiedSinceLastSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              {step === PasswordResetStep.ORDER ? <PasswordResetOrder /> : <PasswordResetSetPassword />}
              <div className={styles.formLabelAndButtonContainer}>
                <Button type='submit' disabled={submitting} className={sharedStyles.bigBlockButton}>
                  {submitting
                    ? t('loading', 'Loading...')
                    : step === PasswordResetStep.ORDER
                    ? t('login.instructor.password_change.order_link_button', 'Order reset link')
                    : t('login.instructor.password_change.set_password_button', 'Set password')}
                </Button>
              </div>
              {submitError && !modifiedSinceLastSubmit && <div className={styles.submitError}>{submitError}</div>}
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}
