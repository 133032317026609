import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotification } from '../contexts/NotificationContext'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'

export const useGameNotifications = () => {
  const { notifyProgress, finishProgress, notifyError } = useNotification()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const notifyGameImportStart = useCallback(
    (gameName: string) => {
      return notifyProgress({
        title: t('game_action_notifications.import.progress.title', 'Importing game'),
        content: t('game_action_notifications.import.progress.content', {
          game_name: gameName,
          defaultValue: 'Importing %{game_name}...',
        }),
      })
    },
    [notifyProgress, t],
  )

  const notifyGameImportFinish = useCallback(
    (notifyId: string, gameName: string, success: boolean) => {
      const title = success
        ? t('game_action_notifications.import.finished.success.title', 'Game imported')
        : t('game_action_notifications.import.finished.failure.title', 'Game import failed')

      const content = success
        ? t('game_action_notifications.import.finished.success.content', {
            game_name: gameName,
            defaultValue: 'Go to dashboard to see %{game_name}',
          })
        : t('game_action_notifications.import.finished.failure.content', {
            game_name: gameName,
            defaultValue: 'Failed to import %{game_name}, please try again or contact us for support',
          })

      const customButton = success
        ? {
            text: t('game_action_notifications.import.finished.success.go_to_dashboard_button_text', 'Go'),
            onClick: () => navigate(routes.homepage),
            closeOnClick: true,
          }
        : undefined

      finishProgress(notifyId, success, { title, content, customButton })
    },
    [finishProgress, t, navigate],
  )

  const notifyGameDuplicateStart = useCallback(
    (gameName: string) => {
      return notifyProgress({
        title: t('game_action_notifications.duplicate.progress.title', 'Duplicating game'),
        content: t('game_action_notifications.duplicate.progress.content', {
          game_name: gameName,
          defaultValue: 'Duplicating %{game_name}...',
        }),
      })
    },
    [notifyProgress, t],
  )

  const notifyGameDuplicateFinish = useCallback(
    (notifyId: string, gameName: string, success: boolean) => {
      const title = success
        ? t('game_action_notifications.duplicate.finished.success.title', 'Game duplicated')
        : t('game_action_notifications.duplicate.finished.failure.title', 'Game duplication failed')

      const content = success
        ? t('game_action_notifications.duplicate.finished.success.content', {
            game_name: gameName,
            defaultValue: 'Duplicated game %{game_name}',
          })
        : t('game_action_notifications.duplicate.finished.failure.content', {
            game_name: gameName,
            defaultValue: 'Failed to duplicate %{game_name}, please try again or contact us for support',
          })

      finishProgress(notifyId, success, { title, content })
    },
    [finishProgress, t],
  )

  const notifyGameDeleteStart = useCallback(
    (gameName: string) => {
      return notifyProgress({
        title: t('game_action_notifications.delete.progress.title', 'Deleting game'),
        content: t('game_action_notifications.delete.progress.content', {
          game_name: gameName,
          defaultValue: 'Deleting %{game_name}...',
        }),
      })
    },
    [notifyProgress, t],
  )

  const notifyGameDeleteFinish = useCallback(
    (notifyId: string, gameName: string, success: boolean) => {
      const title = success
        ? t('game_action_notifications.delete.finished.success.title', 'Game deleted')
        : t('game_action_notifications.delete.finished.failure.title', 'Game deletion failed')

      const content = success
        ? t('game_action_notifications.delete.finished.success.content', {
            game_name: gameName,
            defaultValue: 'Deleted game %{game_name}',
          })
        : t('game_action_notifications.delete.finished.failure.content', {
            game_name: gameName,
            defaultValue: 'Failed to delete %{game_name}, please try again or contact us for support',
          })

      finishProgress(notifyId, success, { title, content })
    },
    [finishProgress, t],
  )

  const notifyFetchingGamesFailed = useCallback(() => {
    notifyError({
      title: t('game_action_notifications.loading_games_error.title', 'Failed to load games'),
      content: t(
        'game_action_notifications.loading_games_error.content',
        'An error occurred while loading games. Please try again or contact us for support',
      ),
    })
  }, [notifyError, t])

  return {
    notifyGameImportStart,
    notifyGameImportFinish,
    notifyGameDuplicateStart,
    notifyGameDuplicateFinish,
    notifyGameDeleteStart,
    notifyGameDeleteFinish,
    notifyFetchingGamesFailed,
  }
}
