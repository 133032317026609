import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useFadeAnimation } from '../../../../hooks/FadeAnimation/useFadeAnimation'
import { TMember } from '../../../../types/commonTypes'
import { MemberTableRow } from './MemberTableRow'
import styles from './MembersTable.module.css'

type MembersTableProps = {
  members: TMember[]
  isLoading: boolean
  onChangeMemberRole: (userName: string) => Promise<boolean>
  onRemoveMember: (userName: string) => Promise<boolean>
}

export const MembersTable: React.FC<MembersTableProps> = ({
  members,
  isLoading,
  onRemoveMember,
  onChangeMemberRole,
}) => {
  const { t } = useTranslation()
  const { fadeTransition } = useFadeAnimation(isLoading, true)

  return (
    <table className={classNames(styles.membersTable, fadeTransition)} cellPadding={0} cellSpacing={0}>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>{t('settings_organization.members.table_headers.name', 'Name')}</th>
          <th>{t('settings_organization.members.table_headers.email', 'Email')}</th>
          <th>{t('settings_organization.members.table_headers.role', 'Role')}</th>
          <th>{t('settings_organization.members.table_headers.actions', 'Actions')}</th>
        </tr>
      </thead>
      <tbody>
        {members.map((member, index) => (
          <MemberTableRow
            key={`member:${index}:${member.email}`}
            member={member}
            onChangeMemberRole={onChangeMemberRole}
            onRemoveMember={onRemoveMember}
          />
        ))}
      </tbody>
    </table>
  )
}
