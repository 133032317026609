import classNames from 'classnames'
import React from 'react'
import { safeIsNullOrEmpty } from '../../../util/string'
import { RoundButton, RoundButtonProps } from '../button/RoundButton'
import { IconName, getIcon } from '../icons/utils'
import styles from './Input.module.css'

export type InputFieldProps = React.HTMLProps<HTMLInputElement> & {
  value: string
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void
  icon?: IconName
  button?: Partial<RoundButtonProps>
  inputContainerClassName?: string
  disabled?: boolean
  shouldMirror?: boolean
  reserveButtonSpace?: boolean
  showCharsCount?: boolean
  big?: boolean
}

export const InputField: React.FC<InputFieldProps> = ({
  value,
  onChange,
  icon,
  disabled,
  inputContainerClassName,
  className,
  shouldMirror = false,
  button,
  reserveButtonSpace,
  showCharsCount,
  maxLength,
  big,
  ...restProps
}) => {
  const hasIcon = icon != null
  const hasButton = button != null
  const hasCharCount = showCharsCount && maxLength != null

  return (
    <div
      className={classNames(
        styles.container,
        disabled && styles.container_disabled,
        (hasIcon || hasButton) && styles.inputLessInitialPadding,
        inputContainerClassName,
      )}
    >
      {hasIcon && (
        <span
          className={classNames(
            styles.inputElement_icon,
            !safeIsNullOrEmpty(value) && styles.inputElement_icon_withValue,
          )}
        >
          {getIcon(icon)}
        </span>
      )}
      <input
        onChange={onChange}
        className={classNames(
          styles.input_field,
          big && styles.input_big,
          (reserveButtonSpace || hasButton) && styles.withButton,
          hasIcon && styles.withIcon,
          hasCharCount && styles.withCharCount,
          className,
        )}
        value={value}
        disabled={disabled}
        {...(maxLength && { maxLength: maxLength })}
        {...restProps}
      />
      {hasButton && (
        <RoundButton
          {...button}
          noHoverShadow={button.noHoverShadow ?? true}
          icon={button.icon ?? 'arrowRight'}
          className={classNames(
            styles.inputElement_button,
            hasCharCount && styles.inputElement_button_withCharCount,
            button.className,
          )}
        />
      )}
      {hasCharCount && (
        <span className={styles.inputElement_charCount}>
          {value?.length ?? 0}/{maxLength}
        </span>
      )}
    </div>
  )
}
