import React, { useCallback, useState } from 'react'

import { InputField } from '../../../../../../common/components/input/InputField'
import styles from './Footer.module.css'
import { useUser } from '../../../../../../contexts/userContext'
import { AllowedOption } from '../../../../../../types/commonTypes'
import { AudioHandler } from './AudioHandler'

type FooterProps = {
  value: string | undefined
  onChangeMessage: (message: string) => void
  onSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void
  onSubmitAudio: (audioUrl: string) => void
}

export const Footer: React.FC<FooterProps> = ({ value = '', onChangeMessage, onSubmit, onSubmitAudio }) => {
  const { user } = useUser()
  const hasAudioChat = user?.allowedOptions.includes(AllowedOption.CUSTOM_CHAT)
  const [messageIsText, setMessageIsText] = useState(!hasAudioChat)
  const [showAudioRecorder, setShowAudioRecorder] = useState(false)

  const onChangeMessageInternal = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget
      if (value.length > 0) setMessageIsText(true)
      else setMessageIsText(!hasAudioChat)
      onChangeMessage(value)
    },
    [hasAudioChat, onChangeMessage],
  )

  const onSubmitInternal = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      if (messageIsText) {
        onSubmit(e)
        setMessageIsText(!hasAudioChat)
      } else {
        setShowAudioRecorder(true)
        e.preventDefault()
      }
    },
    [hasAudioChat, messageIsText, onSubmit],
  )

  const onSubmitAudioInternal = useCallback((audioUrl: string) => {
    onSubmitAudio(audioUrl)
    setShowAudioRecorder(false)
    setMessageIsText(!hasAudioChat)
  }, [hasAudioChat, onSubmitAudio])

  const handleAudioCancel = useCallback(() => {
    setShowAudioRecorder(false)
    setMessageIsText(!hasAudioChat)
  }, [hasAudioChat])

  return (
    <div className={styles.container}>
      {showAudioRecorder ? (
        <AudioHandler onSubmitAudio={onSubmitAudioInternal} onCancel={handleAudioCancel}/>
      ) : (
        <form className={styles.messageInputForm} onSubmit={onSubmitInternal}>
          <InputField
            required={messageIsText}
            name='message-field'
            onChange={onChangeMessageInternal}
            value={value}
            button={{
              type: 'submit',
              icon: messageIsText ? 'arrowRight' : 'audio',
            }}
          />
        </form>
      )}
    </div>
  )
}
