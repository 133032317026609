import * as React from 'react'
import { SVGProps } from 'react'
const SvgWelcomeDay01Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 271.33 247.05' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' {...props}>
    <path
      d='m93.61 220.52 3.5 1.94 37.22 20.57a2.497 2.497 0 0 0 2.45 0l34.42-19.01-4.98-6.14c-.8-1.16-1.03-2.03-.67-2.61.4-.68 1.55-.93 3.46-.76l17.48 1.07 83.42-46.11c.36-.21.62-.41.8-.6.16-.16.3-.36.41-.61.18-.39.25-1.43.22-3.13l-.09-15.92c-.05.52-.49 1.05-1.33 1.59L168.5 206.85l-16.27 8.98-15.43 8.53a2.497 2.497 0 0 1-2.45 0l-48.76-26.94-.08 18.62.1 24.51 8.01-20.04M1.38 150.89a2.519 2.519 0 0 1-1.32-2.15c0 .12-.01 2.48-.04 7.09-.03 4.9-.03 8.24-.01 10.01.02 1.15.08 1.94.2 2.36.15.52.54.98 1.17 1.36L57 200.3l10.7 39.58.15-52.25zM221.94 56.41c-2.3 0-4.26.81-5.88 2.43-1.62 1.61-2.43 3.57-2.43 5.87s.81 4.25 2.43 5.87c1 1.01 2.12 1.7 3.37 2.09-3.63 14.3-5.44 21.45-5.43 21.46 5.39 3.34 10.68 3.34 15.85 0 0 0-.92-3.78-2.79-11.3l-.68-2.73-1.03-4.07-.85-3.39c.29-.09.57-.2.85-.33.89-.41 1.7-.98 2.45-1.74.26-.26.51-.54.74-.82 1.13-1.45 1.7-3.13 1.7-5.05 0-2.3-.81-4.26-2.43-5.87-1.62-1.62-3.58-2.43-5.86-2.43M139.19 50.89l-25.34-35.13c-2.24-3.13-4.98-4.66-8.22-4.6-3.23-.05-5.97 1.48-8.22 4.6-.02.03-.03.05-.05.08L71.07 55.93c.78 1.83 1.2 3.82 1.26 5.95 2.01-1.5 4.35-2.26 7-2.26 3.29 0 6.09 1.16 8.41 3.47 2.32 2.32 3.47 5.12 3.47 8.41s-1.16 6.09-3.47 8.41c-2.24 2.24-4.94 3.4-8.1 3.47H53.08L16.87 138.6l7.72-3.91 16.35-8.28 7-3.55c-.1-.56-.15-1.13-.15-1.72 0-2.46.87-4.56 2.61-6.29 1.73-1.73 3.83-2.6 6.29-2.6s4.55.87 6.29 2.6c.08.08.17.17.25.27l71.35-36.16c.36-.19.74-.28 1.14-.28s.78.09 1.15.28l20.47 10.37c.11-.14 1.75-2.42 4.91-6.85 2.22 1.08 4.54 1.63 6.94 1.64-.25.45-2.02 3.29-5.31 8.52l25.22 12.78 6.86 3.49h.04c.15-.43.74-1.35 1.75-2.75a35.22 35.22 0 0 1 5.83-6.29c1.13-.96 2.3-1.81 3.49-2.56l-4.76-6.54h.01l-38.27-52.76c-2.95-4.22-5.74-6.35-8.36-6.37-2.64-.02-5.33 2.11-8.08 6.38l-8.43 12.87'
      fill='var(--welcome-block-dark)'
    />
    <path
      d='M119.85 56.73c-2.06-2.29-3.56-3.92-4.49-4.9-1.58-1.65-2.97-2.85-4.16-3.6-1.5-.94-3.01-1.42-4.54-1.43-1.34-.02-2.67.25-3.99.81-.88.38-2.03 1.03-3.45 1.95-1.68 1.12-2.87 1.85-3.56 2.21-1.45.77-2.88 1.29-4.31 1.57-3.19.62-6.14.16-8.85-1.36-.85-.47-1.63-1.03-2.33-1.67l-.84-.81 21.12-32.14c1.43-2 3.15-3.03 5.16-3.08h.01c.8-.03 1.56.14 2.26.48.97.49 1.94 1.36 2.9 2.6l34.41 44.75c-1.1 1.51-2.18 2.68-3.23 3.5-1.5 1.14-3.07 1.72-4.72 1.75-3.61.05-7.56-1.72-11.84-5.33-1.72-1.44-3.56-3.21-5.53-5.31M178.72 71.18c1.82 1.12 2.78 2.43 2.88 3.93-.1 1.5-1.06 2.81-2.88 3.92-1.17.71-2.46 1.24-3.87 1.59-.31.08-.63.14-.94.2h.03c-1.12.19-2.3.29-3.55.29-3.25 0-6.03-.69-8.33-2.08-1.83-1.11-2.79-2.41-2.88-3.92.1-1.5 1.06-2.82 2.88-3.93 2.3-1.38 5.08-2.07 8.33-2.07s6.03.69 8.33 2.07'
      fill='var(--welcome-block-dark)'
    />
    <g fill='#fff'>
      <path d='M148.05 194.76c-7.07 3.29-12.34 5.71-15.81 7.27l19.99 13.81 16.27-8.98-20.44-12.1M162.26 82.48c-3.16 4.43-4.8 6.71-4.91 6.85l-7.48 9.79s1.31.47 3.86 1.4c2.61.95 4.1 1.46 4.45 1.55 0 0 .02 0 .03.01.24-.36 2.13-3.51 5.68-9.44 3.3-5.23 5.07-8.07 5.31-8.52-2.4 0-4.71-.55-6.94-1.64M116.7 191.31l11.29 7.79c.53-.21 5.96-2.65 16.28-7.3-.3-.2-4.02-2.55-11.17-7.04l-16.4 6.56M163.67 183.09c-.35-.21-3.78-2.39-10.3-6.54l-14.34 5.83c.48.34 2.27 1.42 5.36 3.25 3.16 1.87 5.09 3.04 5.78 3.5l13.49-6.04M179.56 166.04c.89.57 3.99 2.42 9.31 5.55l12.02-5.44-8.53-5.1-12.8 4.98M178.89 171.05c-2.54-1.61-4.06-2.57-4.57-2.88l-14.86 5.88c1.69 1.08 5.18 3.14 10.46 6.16l13.53-6.26c-.36-.24-1.88-1.2-4.57-2.9M180.18 149.85l11.71-5.37-8.78-4.73-11.53 5.19zM169.16 132.25l-10.97 5.18 8.99 5.06 11.39-5.17zM147.86 126.29c-3.38.47-5.76.83-7.13 1.07l12.59 7.24 11.38-4.76-8.56-4.59c-1.69.16-4.45.51-8.28 1.04M205.24 151.69l-8.9-4.8-12.28 5.25 8.41 4.67zM209.74 154.11l-13.06 5.22c.75.44 3.79 1.97 9.11 4.59l12.01-5.48-8.05-4.34' />
      <path d='M107.32 118.59c-4.95 1.49-8.38 4.16-10.31 8.01-1.35 2.71-2.04 6.35-2.05 10.91 0 1.72.12 3.82.37 6.3.14 1.4.35 3.5.65 6.3.46 5.06.27 9.19-.57 12.38-1.28 4.83-4.03 9.27-8.26 13.33-3.56 3.42-8.04 6.46-13.43 9.12-1.65.81-3.6 1.71-5.86 2.69l17.72 9.79a91.9 91.9 0 0 0 7.82-4.05c2.89-1.66 5.36-3.26 7.42-4.81 2.35-1.77 4.3-3.56 5.85-5.38 3.23-3.8 5.54-7.81 6.94-12.04 1.13-3.47 1.71-7.31 1.72-11.53 0-2.22-.23-5.86-.71-10.93-.43-4.46-.56-7.88-.39-10.25.31-4.4 1.69-7.65 4.12-9.75 1.67-1.44 4.95-2.63 9.82-3.59 3.77-.74 9.13-1.43 16.07-2.07 4-.37 10.22-.9 18.65-1.6 7.32-.67 13.17-1.39 17.54-2.17 1.89-.33 3.26-.61 4.11-.85 1.37-.37 2.59-.87 3.67-1.48 2.44-1.41 5.05-4.08 7.81-8.01h-.04l-6.86-3.5c-1.43 1.78-2.88 3.13-4.36 4.06-3.1 1.95-7.23 3.19-12.38 3.72-4.02.42-9.55.82-16.59 1.18-8.3.39-14.51.69-18.63.91-15.19.79-25.14 1.89-29.83 3.3' />
      <path d='M153.97 108.86c1.1-1.89 1.76-3.01 1.98-3.35-.36-.1-1.91-.65-4.67-1.65l-4.08-1.5-7.47 9.25 12.68-.1s.53-.88 1.56-2.65M56.7 112.25c-2.46 0-4.56.87-6.29 2.6-1.74 1.73-2.61 3.83-2.61 6.29 0 .59.05 1.17.15 1.72.32 1.72 1.14 3.25 2.46 4.57 1.07 1.07 2.27 1.82 3.6 2.24l-5.83 22.93c5.79 3.61 11.47 3.61 17.03 0l-3.02-12.07-2.74-10.89c1.31-.41 2.49-1.15 3.54-2.21 1.73-1.74 2.6-3.84 2.6-6.29s-.78-4.35-2.35-6.02c-.08-.09-.17-.18-.25-.27-1.74-1.73-3.84-2.6-6.29-2.6M181.6 75.11c-.1-1.5-1.06-2.82-2.88-3.93-2.3-1.38-5.08-2.07-8.33-2.07s-6.03.69-8.33 2.07c-1.82 1.12-2.78 2.43-2.88 3.93.09 1.5 1.05 2.81 2.88 3.92 2.3 1.39 5.08 2.08 8.33 2.08 1.25 0 2.43-.1 3.55-.29h-.03c.31-.06.63-.13.94-.2 1.41-.35 2.7-.88 3.87-1.59 1.82-1.11 2.78-2.41 2.88-3.92M115.36 51.83c.93.97 2.43 2.6 4.49 4.9 1.97 2.11 3.81 3.88 5.53 5.31 4.29 3.6 8.23 5.38 11.84 5.33 1.65-.03 3.22-.61 4.72-1.75 1.06-.82 2.13-1.99 3.23-3.5l-34.41-44.75c-.96-1.24-1.93-2.11-2.9-2.6-.7-.35-1.45-.51-2.26-.48h-.01c-2.01.05-3.73 1.08-5.16 3.08L79.31 49.51l.84.81c.7.64 1.48 1.2 2.33 1.67 2.71 1.52 5.66 1.97 8.85 1.36 1.43-.28 2.87-.8 4.31-1.57.69-.36 1.88-1.09 3.56-2.21 1.42-.92 2.57-1.57 3.45-1.95 1.33-.56 2.66-.83 3.99-.81 1.53.02 3.04.49 4.54 1.43 1.19.75 2.58 1.95 4.16 3.6' />
    </g>
    <path
      d='M169.01 214.51c-1.91-.17-3.06.08-3.46.76-.36.58-.13 1.45.67 2.61l4.98 6.14 17.94 22.05c.7.74 1.41 1.05 2.12.95.77-.11 1.18-.73 1.23-1.85l.36-10.53c.82.55 1.34.89 1.56 1.03 8.99 5.6 18.33 9.1 28.03 10.5 4.86.7 8.86.84 12 .42l5.1-15.32c-4.21.57-9.08.81-14.61.7-11.02-.23-20.24-2-27.67-5.33l7.32-6.51c.96-.93 1.31-1.7 1.04-2.31-.3-.68-1.35-1.07-3.13-1.19l-15.99-1.07-17.48-1.07M106.66 183.19c-1.55 1.82-3.5 3.61-5.85 5.38-2.05 1.55-4.53 3.15-7.42 4.81a91.9 91.9 0 0 1-7.82 4.05l-17.72-9.79-.15 52.25c.48-2.65 2.14-8.13 4.98-16.42s7.11-10.76 12.81-7.41l.08-18.62 48.76 26.94a2.497 2.497 0 0 0 2.45 0l15.43-8.53-19.99-13.81c3.47-1.56 8.75-3.98 15.81-7.27l20.44 12.1 101.42-56.05c.84-.53 1.28-1.06 1.33-1.59.04-.39-.03-.91-.2-1.57-.06-.25-.12-.49-.19-.75-.59-2.2-1.69-5.86-3.3-10.98-2.69-8.57-5.93-13.88-9.71-15.92-2.49-1.34-5.5-1.24-9.04.29-.23-1.34-.57-2.86-1.01-4.55-.89-3.34-2.07-6.37-3.54-9.09-2.32-4.28-5.2-7.55-8.64-9.8a22.028 22.028 0 0 0-5.74-2.7c-5.17 3.34-10.46 3.34-15.85 0a27.15 27.15 0 0 0-6.92 3.17 32.751 32.751 0 0 0-4.25 3.22 35.22 35.22 0 0 0-5.07 5.63c-1.01 1.4-1.6 2.33-1.75 2.76-2.76 3.93-5.37 6.6-7.81 8.01-1.07.62-2.3 1.11-3.67 1.48-.85.24-2.22.52-4.11.85-4.37.78-10.22 1.5-17.54 2.17-8.44.69-14.66 1.23-18.65 1.6-6.94.63-12.3 1.32-16.07 2.07-4.87.96-8.14 2.15-9.82 3.59-2.43 2.11-3.81 5.36-4.12 9.75-.17 2.37-.04 5.78.39 10.25.48 5.07.72 8.72.71 10.93-.02 4.22-.59 8.06-1.72 11.53-1.39 4.23-3.71 8.24-6.94 12.04'
      fill='var(--welcome-block-light)'
    />
    <path
      d='m196.68 159.33 13.06-5.22 8.05 4.34-12.01 5.48c-5.32-2.62-8.35-4.15-9.11-4.59M196.34 146.89l8.9 4.8-12.77 5.12-8.41-4.67zM140.73 127.35c1.37-.24 3.75-.59 7.13-1.07 3.83-.53 6.59-.88 8.28-1.04l8.56 4.59-11.38 4.76zM158.19 137.43l10.97-5.18 9.41 5.07-11.39 5.17zM191.89 144.48l-11.71 5.37-8.6-4.91 11.53-5.19zM174.32 168.17c.51.31 2.03 1.27 4.57 2.88 2.69 1.7 4.21 2.67 4.57 2.9l-13.53 6.26c-5.28-3.03-8.77-5.08-10.46-6.16l14.86-5.88M188.87 171.6c-5.32-3.14-8.42-4.99-9.31-5.55l12.8-4.98 8.53 5.1-12.02 5.44M153.37 176.54c6.52 4.15 9.95 6.33 10.3 6.54l-13.49 6.04c-.69-.46-2.62-1.63-5.78-3.5-3.09-1.83-4.88-2.91-5.36-3.25l14.34-5.83M127.98 199.09l-11.29-7.79 16.4-6.56c7.15 4.49 10.88 6.84 11.17 7.04-10.32 4.66-15.75 7.09-16.28 7.3'
      fill='#fff'
    />
    <path
      d='M65.59 121.14c0 2.45-.87 4.55-2.6 6.29-1.05 1.06-2.23 1.79-3.54 2.21l2.74 10.89 3.02 12.07c-5.56 3.61-11.24 3.61-17.03 0l5.83-22.93c-1.34-.42-2.54-1.17-3.6-2.24-1.32-1.32-2.14-2.84-2.46-4.57l-7 3.55-16.35 8.28-7.72 3.91-4.79 2.43-10.13 5.12-.49.25c-.57.29-.98.73-1.22 1.32-.13.31-.19.63-.18.96v.05c0 .26.06.52.15.79.22.59.61 1.04 1.17 1.36l66.48 36.74c2.26-.98 4.21-1.88 5.86-2.69 5.39-2.66 9.87-5.7 13.43-9.12 4.23-4.06 6.98-8.5 8.26-13.33.84-3.19 1.03-7.31.57-12.38-.3-2.8-.51-4.9-.65-6.3-.25-2.49-.37-4.59-.37-6.3.02-4.56.7-8.19 2.05-10.91 1.93-3.86 5.36-6.53 10.31-8.01 4.69-1.41 14.63-2.51 29.83-3.3 4.12-.22 10.33-.52 18.63-.91 7.04-.36 12.57-.76 16.59-1.18 5.15-.52 9.27-1.76 12.38-3.72 1.48-.93 2.93-2.28 4.36-4.06L163.9 92.63c-3.55 5.93-5.44 9.07-5.68 9.44 0 0-.02-.01-.03-.01-.36-.08-1.84-.6-4.45-1.55-2.54-.92-3.83-1.39-3.86-1.4l7.48-9.79-20.47-10.37c-.37-.19-.76-.28-1.15-.28s-.78.09-1.14.28l-71.35 36.16c1.56 1.67 2.35 3.68 2.35 6.02'
      fill='var(--welcome-block-light)'
    />
    <path
      d='M155.95 105.51c-.22.34-.88 1.45-1.98 3.35-1.03 1.77-1.55 2.65-1.56 2.65l-12.68.1 7.47-9.25s1.4.51 4.08 1.5c2.76 1 4.31 1.55 4.67 1.65'
      fill='#fff'
    />
    <path
      d='M25.87 56.08c-2.43.78-4.46 2.02-6.1 3.73-1.78 1.85-2.89 4.06-3.35 6.63-.87 4.9.06 8.93 2.8 12.07 2.76 3.18 6.81 4.8 12.15 4.87h48.27c3.16-.08 5.86-1.23 8.1-3.47 2.32-2.32 3.47-5.12 3.47-8.41s-1.16-6.09-3.47-8.41c-2.32-2.32-5.12-3.47-8.41-3.47-2.65 0-4.99.75-7 2.26-.06-2.13-.48-4.11-1.26-5.95-.81-1.93-2.02-3.69-3.61-5.29-3.24-3.24-7.15-4.86-11.74-4.86s-8.51 1.62-11.76 4.86c-2.15 2.16-3.58 4.61-4.3 7.36-1.89-1.4-4.1-2.25-6.62-2.57-2.43-.32-4.83-.1-7.18.66M192.66 9.64c-2.43-.32-4.83-.1-7.18.66-2.43.78-4.46 2.02-6.1 3.73-1.78 1.85-2.89 4.06-3.35 6.63-.87 4.9.06 8.93 2.8 12.07 2.76 3.18 6.81 4.8 12.15 4.87h48.27c3.16-.08 5.86-1.23 8.1-3.47 2.32-2.32 3.47-5.12 3.47-8.41s-1.16-6.09-3.47-8.41c-2.32-2.32-5.12-3.47-8.41-3.47-2.65 0-4.99.75-7 2.26-.13-4.37-1.75-8.12-4.87-11.24S219.92 0 215.33 0s-8.51 1.62-11.76 4.86c-2.15 2.16-3.58 4.61-4.3 7.36-1.89-1.4-4.1-2.25-6.62-2.57'
      fill='var(--welcome-block-light)'
    />
  </svg>
)
export default SvgWelcomeDay01Icon

