import classNames from 'classnames'
import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { checkGameOrPlayerPinExists } from '../../../api/gameApiService'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { Select, SelectOption } from '../../../common/components/Select/Select'
import { Button } from '../../../common/components/button/Button'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS_WITH_ARABIC } from '../../../common/constants'
import { useNotification } from '../../../contexts/NotificationContext'
import { useTheme } from '../../../contexts/ThemeContext'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import sharedStyles from '../Login.module.css'
import styles from './InstructorOrPlayer.module.css'
import { LoginCard } from './LoginCard'

type LoginFormValues = {
  gameCode: string
  email: string
  password: string
}

type InstructorOrPlayerProps = {
  autofocusEnterCode: boolean
  shouldMirror: boolean
  onClickContinueWithSignup: () => void
}

export const InstructorOrPlayer: React.FC<InstructorOrPlayerProps> = ({
  autofocusEnterCode = false,
  shouldMirror = false,
  onClickContinueWithSignup,
}) => {
  const { t, i18n } = useTranslation()
  const { logoUrl } = useTheme()
  const { fadeTransition } = useFadeAnimation()
  const { notifyError } = useNotification()

  const handleOnSubmit = useCallback(
    async (values: LoginFormValues) => {
      const checkStatus = await checkGameOrPlayerPinExists(null, values.gameCode)
      if (values.gameCode != null && values.gameCode !== '' && checkStatus.success && checkStatus.value === 'OK') {
        window.location.replace(`${process.env.REACT_APP_OLD_UI_BASE_URL}/login/${values.gameCode}`)
      } else {
        notifyError({
          title: t('login.login_failed_error_notification.title'),
          content: t('login.login_failed_error_notification.content'),
          timeout: 5000,
          allowClose: false,
        })
      }
    },
    [notifyError, t],
  )

  const handleClickGoToPlayerLogin = () => {
    window.location.replace(`${process.env.REACT_APP_OLD_UI_BASE_URL}/?pa=true`)
  }

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
  }

  return (
    <div className={classNames(styles.backgroundContainer, fadeTransition)}>
      <img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />
      <Select
        options={LANGUAGE_SELECT_OPTIONS_WITH_ARABIC}
        defaultValue={LANGUAGE_SELECT_DEFAULT_OPTION}
        onChange={handleChangeLanguage}
        className={classNames(styles.languageSelect, styles.hideOnLargeScreen)}
      />
      <h1 className={styles.title}>{t('login.title', 'What would you like to do?')}</h1>
      <div className={styles.cardsContainer}>
        <LoginCard icon='door' title={t('login.join_existing_game.title', 'Join an existing game')}>
          <p className={styles.cardBodyText}>{t('login.join_existing_game.input.label', 'Enter your code')}</p>
          <div className={styles.layoutAlignmentWrapper}>
            <Form<LoginFormValues> onSubmit={handleOnSubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.loginWithGameCode}>
                  <InputFormField
                    label={t('login.join_existing_game.input.label', 'Enter your code')}
                    placeholder={t('login.join_existing_game.input.placeholder', 'Type code')}
                    srOnlyLabel
                    autoFocus={autofocusEnterCode}
                    name='gameCode'
                    type='text'
                    reserveButtonSpace
                    button={{
                      type: 'submit',
                      className: shouldMirror && styles.inputElementMirroring,
                      iconDirection: shouldMirror ? 'left' : 'right',
                    }}
                    big
                  />
                  <button type='button' className={styles.loginWithIdButton} onClick={handleClickGoToPlayerLogin}>
                    {t('login.join_existing_game.login_with_player_id', 'Log in with player id').toUpperCase()}
                  </button>
                </form>
              )}
            </Form>
          </div>
        </LoginCard>
        <LoginCard smallDeviceStyles icon='text' title={t('login.create_games.title', 'Create games')}>
          <p className={classNames(styles.cardBodyText, styles.hideOnSmallScreen)}>
            {t('login.create_games.body', "Let's get signed up and make something awesome")}
          </p>
          <div className={styles.layoutAlignmentWrapper}>
            <p className={styles.hideOnLargeScreen}>
              {t(
                'login.create_games.small_device.body1',
                'Your window size is too small to use the instructor interface.',
              )}
            </p>
            <p className={styles.hideOnLargeScreen}>
              {t(
                'login.create_games.small_device.body2',
                'Please enlarge it or switch to a larger device to continue.',
              )}
            </p>
            <span className={styles.hideOnSmallScreen}>
              <Button className={sharedStyles.bigBlockButton} onClick={onClickContinueWithSignup}>
                {t('login.create_games.button_text', 'Continue')}
              </Button>
            </span>
          </div>
        </LoginCard>
      </div>
    </div>
  )
}
