import classNames from 'classnames'
import { forwardRef, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { RoundButton } from '../../common/components/button/RoundButton'
import { Tooltip } from '../../common/components/tooltip/Tooltip'
import { HELP_MENU_CONTROL_ID, useHelpMenu } from '../../contexts/HelpMenuContextProvider'
import { useUser } from '../../contexts/userContext'
import { openNinchat } from '../../util/ninchat'
import styles from './HelpMenu.module.css'
import { HelpMenuItem } from './components/HelpMenuItem/HelpMenuItem'

type HelpMenuProps = {
  isLegacyEditor?: boolean
  buttonClassName?: string
  shouldMirror?: boolean
  isChatOpen?: boolean
}

export const HelpMenu = forwardRef<HTMLDivElement, HelpMenuProps>(
  ({ buttonClassName, isLegacyEditor, shouldMirror, isChatOpen = false }, ref) => {
    const { showHelpMenu, toggleDisplayMenu } = useHelpMenu()
    const { user } = useUser()
    const { t } = useTranslation()
    window.current_user = { language: user?.language, name: user?.name } //Needed for ninchat to be in window as global

    const helpMenuRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(helpMenuRef, (e) => {
      if (!(e.target as any)?.getAttribute?.('id')?.startsWith(HELP_MENU_CONTROL_ID)) {
        toggleDisplayMenu()
      }
    })

    // TODO: replace with non-hardcoded value. Read from config/env file?
    const getFeedbackFormUrl = useCallback((): string => {
      switch (user?.language) {
        case 'fi':
          return `${process.env.REACT_APP_FEEDBACK_FINNISH_LANGUAGE}`
        default:
          return `${process.env.REACT_APP_FEEDBACK_DEFAULT_LANGUAGE}`
      }
    }, [user?.language])

    const handleContactSupport = useCallback(
      (retries = 3) =>
        () => {
          console.log('--retries ' + retries)
          openNinchat(toggleDisplayMenu)
        },
      [toggleDisplayMenu],
    )

    const handleClickSeppoGuide = useCallback(() => {
      window.open('https://seppo.io/manual/', '_blank')
      toggleDisplayMenu()
    }, [toggleDisplayMenu])

    const handleClickSubmitFeedback = useCallback(() => {
      window.open(getFeedbackFormUrl(), '_blank')
      toggleDisplayMenu()
    }, [getFeedbackFormUrl, toggleDisplayMenu])

    return (
      <>
        <div
          ref={ref}
          className={classNames(
            isLegacyEditor
              ? styles.helpMenuContainerTempLegacyEditor
              : classNames(styles.helpMenuContainer, isChatOpen && styles.chatOffset),
            shouldMirror && styles.helpMenuPosMirroring,
          )}
        >
          {showHelpMenu && (
            <div ref={helpMenuRef} className={styles.helpMenu}>
              <div>
                <HelpMenuItem
                  icon={'receipt'}
                  label={t('help_menu.seppo_guide', 'Seppo Guide')}
                  onClickAction={handleClickSeppoGuide}
                />
                <HelpMenuItem
                  icon={'multipleUsers'}
                  label={t('help_menu.contact_support', 'Contact support')}
                  onClickAction={handleContactSupport()}
                />
                <HelpMenuItem
                  icon={'messageFail'}
                  label={t('help_menu.submit_feedback', 'Submit feedback')}
                  onClickAction={handleClickSubmitFeedback}
                />
              </div>
            </div>
          )}
        </div>
        <Tooltip
          idOverride={`${HELP_MENU_CONTROL_ID}_fabButton`}
          tooltipContent={t('help_menu_button.tooltip', 'Help')}
          closeOnScroll
        >
          {(tooltipProps) => (
            <span
              className={classNames(
                isLegacyEditor
                  ? styles.fabContainerTempLegacyEditor
                  : classNames(styles.fabContainer, isChatOpen && styles.chatOffset),
                shouldMirror && styles.fabContainerPosMirroring,
              )}
            >
              <RoundButton
                icon='help'
                className={classNames(
                  isLegacyEditor ? styles.fabButtonTempLegacyEditor : styles.fabButton,
                  buttonClassName,
                )}
                onClick={toggleDisplayMenu}
                aria-label={
                  showHelpMenu
                    ? t('accessibility.help_menu_button.close', 'Close help menu')
                    : t('accessibility.help_menu_button.open', 'Open help menu')
                }
                {...tooltipProps}
              />
            </span>
          )}
        </Tooltip>
      </>
    )
  },
)
