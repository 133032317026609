import * as React from 'react'
import { SVGProps } from 'react'
const SvgFilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 25 25' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M1 0a1 1 0 0 0-1 1v22.5a1 1 0 0 0 1 1h22.5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm1 22.5V2h20.5v20.5zM12 16a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0zm1.842 1a3.249 3.249 0 0 1-6.184 0H5.5a1 1 0 1 1 0-2h2.158a3.244 3.244 0 0 1 1.285-1.702 3.25 3.25 0 0 1 4.9 1.702H19a1 1 0 1 1 0 2zM4.5 8.5a1 1 0 0 1 1-1h8.158A3.25 3.25 0 0 1 20 8.5h-1 1a3.251 3.251 0 0 1-3.251 3.25 3.25 3.25 0 0 1-3.091-2.25H5.5a1 1 0 0 1-1-1zm13.29-.694c.137.205.21.447.21.694l-1.25 1.25a1.25 1.25 0 1 1 1.04-1.944zM18 8.5l-1.25 1.25A1.251 1.251 0 0 0 18 8.5z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgFilterIcon

