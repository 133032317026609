import { ObjectAny } from '../types/commonTypes'
import { safeParseInt } from './number'

export const noop = () => {}
export const noopPromise = async () => {}

export const clamp = (min: number, preferred: number, max: number): number => {
  return Math.min(Math.max(min, preferred), max)
}

export const debounce = <T extends any[]>(func: (...args: T) => void, timeout = 300): ((...args: T) => void) => {
  let timer: any
  return (...args: T) => {
    window.clearTimeout(timer)
    timer = window.setTimeout(() => func(...args), timeout)
  }
}

export const getEnumKeyByValue = (value: string | number, myEnum: any): string => {
  const indexOfS = Object.values(myEnum).indexOf(value)

  const key = Object.keys(myEnum)[indexOfS]

  return key
}

export const sleep = (timeMs: number = 500): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, timeMs))
}

export const getRandomTempId = () => Math.floor(100000 + Math.random() * 900000)

export const isEmpty = (value?: unknown): boolean => {
  if (typeof value === 'object') {
    if (value == null || JSON.stringify(value) === '{}') {
      return true
    }
    const keys = Object.keys(value)
    return !keys.some((key) => !isEmpty((value as ObjectAny)[key]))
  }
  if (Array.isArray(value)) {
    return !value.some((val) => !isEmpty(val))
  }
  return value == null
}

export const areObjectsEqual = (objA: ObjectAny, objB: ObjectAny) => {
  return JSON.stringify(getFlatSortedKeysObject(objA)) === JSON.stringify(getFlatSortedKeysObject(objB))
}

export const getFlatSortedKeysObject = (obj: ObjectAny, parentKey?: string) => {
  let result: ObjectAny = {}
  if (obj == null) {
    return result
  }
  Object.keys(obj).sort((keyA, keyB) => keyA < keyB ? -1 : 1).forEach((key) => {
    const value = obj[key]
    const _key = parentKey ? parentKey + (safeParseInt(key) == null ? `.${key}` : `[${key}]`) : key
    if (typeof value === 'object') {
      result = { ...result, ...getFlatSortedKeysObject(value, _key) }
    } else {
      result[_key] = value
    }
  })
  return result
}

export const omitProperty = <T extends ObjectAny, K extends ObjectAny>(obj: T, key: keyof T): K => {
  const { [key]: _, ...newObj } = obj
  return newObj as unknown as K
}
