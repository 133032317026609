export const config = {
  api: {
    //url: 'https://smartfeet-beta.herokuapp.com',
    //url: 'https://beta.seppo.io',
    url: 'https://play.seppo.io',
    //url: 'https://staging.seppo.io',
    //url: 'http://dev.toni.com:3001',
    //url: isWeb() ? 'http://dev.toni.com:3000' : 'http://10.0.2.2:3000',
    //url: 'https://seppo-proxy.herokuapp.com/https://staging.seppo.io',
  },
}
