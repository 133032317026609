import React from 'react'

import { useTranslation } from 'react-i18next'
import { GameStatus } from '../../../../types/commonTypes'
import { getDisplayExercisesAndMaps } from '../../../../util/game'
import { EMPTY_CONTENT_FALLBACK } from '../../../../common/constants'
import { InfoIcon, PeopleIcon, PinMultipleIcon, RecentsIcon } from '../../../../common/components/icons'
import { Tooltip } from '../../../../common/components/tooltip/Tooltip'
import styles from './Card.module.css'

type GameInfoProps = {
  status: GameStatus
  archivedAt?: string
  modifiedAt?: string
  exercisesNumber?: number
  mapsNumber?: number
  playersCount?: number
  showModifiedAt?: boolean
}

export const GameInfo: React.FC<GameInfoProps> = ({
  status,
  archivedAt,
  modifiedAt,
  exercisesNumber,
  mapsNumber,
  playersCount,
  showModifiedAt,
}) => {
  const { t } = useTranslation()

  const prepareCardContent = () => {
    switch (status) {
      case GameStatus.ARCHIVED:
        return (
          <div className={styles.gameInfo}>
            {/* TODO: add correct icon */}
            <InfoIcon />
            <span>
              {t('game_info.archived_at', { defaultValue: 'Archived %{archived_at}', archived_at: archivedAt })}
            </span>
          </div>
        )

      case GameStatus.IN_PROGRESS:
      case GameStatus.PAUSED:
        return (
          <Tooltip tooltipContent={t('game_info.players_count.tooltip', 'Number of players')}>
            {(tooltipProps) => (
              <div className={styles.gameInfo} {...tooltipProps}>
                <PeopleIcon color={status === GameStatus.IN_PROGRESS ? 'var(--primary-normal)' : 'currentColor'} />
                <span>
                  {playersCount != null
                    ? t('game_info.players_count', { count: playersCount, defaultValue: '%{count} players' })
                    : EMPTY_CONTENT_FALLBACK}
                </span>
              </div>
            )}
          </Tooltip>
        )

      case GameStatus.LIBRARY:
      case GameStatus.TEMPLATE:
      case GameStatus.COMMUNITY:
      case GameStatus.SPONSORED:
        return (
          <div className={styles.gameInfo}>
            <PinMultipleIcon />
            <span>{getDisplayExercisesAndMaps(exercisesNumber ?? 0, mapsNumber ?? 0, t)}</span>
          </div>
        )

      default:
        return showModifiedAt ? (
          <Tooltip tooltipContent={t('game_info.last_modified.tooltip', 'Last modified')}>
            {(tooltipProps) => (
              <div className={styles.gameInfo} {...tooltipProps}>
                <RecentsIcon />
                <span>{modifiedAt}</span>
              </div>
            )}
          </Tooltip>
        ) : (
          <div className={styles.gameInfo}>
            <PinMultipleIcon />
            <span>{t('game_info.tasks', { defaultValue: '%{count} tasks', count: exercisesNumber })}</span>
          </div>
        )
    }
  }

  return prepareCardContent()
}
