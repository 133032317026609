import * as React from 'react'
import { SVGProps } from 'react'
const SvgExercisePinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m12.054 27.5 8.125-7.923c4.438-4.327 4.438-11.407-.057-15.79-4.495-4.383-11.756-4.383-16.25 0-4.496 4.383-4.496 11.463 0 15.846l8.182 7.867ZM8.539 8.339c1.902-1.855 5.013-1.855 6.915 0a4.698 4.698 0 0 1 0 6.743c-.173.168-.346.337-.576.45 0-.675-.346-1.35-.865-1.855-1.095-1.068-2.939-1.068-4.034 0-.518.506-.806 1.236-.864 1.854-.173-.168-.403-.28-.576-.45a4.698 4.698 0 0 1 0-6.742Z'
      fill='currentColor'
    />
    <path
      d='m12.054 27.5 8.125-7.923c4.438-4.327 4.438-11.407-.057-15.79-4.495-4.383-11.756-4.383-16.25 0-4.496 4.383-4.496 11.463 0 15.846l8.182 7.867ZM8.539 8.339c1.902-1.855 5.013-1.855 6.915 0a4.698 4.698 0 0 1 0 6.743c-.173.168-.346.337-.576.45 0-.675-.346-1.35-.865-1.855-1.095-1.068-2.939-1.068-4.034 0-.518.506-.806 1.236-.864 1.854-.173-.168-.403-.28-.576-.45a4.698 4.698 0 0 1 0-6.742Z'
      fill='url(#exercise_pin_icon_svg__a)'
      fillOpacity={0.25}
    />
    <defs>
      <linearGradient id='exercise_pin_icon_svg__a' x1={12} y1={0.5} x2={12} y2={27.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgExercisePinIcon

