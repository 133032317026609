import { useCallback, useState } from 'react'
import { CheckboxFilterItemProps } from './types'

import styles from '../Search.module.css'
import { RemoveFilterButton } from './RemoveFilterButton'
import { ToggleSwitch } from '../../../common/components/ToggleSwitch/ToggleSwitch'

export const CheckboxFilter: React.FC<CheckboxFilterItemProps> = ({
  label,
  initialValue,
  onChange,
  onRemove,
}) => {
  const [value, setValue] = useState<boolean | undefined>(initialValue)

  const onChangeInternal = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.checked)
    onChange(e.currentTarget.checked)
  }, [onChange])

  const onRemoveInternal = useCallback(() => {
    setValue(false)
    onChange(false)
    onRemove?.()
  }, [setValue, onRemove, onChange])

  return (
    <div className={styles.singleFilterContainer}>
      <div className={styles.checkboxFilter}>
        <label htmlFor={label}>{label}</label>
        <ToggleSwitch
          id={label}
          checked={value === undefined ? false : value}
          onChange={onChangeInternal}
          small
        />
      </div>
      { onRemove != null && <RemoveFilterButton onClick={onRemoveInternal}/> }
    </div>
  )
}
