import { Editor, IAllProps } from '@tinymce/tinymce-react'
import classNames from 'classnames'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { useUser } from '../../../../contexts/userContext'
import { AllowedOption, FormFieldProps } from '../../../../types/commonTypes'
import { ERROR_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import fieldStyles from '../FormFields.module.css'
import './EditorFormField.module.css'
import styles from './EditorFormField.module.css'
import {
  audioTemplateCallback,
  filePickerCallback,
  getTinymceLanguage,
  imagesUploadHandler,
  videoTemplateCallback,
} from './helpers'

type InitProps = IAllProps['init']

const DEFAULT_PLUGINS = 'lists link image media directionality'
const BASIC_PLUGINS = 'link image'
const DEFAULT_TOOLBAR_OPTIONS =
  'bold italic subscript superscript undo redo removeFormat numlist bullist link image media'
const BASIC_TOOLBAR_OPTIONS = 'bold italic undo redo link image'

export interface EditorFormFieldProps {
  onEditorChange?: (value: string) => void
  initProps?: InitProps
  additionalToolbarOptions?: string
  onlyBasicOptions?: boolean
  allowInputElements?: boolean
  onKeyUp?: (e: any) => void
  onCut?: (e: any) => void
  onUndo?: (e: any) => void
  onRedo?: (e: any) => void
  disabled?: boolean
}

export const EditorFormField = forwardRef<Editor, FormFieldProps<EditorFormFieldProps>>(
  (
    {
      name,
      label,
      srOnlyLabel,
      inline,
      onEditorChange,
      initProps,
      additionalToolbarOptions,
      onlyBasicOptions,
      fieldContainerClassName,
      validateOnlyIfDirty,
      allowInputElements,
      onKeyUp,
      onCut,
      onUndo,
      onRedo,
      disabled,
    },
    ref,
  ) => {
    const { user } = useUser()

    const language = useMemo(() => {
      return getTinymceLanguage(user?.language)
    }, [user?.language])

    const plugins = useMemo<string>(() => {
      return [
        onlyBasicOptions ? BASIC_PLUGINS : DEFAULT_PLUGINS,
        user?.allowedOptions.includes(AllowedOption.EXERCISE_CODE) ? 'code' : null,
      ]
        .filter((item) => item != null)
        .join(' ')
    }, [onlyBasicOptions, user?.allowedOptions])

    const toolbar = useMemo<string>(() => {
      return [
        onlyBasicOptions ? BASIC_TOOLBAR_OPTIONS : DEFAULT_TOOLBAR_OPTIONS,
        additionalToolbarOptions,
        user?.allowedOptions.includes(AllowedOption.EXERCISE_CODE) ? 'code' : null,
      ]
        .filter((item) => item != null)
        .join(' | ')
    }, [onlyBasicOptions, additionalToolbarOptions, user?.allowedOptions])

    const { input, meta } = useField(name)
    const [initialValue] = useState(input.value)

    const handleEditorChange = useCallback(
      (value: string) => {
        input.onChange(value)
        onEditorChange?.(value)
      },
      [input, onEditorChange],
    )

    const handleBlur = useCallback(() => {
      input.onBlur()
    }, [input])

    const handleFocusIn = () => {
      input.onFocus()
    }

    return (
      <FormFieldWithError validateOnlyIfDirty={validateOnlyIfDirty} name={name} errorClassName={styles.editorError}>
        <Field name={name} component='input' type='hidden' />
        <div className={classNames(inline && fieldStyles.multilineInlineField, fieldContainerClassName)}>
          {label && (
            <label className={classNames(srOnlyLabel && 'sr-only', fieldStyles.label)} id={name}>
              {label}
            </label>
          )}
          <div
            className={classNames(
              styles.editorWrapper,
              meta.active && styles.editorWrapper_active,
              ERROR_OUTLINE_CLASS_NAME,
            )}
          >
            <Editor
              ref={ref}
              //apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              onEditorChange={handleEditorChange}
              initialValue={initialValue}
              onBlur={handleBlur}
              onFocusIn={handleFocusIn}
              onKeyUp={onKeyUp}
              onCut={onCut}
              onUndo={onUndo}
              onRedo={onRedo}
              aria-labelledby={name}
              disabled={disabled}
              tinymceScriptSrc={'/tinymce/tinymce.min.js'}
              init={{
                plugins,
                toolbar,
                menubar: false,
                contextmenu: false,
                language,
                language_url: language ? `/tinymce/${language}.js` : undefined,
                directionality: language === 'ar' ? 'rtl' : 'ltr',
                images_upload_handler: imagesUploadHandler,
                media_alt_source: false,
                media_poster: false,
                file_picker_types: 'media',
                file_picker_callback: filePickerCallback,
                audio_template_callback: audioTemplateCallback,
                video_template_callback: videoTemplateCallback,
                invalid_elements: `h1,h2,h3,h4,h5,h6,font${allowInputElements ? '' : ',input'}`,
                invalid_styles: {
                  '*': 'line-height font-family font-size font-weight',
                },
                branding: false,
                elementpath: false,
                height: 200,
                body_class: 'tiny-mce-body-custom',
                content_css: '/tinymce/custom_content.css',
                ...initProps,
              }}
            />
          </div>
        </div>
      </FormFieldWithError>
    )
  },
)
