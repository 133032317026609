import { clamp } from './functional'

type Hsl = {
  h: number
  s: number
  l: number
}

type Rgb = {
  r: number
  g: number
  b: number
}

export type ColorPalette = {
  background: string
  light: string
  normal: string
  dark: string
}

export const getRandomHex = () => {
  const digits = [0,1,2,3,4,5,6,7,8,9,'a','b','c','d','e','f']
  let hexCode = '#'
  while(hexCode.length < 7){
    hexCode += digits[Math.floor(Math.random() * digits.length)]
  }
  return hexCode
}

export const getColorPalette = (color: string): ColorPalette => {
  const hsl = hexOrRGBToHSL(color)
  return {
    background: `hsl(${hsl.h}, ${hsl.s}%, ${clamp(35, hsl.l + 40, 95)}%)`,
    light: `hsl(${hsl.h}, ${hsl.s}%, ${clamp(25, hsl.l + 20, 95)}%)`,
    normal: `hsl(${hsl.h}, ${hsl.s}%, ${clamp(15, hsl.l, 75)}%)`,
    dark: `hsl(${hsl.h}, ${hsl.s}%, ${clamp(5, hsl.l - 20, 65)}%)`,
  }
}

const hexOrRGBToHSL = (color: string): Hsl => {
  try {
    if (color.startsWith('#')) {
      return hexToHSL(color)
    } else if (color.startsWith('rgb')) {
      const rgbStringParts = color.split(',')
      const rgb = {
        r: Number.parseInt(rgbStringParts[0].replace('rgb(', '').trim()),
        g: Number.parseInt(rgbStringParts[1].trim()),
        b: Number.parseInt(rgbStringParts[2].replace(')', '').trim()),
      }
      return RGBToHSL(rgb)
    }
    throw new Error(`Unknown color: [${color}], make sure you are passing in hex or rgb string. Returning Seppo magenta HSL.`)
  } catch (error) {
    console.error(error)
    return { h: 336, s: 58, l: 52 }
  }
}

// code below taken from: https://www.jameslmilner.com/posts/converting-rgb-hex-hsl-colors/
const hexToHSL = (hex: string): Hsl => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) {
    throw new Error('Could not parse Hex Color')
  }

  const r = parseInt(result[1], 16) / 255
  const g = parseInt(result[2], 16) / 255
  const b = parseInt(result[3], 16) / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  let h = (max + min) / 2
  let s = h
  let l = h

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l }
  }

  const d = max - min
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0)
      break
    case g:
      h = (b - r) / d + 2
      break
    case b:
      h = (r - g) / d + 4
      break
  }
  h /= 6

  h = Math.round(360 * h)
  s = Math.round(s * 100)
  l = Math.round(l * 100)

  return { h, s, l }
}

const RGBToHSL = (rgb: Rgb): Hsl => {
  const { r: r255, g: g255, b: b255 } = rgb

  const r = r255 / 255
  const g = g255 / 255
  const b = b255 / 255

  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)

  let h = (max + min) / 2
  let s = h
  let l = h

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l }
  }

  const d = max - min
  s = l >= 0.5 ? d / (2 - (max + min)) : d / (max + min)
  switch (max) {
    case r:
      h = ((g - b) / d + 0) * 60
      break
    case g:
      h = ((b - r) / d + 2) * 60
      break
    case b:
      h = ((r - g) / d + 4) * 60
      break
  }

  return { h: Math.round(h), s: Math.round(s * 100), l: Math.round(l * 100) }
}
