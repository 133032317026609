import { MapDimensions, MapType } from '../../api/gameTypes'
import { DEFAULT_MAP_OPTIONS } from '../../common/constants'
import { Game, GameAdvancedSettings, NotificationSettings } from '../../types/commonTypes'

export type GameBoard = {
  mapIndex?: number
  fileName: string
  name: string
  url: string
  mapOptions?: MapOptions
  dimensions?: MapDimensions
  worldPosition?: [x: number, y: number]
  doors?: Door[]
}

export type Door = {
  id: number
  boardIndex: number
  longitude: number
  latitude: number
  leadsToBoardIndex: number
  pair?: number
  isDefault?: boolean
}

export type MapOptions = {
  center: [longitude: number, latitude: number]
  zoom: number
}

export type GameBoardSettings = {
  gameBoardType: MapType
  isBranching?: boolean 
  mapOptions: MapOptions
  gameBoards: GameBoard[]
}

export type GameForm = Pick<
  Game,
  | 'name'
  | 'description'
  | 'gameBoardSettings'
  | 'language'
  | 'ages'
  | 'topics'
  | 'keywords'
> & {
  notificationSettings: Partial<NotificationSettings>
  advancedSettings: Partial<GameAdvancedSettings>
}

export type ShareGameForm = Pick<
  Game,
  | 'name'
  | 'description'
  | 'language'
  | 'ages'
  | 'topics'
  | 'keywords'
> & {
  shareToPublic: boolean
  shareToPrivate: boolean
  togglerError: boolean
  userName: string
  userEmail: string
  userPhone: string
}

export const DEFAULT_GAME_BOARD_SETTINGS: GameBoardSettings = {
  gameBoardType: MapType.STATIC,
  gameBoards: [],
  mapOptions: DEFAULT_MAP_OPTIONS,
}

export enum CreateGameOption {
  QUICK_START = 'QUICK_START',
  SEARCH_COMMUNITY = 'SEARCH_COMMUNITY',
  GAME_SETTINGS = 'GAME_SETTINGS',
}

export enum TabType {
  BUILD = 'Build',
  TEST = 'Test',
  PLAY = 'Play',
  REPORT = 'Report',
}

export type BadgeForm = {
  id?: number
  badgeName: string
  imageUrl?: string
  imageFile?: Blob
}
