import * as React from 'react'
import { SVGProps } from 'react'
const SvgStarOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 25 25' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M12.433 1.504a.069.069 0 0 0-.061.037L9.033 8.162a.75.75 0 0 1-.596.409l-6.44.638-.012.001a.055.055 0 0 0-.034.094l5.304 5.253a.75.75 0 0 1 .195.732l-1.963 7.133a.063.063 0 0 0 .09.07l.004-.001 6.52-3.23a.75.75 0 0 1 .665 0l6.533 3.236a.063.063 0 0 0 .088-.037.063.063 0 0 0 .002-.034l-1.964-7.133a.75.75 0 0 1 .195-.732l5.3-5.253a.055.055 0 0 0-.034-.094l-.011-.001-6.44-.638a.75.75 0 0 1-.596-.408L12.495 1.54a.07.07 0 0 0-.062-.037zm.73-.304.667-.342a1.57 1.57 0 0 0-2.793 0l-3.158 6.26-6.025.598a1.555 1.555 0 0 0-.959 2.653l4.993 4.945-1.849 6.715-.001.006a1.563 1.563 0 0 0 2.21 1.799l.002-.001 6.183-3.062 6.193 3.066h.002a1.563 1.563 0 0 0 2.21-1.798l-1.85-6.722 4.988-4.944a1.555 1.555 0 0 0-.959-2.653l-6.025-.597-3.16-6.261z'
      fill={props.color || 'currentColor'}
      fillRule='evenodd'
    />
  </svg>
)
export default SvgStarOutlineIcon

