import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { LibrarySource } from '../../api/gameTypes'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { useGameData } from '../../contexts/CombinedSearchContextProvider'
import { GameFilters } from '../../contexts/OwnGamesContextProvider'
import { useUser } from '../../contexts/userContext'
import { useDebounce } from '../../hooks/useDebounce'
import styles from './Search.module.css'
import { Filters } from './components/Filters'
import { LibrarySourceFilters } from './components/LibrarySourceFilters'
import { RefineSearch } from './components/RefineSearch'
import { ALL_GAME_FILTER_KEYS, ALL_SELECT_OPTION_VALUE } from './components/constants'
import { FilterKeys } from './components/types'

const getGameFiltersFromQuery = (searchParams: URLSearchParams): GameFilters => {
  return Array.from(searchParams.entries()).reduce((filters, [key, value]) => {
    return ALL_GAME_FILTER_KEYS.includes(key as keyof GameFilters) ? { ...filters, [key]: value } : filters
  }, {} as GameFilters)
}

export const Search = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const debouncedSearchParams = useDebounce(searchParams, 750)
  const { user } = useUser()

  const gameData = useGameData()
  const { setFilters, filters, gamesSourceCount, loading } = gameData

  const [hasConsumedInitialUrlParams, setHasConsumedInitialUrlParams] = useState<boolean>(false)
  const [initialFilters, setInitialFilters] = useState<GameFilters>()

  useEffect(() => {
    if (!hasConsumedInitialUrlParams) {
      setInitialFilters(getGameFiltersFromQuery(searchParams))
      setHasConsumedInitialUrlParams(true)
    }
  }, [hasConsumedInitialUrlParams, searchParams])

  useEffect(() => {
    const filters = getGameFiltersFromQuery(debouncedSearchParams)
    if (filters.search == null || filters.search.trim().length < 3 || setFilters == null) {
      return
    }
    setFilters({ ...filters })
  }, [debouncedSearchParams, setFilters])

  const handleSearchValueChange = (newSearch: string) => {
    setSearchParams((prev) => {
      prev.set('search', newSearch)
      return prev
    })
  }

  const handleFilterChange = (filterKey: keyof FilterKeys, newValue: string | boolean | null) => {
    setSearchParams((prev) => {
      if (!newValue || newValue === ALL_SELECT_OPTION_VALUE) {
        if (prev.get(filterKey) != null) {
          prev.delete(filterKey)
        }
      } else {
        prev.set(filterKey, newValue.toString())
      }
      return prev
    })
  }

  const handleSourceChange = (sourceValue: LibrarySource | typeof ALL_SELECT_OPTION_VALUE) => {
    handleFilterChange('source', sourceValue)
  }

  if (!hasConsumedInitialUrlParams) {
    return null
  }

  return (
    <div className={styles.mainContainer}>
      <h1>
        {loading
          ? t('search_page.title_loading', {
              defaultValue: 'Loading games for %{search_term}',
              search_term: filters?.search,
            })
          : t('search_page.title_with_results', {
              defaultValue: '%{count} games for %{search_term}',
              count: gamesSourceCount?.total,
              search_term: filters?.search,
            })}
      </h1>
      <LibrarySourceFilters
        gamesSourceCount={gamesSourceCount!}
        activeKey={(searchParams.get('source') as LibrarySource) ?? ALL_SELECT_OPTION_VALUE}
        onSelectSource={handleSourceChange}
        hasCommunity={user?.hasCommunity}
        hasOrgLibrary={user?.hasOrgLibrary}
        hasTemplates={user?.hasTemplates}
      />
      <RefineSearch initialValue={searchParams.get('search') || ''} onChange={handleSearchValueChange} />
      <div className={styles.gamesOverviewContainer}>
        <GamesOverview
          filterSection={
            <Filters
              onUpdateFilter={handleFilterChange}
              initialFilters={initialFilters}
              showSubjectFilter={user?.hasCommunity}
            />
          }
          gameData={gameData}
        />
      </div>
    </div>
  )
}
