import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { MapType } from '../../../../api/gameTypes'
import { Modal } from '../../../../common/components/Modal/Modal'
import { Button } from '../../../../common/components/button/Button'
import { CloseButton } from '../../../../common/components/button/CloseButton'
import { SettingsIcon } from '../../../../common/components/icons'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { useDisableBodyScroll } from '../../../../hooks/useDisableBodyScroll'
import { useFormSubmitFailureListener } from '../../../../hooks/useFormSubmitFailureListener'
import { useOnEscPress } from '../../../../hooks/useOnEscPress'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { EditorPermissions, Game } from '../../../../types/commonTypes'
import { areObjectsEqual, isEmpty } from '../../../../util/functional'
import { MapInstanceID, useMapApi } from '../../../../util/map'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { extendGameDataWithAdditionalMapSettings, validateGameForm } from '../../helpers'
import { DEFAULT_GAME_BOARD_SETTINGS, GameForm } from '../../types'
import styles from './GameSettingsModal.module.css'
import { Accordion } from './components/Accordion'
import { ADVANCED_PIN_SECTION_ID, Advanced } from './components/Advanced'
import { General } from './components/General'
import { Notifications } from './components/Notifications'

type GameSettingsProps = {
  gameData?: Game
  scrollToPlayerSettings?: boolean
  permissions: EditorPermissions
  onClose: () => void
  onSubmit: (data: GameForm, moveTasks?: boolean) => Promise<void>
  onSwitchToQuickStartWizard: () => void
  onOpenBadgeManager: () => void
}

export const GameSettingsModal: React.FC<GameSettingsProps> = ({
  gameData,
  scrollToPlayerSettings,
  permissions,
  onClose,
  onSubmit,
  onSwitchToQuickStartWizard,
  onOpenBadgeManager,
}) => {
  const { t } = useTranslation()
  const { requestConfirmation } = useConfirmation()
  const { map } = useMapApi(MapInstanceID.MODAL)
  const focusTrapRef = useTrapFocus<HTMLFormElement>()
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  useDisableBodyScroll()

  const [submittingManually, setSubmittingManually] = useState<boolean>(false)

  const [moveTasks, setMoveTasks] = useState<boolean>()
  const onToggleMoveTasks = () => setMoveTasks((prev) => !prev)

  const [initialValues] = useState<Partial<GameForm>>({
    advancedSettings: {
      ...gameData?.advancedSettings,
      chatEnabled: gameData?.advancedSettings.chatEnabled ?? true,
      gpsEnabled: gameData?.advancedSettings.gpsEnabled ?? true,
    },
    ages: gameData?.ages,
    description: gameData?.description,
    gameBoardSettings: gameData?.gameBoardSettings,
    keywords: gameData?.keywords,
    language: gameData?.language,
    name: gameData?.name,
    notificationSettings: gameData?.notificationSettings,
    topics: gameData?.topics,
  })

  const onCloseInternal = useCallback(
    (hasChanges: boolean) => {
      if (hasChanges) {
        requestConfirmation({
          title: t('game_editor.game_settings.confirm_closing_title', 'Unsaved changes'),
          text: t(
            'game_editor.game_settings.confirm_closing_text',
            'Are you sure you want to exit? Any unsaved changes will be lost.',
          ),
        }).then((response) => {
          if (response) {
            onClose()
          }
        })
      } else {
        onClose()
      }
    },
    [requestConfirmation, onClose, t],
  )

  useOnEscPress(() => {
    onCloseInternal(true)
  })

  const onClickManageBadgesInternal = async (values: GameForm, hasChanges: boolean) => {
    if (!hasChanges) {
      onClose()
      onOpenBadgeManager()
      return
    }
    const response = await requestConfirmation({
      title: t(
        'game_editor.game_settings.confirm_switching_to_badge_manager_title',
        'You need to save changes before continuing',
      ),
      text: t(
        'game_editor.game_settings.confirm_switching_to_badge_manager_text',
        'To edit badges, you need to save changes',
      ),
      confirmActionText: t(
        'game_editor.game_settings.confirm_switching_to_badge_manager_confirm_button',
        'Save and continue',
      ),
    })
    if (response) {
      const errors = validate(values)
      if (!isEmpty(errors)) {
        submitButtonRef.current?.click()
        return
      }
      setSubmittingManually(true)
      const didSubmit = await handleSubmit(values)
      setSubmittingManually(false)
      if (didSubmit) {
        onClose()
        onOpenBadgeManager()
      }
    }
  }

  const handleSubmit = async (values: GameForm) => {
    const submitValues = extendGameDataWithAdditionalMapSettings(values, map)
    if (
      submitValues.gameBoardSettings.gameBoardType !== MapType.LIVE &&
      submitValues.gameBoardSettings.gameBoards.length === 0 &&
      initialValues.gameBoardSettings?.gameBoardType != null &&
      initialValues.gameBoardSettings?.gameBoards.length
    ) {
      const shouldProceed = await requestConfirmation({
        title: t('game_editor.game_boards.deleted_all_boards_during_edit_confirmation_title', 'You deleted all boards'),
        text: t(
          'game_editor.game_boards.deleted_all_boards_during_edit_confirmation_text',
          'You removed all previously set game boards. If you click confirm, the game will keep the first previous board. If you want to use a different board, click cancel and choose another board.',
        ),
      })
      if (shouldProceed) {
        const submitValuesWithGameBoard: GameForm = {
          ...submitValues,
          gameBoardSettings: {
            ...submitValues.gameBoardSettings,
            gameBoardType: initialValues.gameBoardSettings.gameBoardType,
            gameBoards: [initialValues.gameBoardSettings.gameBoards[0]],
          },
        }
        await onSubmit(submitValuesWithGameBoard, moveTasks)
        return true
      } else {
        return false
      }
    } else {
      await onSubmit(submitValues, moveTasks)
      return true
    }
  }

  const validate = (values: Partial<GameForm>) => {
    return validateGameForm(values, t)
  }

  const anyTasksWithPositionLock =
    gameData?.gameBoardSettings.gameBoardType === MapType.LIVE &&
    gameData?.tasks.some((task) => task.advanced.hasProximityLock)

  const { submitFailedToken, submitListener } = useFormSubmitFailureListener()

  const scrollableContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToPlayerSettings) {
      const offsetTop = document.getElementById(ADVANCED_PIN_SECTION_ID)?.offsetTop
      if (offsetTop && scrollableContainerRef.current) {
        scrollableContainerRef.current.scrollTo({ top: offsetTop - 120, behavior: 'smooth' })
      }
    }
  }, [scrollToPlayerSettings])

  return (
    <Modal>
      <Form<GameForm>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        decorators={[submitListener]}
      >
        {({ handleSubmit, submitting, values, errors }) => (
          <form onSubmit={handleSubmit} ref={focusTrapRef} className={styles.container}>
            <div className={styles.header}>
              <span>
                <SettingsIcon color={'var(--primary-normal)'} />
                <h4>{t('game_editor.game_settings.title', 'Game settings')}</h4>
              </span>
              <CloseButton
                disabled={submitting || submittingManually}
                autoFocus
                onClick={() => onCloseInternal(!areObjectsEqual(values, initialValues))}
              />
            </div>
            <div className={styles.content} ref={scrollableContainerRef}>
              <div className={styles.descriptionContainer}>
                <h4>
                  {permissions.actions.modifyGameSettings
                    ? t('game_editor.game_settings.game_information_title', 'Define your game information')
                    : t('game_editor.game_settings.game_information_title_view_only', 'View game information')}
                </h4>
                <p>
                  {permissions.actions.modifyGameSettings
                    ? gameData?.editRestricted
                      ? t(
                          'game_editor.game_settings.game_information_description_edit_restricted',
                          'In this screen you can define your game properties - some fields are not editable.',
                        )
                      : t(
                          'game_editor.game_settings.game_information_description',
                          'In this screen you can define your game properties - make sure to give your game a name and a game board.',
                        )
                    : t(
                        'game_editor.game_settings.game_information_description_view_only',
                        'In this screen you can view the game properties - for any changes, contact the game instructor.',
                      )}
                  {gameData == null && (
                    <>
                      {' '}
                      <Trans i18nKey={'game_editor.game_settings.game_information_quick_start_wizard_link'}>
                        If you have issues try our{' '}
                        <button type='button' className={styles.linkButton} onClick={onSwitchToQuickStartWizard}>
                          Quick Setup Wizard
                        </button>
                      </Trans>
                    </>
                  )}
                </p>
              </div>
              <Accordion
                title={t('game_editor.game_settings.general.title', 'General')}
                isOpen
                hasAnyErrors={!safeIsNullOrEmpty(errors?.name)}
                forceExpandToken={submitFailedToken}
              >
                <General
                  initialGameBoardSettings={values.gameBoardSettings || DEFAULT_GAME_BOARD_SETTINGS}
                  advancedSettings={values.advancedSettings}
                  moveTasks={moveTasks}
                  onToggleMoveTasks={(gameData?.tasks.length ?? 0) > 0 ? onToggleMoveTasks : undefined}
                  permissions={permissions}
                />
              </Accordion>
              <Accordion
                title={t('game_editor.game_settings.notifications.title', 'Notifications')}
                hasAnyErrors={!isEmpty(errors?.notificationSettings)}
                forceExpandToken={submitFailedToken}
              >
                <Notifications summaryEnabled={values.notificationSettings?.summaryEnabled} permissions={permissions} />
              </Accordion>
              <Accordion
                title={t('game_editor.game_settings.advanced.title', 'Advanced')}
                hasAnyErrors={!isEmpty(errors?.advancedSettings)}
                forceExpandToken={submitFailedToken}
                isOpen={scrollToPlayerSettings}
              >
                <Advanced
                  gameId={gameData?.gameId}
                  mapType={values.gameBoardSettings?.gameBoardType}
                  boardCount={values.gameBoardSettings?.gameBoards.length}
                  advancedSettings={values.advancedSettings}
                  hasTasksWithPositionLock={anyTasksWithPositionLock}
                  badges={gameData?.badges ?? []}
                  onClickManageBadges={() =>
                    onClickManageBadgesInternal(values, !areObjectsEqual(values, initialValues))
                  }
                  permissions={permissions}
                />
              </Accordion>
            </div>
            <div className={styles.footer}>
              <Button
                disabled={submitting || submittingManually}
                variant='outline-normal'
                onClick={() => onCloseInternal(!areObjectsEqual(values, initialValues))}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              {permissions.actions.modifyGameSettings && (
                <Button ref={submitButtonRef} disabled={submitting || submittingManually} type='submit'>
                  {t('common.save', 'Save')}
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
    </Modal>
  )
}
