import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import {
  CreatableMultiSelectFormField,
  MultiSelectFormField,
  SelectFormField,
} from '../../../../../common/components/Form/SelectFormField/SelectFormField'
import { TextAreaFormField } from '../../../../../common/components/Form/TextAreaFormField/TextAreaFormField'
import { EditorPermissions, GameAdvancedSettings } from '../../../../../types/commonTypes'
import { getAgeOptions, getLanguageOptions, getSubjectOptions } from '../../../../search/components/constants'
import { GameBoardSettings, GameForm } from '../../../types'
import { GameBoardPicker } from '../../GameBoardsPicker/GameBoardsPicker'
import { GAME_DESCRIPTION_MAX_LENGTH } from '../../QuickStartWizard/steps/GameInfoStep'
import styles from '../GameSettingsModal.module.css'

const shouldDisableField = (permissions: EditorPermissions, generalField: keyof GameForm) => {
  if (typeof permissions.gameSettings === 'boolean') {
    return !permissions.gameSettings
  }
  return !permissions.gameSettings?.[generalField]
}

type GeneralProps = {
  initialGameBoardSettings: GameBoardSettings
  advancedSettings: Partial<GameAdvancedSettings>
  moveTasks?: boolean
  permissions: EditorPermissions
  onToggleMoveTasks?: () => void
}

export const General: React.FC<GeneralProps> = ({
  initialGameBoardSettings,
  advancedSettings,
  moveTasks,
  permissions,
  onToggleMoveTasks,
}) => {
  const { t } = useTranslation()
  const { input: gameBoardSettingsInput } = useField('gameBoardSettings')

  return (
    <div className={styles.generalSection}>
      <InputFormField
        name='name'
        label={t('game_editor.game_settings.general.game_name_label', 'Game name')}
        disabled={shouldDisableField(permissions, 'name')}
      />
      <TextAreaFormField
        name='description'
        label={t('game_editor.game_settings.general.description_label', 'Description')}
        placeholder={t(
          'game_editor.game_settings.general.description_placeholder',
          'Write a general description of the game',
        )}
        fieldContainerClassName={styles.descriptionFieldContainer}
        className={styles.descriptionField}
        maxLength={GAME_DESCRIPTION_MAX_LENGTH}
        disabled={shouldDisableField(permissions, 'description')}
      />
      <div className={styles.dropdownsContainer}>
        <MultiSelectFormField
          name='topics'
          label={t('game_editor.game_settings.general.topics_label', 'Topics')}
          placeholder={t('game_editor.game_settings.general.topics_placeholder', 'Select topics')}
          options={getSubjectOptions(t)}
          isSearchable
          disabled={shouldDisableField(permissions, 'topics')}
        />
        <SelectFormField
          name='language'
          label={t('game_editor.game_settings.general.language_label', 'Language')}
          placeholder={t('game_editor.game_settings.general.language_placeholder', 'Select language')}
          options={getLanguageOptions(t)}
          isSearchable
          disabled={shouldDisableField(permissions, 'language')}
        />
      </div>
      <div className={styles.dropdownsContainer}>
        <CreatableMultiSelectFormField
          label={t('game_editor.game_settings.general.keywords_label', 'Keywords')}
          name='keywords'
          placeholder={t('game_editor.game_settings.general.keywords_placeholder', 'Enter keywords')}
          disabled={shouldDisableField(permissions, 'keywords')}
        />
        <MultiSelectFormField
          name='ages'
          label={t('game_editor.game_settings.general.ages_label', 'Ages')}
          placeholder={t('game_editor.game_settings.general.ages_placeholder', 'Select age')}
          options={getAgeOptions(t)}
          disabled={shouldDisableField(permissions, 'ages')}
        />
      </div>
      <div className={styles.separator} />
      <GameBoardPicker
        isBranching={advancedSettings.allowBranching}
        initialValues={initialGameBoardSettings}
        onChange={gameBoardSettingsInput.onChange}
        isExploration={advancedSettings.explorationMode}
        moveTasks={moveTasks}
        onToggleMoveTasks={onToggleMoveTasks}
        viewOnly={shouldDisableField(permissions, 'gameBoardSettings')}
      />
    </div>
  )
}
