import * as React from 'react'
import { SVGProps } from 'react'
const SvgCameraGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='camera_gradient_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={3} y2={29}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path d='M19 9a8 8 0 1 0 .002 16.002A8 8 0 0 0 19 9zm-6 8a6 6 0 0 1 12 0 6 6 0 0 1-12 0z' fill='currentColor' />
      <path
        d='M19 9a8 8 0 1 0 .002 16.002A8 8 0 0 0 19 9zm-6 8a6 6 0 0 1 12 0 6 6 0 0 1-12 0z'
        fill='url(#camera_gradient_icon_svg__a)'
        fillOpacity={0.25}
      />
      <path
        d='M15.236 3a3.001 3.001 0 0 0-2.684 1.658L11.382 7H8V6a1 1 0 0 0-2 0v1H3a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3h-2.382l-1.17-2.342A3.001 3.001 0 0 0 22.764 3zM3 9a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1h-3a1 1 0 0 1-.894-.553l-1.447-2.894A1.001 1.001 0 0 0 22.764 5h-7.528c-.379 0-.726.214-.895.552l-1.446 2.895A1 1 0 0 1 12 9z'
        fill='currentColor'
      />
      <path
        d='M15.236 3a3.001 3.001 0 0 0-2.684 1.658L11.382 7H8V6a1 1 0 0 0-2 0v1H3a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3h-2.382l-1.17-2.342A3.001 3.001 0 0 0 22.764 3zM3 9a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1h-3a1 1 0 0 1-.894-.553l-1.447-2.894A1.001 1.001 0 0 0 22.764 5h-7.528c-.379 0-.726.214-.895.552l-1.446 2.895A1 1 0 0 1 12 9z'
        fill='url(#camera_gradient_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgCameraGradientIcon

