import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LibrarySource } from '../../../api/gameTypes'
import { PopupActionMenu } from '../../../common/components/PopupMenu/PopupActionMenu'
import { StatusPill } from '../../../common/components/StatusPill/StatusPill'
import { PeopleIcon, RecentsIcon } from '../../../common/components/icons'
import shared from '../../../common/styles/shared.module.css'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import { getDisplayGameOwnersLines } from '../../../util/game'
import { getFocusableGameItemId } from '../helpers'
import { GameMoreMenu } from './GameMoreMenu'
import styles from './GamesListItem.module.css'
import { GameInfo } from './card/GameInfo'

type ListItemProps = {
  game: TGameCard
  onGameAction: (action: GameAction, game: TGameCard) => void
}

export const GameListItem: React.FC<ListItemProps> = ({ game, onGameAction }) => {
  const { t } = useTranslation()
  const {
    id,
    modifiedAt,
    gameName,
    gameOwners,
    status,
    exercisesCount,
    secondaryStatus,
    playersCount,
    mapsCount,
    archivedAt,
  } = game
  const menuButtonId = getFocusableGameItemId(id)

  const handleMenuItemClick = (action: GameAction) => {
    onGameAction(action, game)
  }

  return (
    <tr>
      <td>
        <h5 className={styles.gameName}>{gameName}</h5>
      </td>
      <td>
        <StatusPill status={status} secondaryStatus={secondaryStatus} />
      </td>
      <td>
        <div className={styles.usersContainer}>
          <PeopleIcon fontSize={16} />
          <p className={classNames('tiny', shared.ml_075)}>
            {getDisplayGameOwnersLines(gameOwners).map((owner, i) => (
              <span key={`game-owner_${owner}:${i}`}>{owner}</span>
            ))}
          </p>
        </div>
      </td>
      <td>
        <div className={styles.lastModifiedContainer}>
          <RecentsIcon fontSize={16} />
          <p className={classNames('tiny', shared.ml_075)}>{modifiedAt}</p>
        </div>
      </td>
      <td>
        <div className={styles.infoContainer}>
          <span className={styles.infoItem}>
            <GameInfo
              status={status}
              showModifiedAt={false}
              exercisesNumber={exercisesCount}
              mapsNumber={mapsCount}
              playersCount={playersCount}
              archivedAt={archivedAt}
            />
          </span>
        </div>
      </td>
      <td>
        <PopupActionMenu
          id={menuButtonId}
          buttonClassName={styles.contextMenuButton}
          buttonTooltip={t('game_info.context_menu.tooltip', 'More actions')}
        >
          <GameMoreMenu
            onMenuItemClick={handleMenuItemClick}
            libraryMode={game.librarySource !== LibrarySource.OWN}
            isExpired={game.expired}
          />
        </PopupActionMenu>
      </td>
    </tr>
  )
}
