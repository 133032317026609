import mapboxgl, { GeoJSONSource } from 'mapbox-gl'
import { GeoJsonProperties, Feature, FeatureCollection, LineString } from 'geojson'
import { Task, TaskConnection } from '../../../../types/commonTypes'
import { arrowIcon } from './ConnectionLine'
import styles from './ConnectionLine.module.css'
import classNames from 'classnames'
import { getPinIdClass } from '../Marker/TaskPinHelper'

let currentMarkers: mapboxgl.Marker[] = []

const getRotation = (taskFrom: Task, taskTo: Task) => {
  //Need to do it with screen coordinates
  const fromCoords = document.getElementsByClassName(getPinIdClass(taskFrom.id))[0]?.getBoundingClientRect()
  const toCoords = document.getElementsByClassName(getPinIdClass(taskTo.id))[0]?.getBoundingClientRect()
  if (fromCoords && toCoords) {
    const dy = toCoords.y - fromCoords.y
    const dx = toCoords.x - fromCoords.x
    return Math.atan2(dy, dx)
  }
  return 0
}

const getIcon = (rotation: number, notReachable = false) => {
  const iconElement = document.createElement('div')
  iconElement.style.width = '16px'
  iconElement.style.height = '16px'
  const innerElement = document.createElement('span')
  innerElement.innerHTML = arrowIcon.replace('<svg', `<svg style="transform: rotate(${rotation}rad);"`)
  innerElement.className = classNames(styles.icon, notReachable && styles.notReachable)
  iconElement.appendChild(innerElement)
  return iconElement
}

const linesGeojson: FeatureCollection<LineString> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
  ],
}

export const refreshConnectionLinesToMap = (
  tasks: Task[],
  taskConnections: TaskConnection[],
  map: mapboxgl.Map,
  isReachable: (taskId: number) => boolean,
) => {
  currentMarkers.forEach((m) => {
    m.getElement().innerHTML = ''
    m.getElement().remove()
    m.remove()
  })

  currentMarkers = []
  const colorPrimary = document.body.style.getPropertyValue('--secondary-normal')
  const notReachableColor = document.body.style.getPropertyValue('--grey-500')

  const tasksLookup: { [key: number]: Task } = {}
  tasks.forEach((aTask) => (tasksLookup[aTask.id] = aTask))

  linesGeojson.features = []

  taskConnections.forEach((aConnection) => {
    const taskFrom = tasksLookup[aConnection.fromId]
    const taskTo = tasksLookup[aConnection.toId]
    if (taskFrom && taskTo) {
      const lineString: Feature<LineString, GeoJsonProperties> = {
        type: 'Feature',
        properties: {
          color: isReachable(taskFrom.id) ? colorPrimary : notReachableColor,
          connection: aConnection.fromId + '_' + aConnection.toId,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [taskFrom.y, taskFrom.x],
            [taskTo.y, taskTo.x],
          ],
        },
      }
      linesGeojson.features.push(lineString)
      if (isReachable(taskTo.id)) {
        const marker = new mapboxgl.Marker(getIcon(getRotation(taskFrom, taskTo), !isReachable(taskFrom.id)), { offset: [-4, 1] })
          .setLngLat([(taskFrom.y + taskTo.y)/2, (taskFrom.x + taskTo.x)/2])
          .addTo(map)
        currentMarkers.push(marker)
      }
    }
  })

  const linesSource = map.getSource('line') as GeoJSONSource
  linesSource.setData(linesGeojson)
}
