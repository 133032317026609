import classNames from 'classnames'
import { safeIsNullOrEmpty } from '../../../../util/string'
import styles from './FormError.module.css'

type FormSubmitErrorProps = {
  error: string | undefined
  modifiedSinceSubmit: boolean
  className?: string
}

export const FormSubmitError: React.FC<FormSubmitErrorProps> = ({
  error,
  modifiedSinceSubmit,
  className,
}) => {
  return (!safeIsNullOrEmpty(error) && !modifiedSinceSubmit) ? (
    <div className={classNames(styles.submitErrorContainer, className)}>
      { error }
    </div>
  ) : null
}
