import * as React from 'react'
import { SVGProps } from 'react'
const SvgDownloadsOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 18 24' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M8.702 3.168 6.396 5.475c-.281.28-.675.28-.956 0s-.281-.732 0-1.013L8.87.975a.777.777 0 0 1 .225-.17.959.959 0 0 1 .563 0c.028.029.07.057.112.085a.78.78 0 0 1 .113.085l3.487 3.487a.713.713 0 0 1 0 1.013.687.687 0 0 1-.506.225c-.169 0-.394-.113-.506-.225l-2.307-2.307v8.157c0 .337-.337.675-.675.675a.684.684 0 0 1-.675-.675zm5.625 4.613h1.407c1.18 0 2.137.956 2.193 2.081v11.25a2.137 2.137 0 0 1-2.137 2.138H3.134c-1.182 0-2.138-.957-2.138-2.082V9.918c0-1.18.956-2.137 2.138-2.137H4.54c.337 0 .675.337.675.675s-.281.675-.675.675H3.134a.684.684 0 0 0-.675.675v11.25c0 .337.28.675.675.675h12.6a.684.684 0 0 0 .675-.675V9.806a.684.684 0 0 0-.675-.675h-1.407c-.337 0-.675-.338-.675-.675s.282-.675.675-.675z'
      fill={props.color || 'currentColor'}
      fillRule='evenodd'
    />
  </svg>
)
export default SvgDownloadsOutlineIcon

