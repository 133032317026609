import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../../../api/gameTypes'
import { Game } from '../../../../../../types/commonTypes'
import { AddTimeSettings } from './AddTimeSettings'
import { AutomaticBadge } from './AutomaticBadge'
import { LockCodeSettings } from './LockCodeSettings'
import { PinIconSettings } from './PinIconSettings'
import { ProximitySettings } from './ProximitySettings'
import { SettingsToggle } from './SettingsToggle'
import styles from './TaskAdvancedSettings.module.css'

type TaskAdvancedSettingsProps = {
  game: Game
  maxPoints: number
  showAutomaticBadgeModal: boolean
  onSetShowAutomaticBadgeModal: (show: boolean) => void
  viewOnly: boolean
}

export const TaskAdvancedSettings: React.FC<TaskAdvancedSettingsProps> = ({
  game,
  maxPoints,
  showAutomaticBadgeModal,
  onSetShowAutomaticBadgeModal,
  viewOnly,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <SettingsToggle
        title={t('game_editor.tasks.advanced.flash_title', 'Flash task')}
        description={t(
          'game_editor.tasks.advanced.flash_description_disabled',
          'This will hide the task from the map and will instead be triggered by you or an instructor during the game',
        )}
        descriptionChecked={t(
          'game_editor.tasks.advanced.flash_description_enabled',
          'The task is hidden from the map and will instead be triggered by you or an instructor during the game',
        )}
        icon='taskFlashGradient'
        name='advanced.isFlash'
        disabled={viewOnly}
      />

      <SettingsToggle
        title={t('game_editor.tasks.advanced.picture_or_selfie_title', 'Send picture or selfie')}
        description={t(
          'game_editor.tasks.advanced.picture_or_selfie_description_disabled',
          'This will require players to add a photo when submitting this task',
        )}
        descriptionChecked={t(
          'game_editor.tasks.advanced.picture_or_selfie_description_enabled',
          'This will require players to add a photo when submitting this task',
        )}
        icon='cameraGradient'
        name='advanced.requirePictureOrSelfie'
        disabled={viewOnly}
      />

      {game.advancedSettings.badgesEnabled && (
        <SettingsToggle
          title={t('game_editor.tasks.advanced.automatic_badge_title', 'Automatically award badge')}
          description={t(
            'game_editor.tasks.advanced.automatic_badge_description_disabled',
            'This will give give a badge automatically to players who submit the task',
          )}
          descriptionChecked={t(
            'game_editor.tasks.advanced.automatic_badge_description_enabled',
            'This will give give a badge automatically to players who submit the task',
          )}
          icon='badgeGradient'
          name='advanced.hasAutomaticBadge'
          disabled={viewOnly}
        >
          <AutomaticBadge
            availableBadges={game.badges}
            maxPoints={maxPoints}
            showAutomaticBadgeModal={showAutomaticBadgeModal}
            onSetShowAutomaticBadgeModal={onSetShowAutomaticBadgeModal}
            noPointsGame={game.advancedSettings.noPointsGame}
            viewOnly={viewOnly}
          />
        </SettingsToggle>
      )}

      <SettingsToggle
        title={t('game_editor.tasks.advanced.time_limit_title', 'Time limit')}
        description={t(
          'game_editor.tasks.advanced.time_limit_description_disabled',
          'This will add a determined amount of time for answering the task after opening it',
        )}
        descriptionChecked={t(
          'game_editor.tasks.advanced.time_limit_description_enabled',
          'This will add a determined amount of time for answering the task after opening it',
        )}
        icon='timerGradient'
        name='advanced.hasTimeLimit'
        disabled={viewOnly}
      >
        <AddTimeSettings viewOnly={viewOnly} />
      </SettingsToggle>

      <SettingsToggle
        title={t('game_editor.tasks.advanced.code_locked_title', 'Code locked')}
        description={t(
          'game_editor.tasks.advanced.code_locked_description_disabled',
          'This will require a code from players to access the task',
        )}
        descriptionChecked={t(
          'game_editor.tasks.advanced.code_locked_description_enabled',
          'This will require a code from players to access the task',
        )}
        icon='lockGradient'
        name='advanced.hasLockCode'
        disabled={viewOnly}
      >
        <LockCodeSettings viewOnly={viewOnly} />
      </SettingsToggle>

      {game.gameBoardSettings.gameBoardType === MapType.LIVE && (
        <SettingsToggle
          title={t('game_editor.tasks.advanced.gps_location_locked_title', 'Location locked')}
          description={
            game.advancedSettings.gpsEnabled
              ? t(
                  'game_editor.tasks.advanced.gps_location_locked_description_disabled',
                  'This will require players to be in a certain area to be able to open the task',
                )
              : t(
                  'game_editor.tasks.advanced.gps_location_locked_description_unavailable_no_gps',
                  'Location lock unavailable if player GPS positioning is not enabled in game settings',
                )
          }
          descriptionChecked={t(
            'game_editor.tasks.advanced.gps_location_locked_description_enabled',
            'This will require players to be in a certain area to be able to open the task',
          )}
          icon='taskLocationLockedGradient'
          name='advanced.hasProximityLock'
          disabled={!game.advancedSettings.gpsEnabled || viewOnly}
        >
          <ProximitySettings viewOnly={viewOnly} />
        </SettingsToggle>
      )}

      {game.gameBoardSettings.gameBoardType === MapType.PANORAMA && (
        <SettingsToggle
          title={t('game_editor.tasks.advanced.hidden_task_title', 'Hidden task')}
          description={t(
            'game_editor.tasks.advanced.hidden_task_description_disabled',
            'The player must click on the area where the invisible icon is placed to access this task',
          )}
          descriptionChecked={t(
            'game_editor.tasks.advanced.hidden_task_description_enabled',
            'The player must click on the area where the invisible icon is placed to access this task',
          )}
          icon='taskHideGradient'
          name='advanced.isHidden'
          disabled={viewOnly}
        />
      )}

      <SettingsToggle
        title={t('game_editor.tasks.advanced.pin_icon_title', 'Pin icon')}
        description={t(
          'game_editor.tasks.advanced.pin_icon_description_disabled',
          'Set a different icon for marking tasks on the map',
        )}
        descriptionChecked={t(
          'game_editor.tasks.advanced.pin_icon_description_enabled',
          'Set a different icon for marking tasks on the map',
        )}
        icon='exercisePin'
        name='advanced.hasCustomIconType'
        disabled={viewOnly}
      >
        <PinIconSettings viewOnly={viewOnly} />
      </SettingsToggle>
    </div>
  )
}
