import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { externalRoutes } from '../../../routes'
import { dateTimeToLocalDateString } from '../../../util/string'
import { WelcomeBlockButton } from '../helpers'
import { BannerButton } from './BannerButton'
import styles from './LicenceExpiredBanner.module.css'

type ExpiredBannerProps = {
  title: string
  webshopBtnLabel: string
  webshopLink: string
  showHelpButton?: boolean
}

const ExpiredBanner: React.FC<PropsWithChildren<ExpiredBannerProps>> = ({
  title,
  webshopBtnLabel,
  webshopLink,
  showHelpButton,
  children,
}) => {
  const { t } = useTranslation()

  const btn_webshop: WelcomeBlockButton = {
    label: webshopBtnLabel,
    type: 'link-external',
    icon: 'starOutline',
    link: webshopLink,
  }

  const btn_help: WelcomeBlockButton | null = showHelpButton
    ? {
        label: t('expired_banner.licence_expired.action_button_contact_support', 'Support'),
        type: 'ninchat-form',
      }
    : null

  return (
    <div className={styles.licenceExpiredBanner}>
      <div className={styles.title}>{title}</div>
      <p className={classNames('normal', styles.text)}>{children}</p>
      <div className={styles.buttonsContainer}>
        <BannerButton button={btn_webshop} />
        {btn_help != null && <BannerButton button={btn_help} isSecondary />}
      </div>
    </div>
  )
}

type LicenceExpiredBannerProps = {
  validUntil: string
  hasValidSponsoredGames: boolean
}

export const LicenceExpiredBanner: React.FC<LicenceExpiredBannerProps> = ({ validUntil, hasValidSponsoredGames }) => {
  const { t } = useTranslation()
  return (
    <ExpiredBanner
      title={t('expired_banner.licence_expired.title', 'Your licence has expired')}
      webshopBtnLabel={t('expired_banner.licence_expired.action_button_renew_licence', 'Renew licence')}
      webshopLink={externalRoutes.webshop}
      showHelpButton
    >
      {hasValidSponsoredGames ? (
        <Trans
          i18nKey='expired_banner.licence_expired.text_with_valid_sponsored_games'
          components={{ newline: <br /> }}
          values={{ valid_until_date: dateTimeToLocalDateString(validUntil) }}
        >
          {
            'Your licence was valid until %{valid_until_date}. To continue creating games with Seppo, renew your licence or contact us for support.<newline/><newline/>You can continue using any active sponsored games that you downloaded.'
          }
        </Trans>
      ) : (
        <Trans
          i18nKey='expired_banner.licence_expired.text_without_valid_sponsored_games'
          components={{ newline: <br /> }}
          values={{ valid_until_date: dateTimeToLocalDateString(validUntil) }}
        >
          {
            'Your licence was valid until %{valid_until_date}.<newline/>To continue using Seppo, renew your licence or contact us for support.'
          }
        </Trans>
      )}
    </ExpiredBanner>
  )
}

export const SponsoredGamesExpiredBanner: React.FC = () => {
  const { t } = useTranslation()
  return (
    <ExpiredBanner
      title={t(
        'expired_banner.sponsored_games_expired.title',
        'Oops! It looks like your game has reached its expiration date',
      )}
      webshopLink={externalRoutes.webshopSponsoredGames}
      webshopBtnLabel={t('expired_banner.sponsored_games_expired.action_button_visit_webshop', 'Visit shop')}
    >
      {t(
        'expired_banner.sponsored_games_expired.text',
        "But don't worry. You can easily get a fresh copy from our shop. Just remember to acquire an activation code from your sponsor. All sponsored games stay active for a whole year from the day you download them. Let's get you back in the game!",
      )}
    </ExpiredBanner>
  )
}
