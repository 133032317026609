import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel9Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_9_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-.509 5.608c1.035 0 1.926.213 2.672.64.747.427 1.323 1.05 1.728 1.872.406.81.608 1.824.608 3.04 0 1.29-.245 2.384-.736 3.28-.48.896-1.146 1.579-2 2.048-.853.47-1.845.704-2.976.704A7.382 7.382 0 0 1 13.096 18a4.432 4.432 0 0 1-1.408-.576l.96-1.904c.31.213.64.363.992.448.352.075.72.112 1.104.112.96 0 1.723-.293 2.288-.88.536-.546.822-1.336.86-2.37-.05.05-.102.1-.156.146-.33.277-.714.49-1.152.64a4.859 4.859 0 0 1-1.472.208 4.37 4.37 0 0 1-1.952-.432 3.574 3.574 0 0 1-1.392-1.216c-.341-.523-.512-1.136-.512-1.84 0-.768.187-1.43.56-1.984a3.763 3.763 0 0 1 1.536-1.28 4.929 4.929 0 0 1 2.144-.464zm.16 1.936c-.373 0-.698.07-.976.208-.277.128-.496.32-.656.576-.16.245-.24.539-.24.88 0 .512.17.923.512 1.232.352.299.816.448 1.392.448.374 0 .704-.07.992-.208.299-.15.528-.352.688-.608.16-.256.24-.544.24-.864s-.08-.603-.24-.848a1.48 1.48 0 0 0-.656-.592c-.288-.15-.64-.224-1.056-.224zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-.509 5.608c1.035 0 1.926.213 2.672.64.747.427 1.323 1.05 1.728 1.872.406.81.608 1.824.608 3.04 0 1.29-.245 2.384-.736 3.28-.48.896-1.146 1.579-2 2.048-.853.47-1.845.704-2.976.704A7.382 7.382 0 0 1 13.096 18a4.432 4.432 0 0 1-1.408-.576l.96-1.904c.31.213.64.363.992.448.352.075.72.112 1.104.112.96 0 1.723-.293 2.288-.88.536-.546.822-1.336.86-2.37-.05.05-.102.1-.156.146-.33.277-.714.49-1.152.64a4.859 4.859 0 0 1-1.472.208 4.37 4.37 0 0 1-1.952-.432 3.574 3.574 0 0 1-1.392-1.216c-.341-.523-.512-1.136-.512-1.84 0-.768.187-1.43.56-1.984a3.763 3.763 0 0 1 1.536-1.28 4.929 4.929 0 0 1 2.144-.464zm.16 1.936c-.373 0-.698.07-.976.208-.277.128-.496.32-.656.576-.16.245-.24.539-.24.88 0 .512.17.923.512 1.232.352.299.816.448 1.392.448.374 0 .704-.07.992-.208.299-.15.528-.352.688-.608.16-.256.24-.544.24-.864s-.08-.603-.24-.848a1.48 1.48 0 0 0-.656-.592c-.288-.15-.64-.224-1.056-.224zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_9_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel9Icon

