import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel6Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_6_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm.563 17.192c-1.024 0-1.914-.213-2.672-.64-.746-.427-1.322-1.05-1.728-1.872-.405-.821-.608-1.835-.608-3.04 0-1.29.24-2.384.72-3.28.49-.896 1.163-1.579 2.016-2.048.864-.47 1.856-.704 2.976-.704.598 0 1.168.064 1.712.192s1.014.32 1.408.576l-.96 1.904a2.734 2.734 0 0 0-.992-.432 4.67 4.67 0 0 0-1.104-.128c-.97 0-1.738.293-2.304.88-.528.548-.81 1.344-.844 2.386a3.055 3.055 0 0 1 1.292-.786c.448-.15.939-.224 1.472-.224.726 0 1.376.144 1.952.432s1.035.693 1.376 1.216c.352.523.528 1.136.528 1.84 0 .757-.192 1.419-.576 1.984-.373.555-.88.987-1.52 1.296-.63.299-1.344.448-2.144.448zm-.144-1.936c.363 0 .683-.064.96-.192.288-.139.512-.336.672-.592.16-.256.24-.55.24-.88 0-.512-.176-.917-.528-1.216-.341-.31-.8-.464-1.376-.464-.384 0-.72.075-1.008.224a1.69 1.69 0 0 0-.688.592c-.16.245-.24.533-.24.864 0 .32.08.608.24.864.16.245.384.443.672.592.288.139.64.208 1.056.208zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm.563 17.192c-1.024 0-1.914-.213-2.672-.64-.746-.427-1.322-1.05-1.728-1.872-.405-.821-.608-1.835-.608-3.04 0-1.29.24-2.384.72-3.28.49-.896 1.163-1.579 2.016-2.048.864-.47 1.856-.704 2.976-.704.598 0 1.168.064 1.712.192s1.014.32 1.408.576l-.96 1.904a2.734 2.734 0 0 0-.992-.432 4.67 4.67 0 0 0-1.104-.128c-.97 0-1.738.293-2.304.88-.528.548-.81 1.344-.844 2.386a3.055 3.055 0 0 1 1.292-.786c.448-.15.939-.224 1.472-.224.726 0 1.376.144 1.952.432s1.035.693 1.376 1.216c.352.523.528 1.136.528 1.84 0 .757-.192 1.419-.576 1.984-.373.555-.88.987-1.52 1.296-.63.299-1.344.448-2.144.448zm-.144-1.936c.363 0 .683-.064.96-.192.288-.139.512-.336.672-.592.16-.256.24-.55.24-.88 0-.512-.176-.917-.528-1.216-.341-.31-.8-.464-1.376-.464-.384 0-.72.075-1.008.224a1.69 1.69 0 0 0-.688.592c-.16.245-.24.533-.24.864 0 .32.08.608.24.864.16.245.384.443.672.592.288.139.64.208 1.056.208zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_6_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel6Icon

