import { ApiResponse, TUser, Try, toFailure, toSuccess } from '../types/commonTypes'
import { camelToSnakeCase } from '../util/string'
import { createAxios } from './axios'
import { GetLibraryGamesResponse } from './gameTypes'
import {
  GetUserInfoParams,
  LoginParams,
  NewTrialParams,
  PasswordResetParams,
  PlayerAccountSearchResponse,
  PostFeedbackParams,
  PostFeedbackResponse,
  UpdateUserInfoParams,
  User,
} from './userTypes'

export const TEST_USER_AUTHORIZATION = null //'ad5dec1598a7043657d92c2c990b7ce8' //'testuser@seppo.io'
export const TEST_USER_ID = null

export async function getUserInfo({ authorization = TEST_USER_AUTHORIZATION }: GetUserInfoParams): Promise<Try<User>> {
  try {
    const response = await createAxios(authorization).get<User>('/user_info', {
      params: {},
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

const mapTUserKeyToUserDataKey = (key: keyof TUser) => {
  return key === 'chatName' ? 'real_name' : camelToSnakeCase(key)
}

export async function updateUserInfo({
  authorization = TEST_USER_AUTHORIZATION,
  userId,
  updateData,
}: UpdateUserInfoParams): Promise<Try<User>> {
  try {
    const response = await createAxios(authorization).put<User>(`/users/${userId}.json`, {
      user: { [mapTUserKeyToUserDataKey(updateData.key)]: updateData.value },
      id: userId,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function login({
  userName,
  password,
  language = 'en',
  teacherCode,
  myRandom,
  gToken,
  fbToken,
  resendPassword = false,
  trialConfirm,
}: LoginParams): Promise<Try<User>> {
  try {
    console.log('Trying to login now')
    const response = await createAxios().post<User>('/users/login', {
      /* eslint-disable camelcase */
      ux3: true,
      login_type: 'json_reply',
      disable_cookies: true,
      username: userName,
      password: password,
      language: language,
      random: myRandom,
      ...((teacherCode || '').length > 0 ? { teacher_code: teacherCode } : {}),
      ...((gToken || '').length > 0 ? { g_token: gToken } : {}),
      ...((fbToken || '').length > 0 ? { fb_token: fbToken, email: userName } : {}),
      ...(resendPassword ? { resend_password: resendPassword } : {}),
      ...(trialConfirm ? { trial_confirm_email: trialConfirm } : {}),
      /* eslint-enable camelcase */
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

// A new user object has been created already, with this request the backend creates a Trial account and returns suggested games
export async function startNewTrial(params: NewTrialParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const query = Object.entries<string>(params)
      .map(([key, value]) => `${key}=${encodeURI(value)}`)
      .join('&')
    const response = await createAxios().get<GetLibraryGamesResponse>(`/ux3_trial?${query}`, {})

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

// TODO: this implementation is not that perfect, should be done properly
export const sendPasswordResetLink = async ({
  authorization = TEST_USER_AUTHORIZATION,
  userEmail: userName,
}: PasswordResetParams): Promise<Try<any>> => {
  try {
    await createAxios(authorization).post('/password_resets', {
      name: userName,
    })
    return toSuccess(true)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function submitTrialFeedback({
  authorization = TEST_USER_AUTHORIZATION,
  feedbackMessage,
}: PostFeedbackParams): Promise<Try<PostFeedbackResponse>> {
  try {
    const response = await createAxios(authorization).post<PostFeedbackResponse>('/trial_feedback', {
      feedback_text: feedbackMessage,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

//https://staging.seppo.io/gamer_accounts/sort_players_by_classes.json
export async function searchPlayerAccounts({
  authorization = TEST_USER_AUTHORIZATION,
  tagIds,
  search,
  page,
  showAll = false,
}: {
  authorization?: null
  tagIds: string[]
  search: string
  page: number
  showAll?: boolean
}): Promise<Try<PlayerAccountSearchResponse>> {
  try {
    const response = await createAxios(authorization).post<PlayerAccountSearchResponse>(
      '/gamer_accounts/sort_players_by_classes.json',
      {
        class_ids: { '0': tagIds.length ? tagIds : '0' }, // a bit strange format required by BE, "class_ids"=>{"0"=>["0"]} means to BE that no tag selected
        only_selected_classes: true,
        player_name_input: search,
        page_number: page,
        ...(showAll ? { show_all: showAll } : {}),
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function orderPasswordResetLink({ email }: { email: string }): Promise<Try<ApiResponse>> {
  try {
    const response = await createAxios(null).post<ApiResponse>(
      '/order_password_reset_link.json',
      {
        name: email,
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function resetPassword({ token, password, passwordConfirmation }: { token: string, password: string, passwordConfirmation: string }): Promise<Try<ApiResponse>> {
  try {
    const response = await createAxios(null).post<ApiResponse>(
      '/set_new_password.json',
      {
        reset_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}
