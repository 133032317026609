import { ToggleSwitch } from '../../common/components/ToggleSwitch/ToggleSwitch'
import { Button } from '../../common/components/button/Button'
import { IconTooltip } from '../../common/components/tooltip/IconTooltip'
import { useUser } from '../../contexts/userContext'
import { AllowedOption } from '../../types/commonTypes'
import styles from './Debug.module.css'

const ALL_OPTION_GROUPS = [
  {
    title: 'Editor',
    options: [
      {
        option: AllowedOption.ADD_PLAYERS_BY_EMAIL,
        description: 'Adding players by email',
      },
      {
        option: AllowedOption.GRADINGLICENCE,
        description: 'Adding evaluators',
      },
      {
        option: AllowedOption.STUDENTLICENCE,
        description: 'Adding student instructors',
      },
      {
        option: AllowedOption.COLLABORATION,
        description: 'Has create & comment task',
      },
      {
        option: AllowedOption.EXERCISE_CODE,
        description: 'Show the code button in TinyMCE, which lets you see html inside the editor',
      },
      {
        option: AllowedOption.ALLOW_NEW_EDITOR,
        description: 'Access to UX3',
      },
    ],
  },
  {
    title: 'Reporting',
    options: [
      {
        option: AllowedOption.SPONSORED_GAMES_REPORTING,
        description: 'Access to sponsored games report',
      },
      {
        option: AllowedOption.PLAYER_REPORTING,
        description: 'Access to players report',
      },
      {
        option: AllowedOption.TEACHER_REPORTING,
        description: 'Access to teacher report',
      },
    ],
  },
  {
    title: 'Library access',
    options: [
      {
        option: AllowedOption.CORPORATE_LIBRARY,
        description: 'Access to templates',
      },
      {
        option: AllowedOption.PRIVATE_LIBRARY,
        description: 'Probably linked to organization library but we check that from another user property',
      },
    ],
  },
  {
    title: 'Ignored editor options',
    options: [
      {
        option: AllowedOption.ALLOW_NEW_PLAYER_UI,
        description: 'If new player UI can be used, no toggle in UX3',
      },
      {
        option: AllowedOption.CUSTOM_CHAT,
        description: 'Ignored - if allowed, then the chat should allow audio messages',
      },
      {
        option: AllowedOption.P2P_MESSAGING,
        description: 'Ignored - If allowed players can see each others names (player side)',
      },
      {
        option: AllowedOption.ADVANCED_MODE,
        description: 'Ignored since everybody has it - exploration mode',
      },
      {
        option: AllowedOption.TREE_MODEL,
        description: 'Ignored since everybody has it - branching',
      },
      {
        option: AllowedOption.PLAYER_FEEDBACK,
        description: 'Ignored since everybody has it - requesting player feedback in game settings',
      },
      {
        option: AllowedOption.ORDERED_EXERCISES,
        description: 'Ignored since everybody has it - task ordering',
      },
      {
        option: AllowedOption.LEVELS,
        description: 'Ignored since everybody has it - levels',
      },
      {
        option: AllowedOption.MULTIMAPS,
        description: 'Ignored since everybody has it - multiple game boards',
      },
      {
        option: AllowedOption.NO_POINTS_GAME,
        description: 'Ignored since everybody has it - no points game',
      },
      {
        option: AllowedOption.PLAYERCONTINUESENTANS,
        description: 'Ignored since everybody has it - allowing players to continue sending answers',
      },
      {
        option: AllowedOption['360_MAPS'],
        description: 'Ignored since everybody has it - panorama',
      },
      {
        option: AllowedOption.MISSINGWORDEXERCISE,
        description: 'Ignored since everybody has it - missing word',
      },
      {
        option: AllowedOption.MATCHPAIRS,
        description: 'Ignored since everybody has it - match pairs',
      },
    ],
  },
  {
    title: 'Other',
    options: [
      {
        option: AllowedOption.GAME_PACKAGES,
        description: '',
      },
      {
        option: AllowedOption.PUBLICSUMMARY,
        description: '',
      },
      {
        option: AllowedOption.SUMMARY_DOWNLOAD,
        description: '',
      },
      {
        option: AllowedOption.EXPORT_RESULTS,
        description: '',
      },
    ],
  },
]

export const DebugAllowedOptions: React.FC = () => {
  const { user, DEVupdateUser, refreshUser } = useUser()

  if (user == null) {
    return null
  }

  const changeOptionCreator = (option: AllowedOption) => () => {
    const wasChecked = user.allowedOptions.includes(option)
    const newOptions = wasChecked ? user.allowedOptions.filter((o) => o !== option) : [...user.allowedOptions, option]
    DEVupdateUser({ allowedOptions: newOptions })
  }

  return (
    <>
      <div className={styles.allowedOptionsContainer}>
        {ALL_OPTION_GROUPS.map((optionGroup, i) => (
          <div key={`debug_option_group_${i}`}>
            <h5>{optionGroup.title}</h5>
            {optionGroup.options.map(({ option, description }, index) => (
              <div className={styles.allowedOptionsItem} key={`debug_option_${index}`}>
                <ToggleSwitch
                  checked={user.allowedOptions.includes(option)}
                  onChange={changeOptionCreator(option)}
                  small
                />
                <span onClick={changeOptionCreator(option)}>{option}</span>
                {description && <IconTooltip tooltipContent={description} iconColor='var(--grey-700)' />}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        <Button secondary variant='tiny' onClick={refreshUser}>reset user</Button>
      </div>
    </>
  )
}
