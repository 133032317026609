import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../common/components/loaders/spinner/Spinner'
import { useTheme } from '../contexts/ThemeContext'
import { useUser } from '../contexts/userContext'
import { routes } from '../routes'
import styles from './Protected.module.css'

export const Protected: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const { user, loginState } = useUser()
  const { logoUrl } = useTheme()
  const { t } = useTranslation()
  const [waitCounter, setWaitCounter] = useState(0)

  useEffect(() => {
    if (user == null) {
      if (loginState === 'PENDING') {
        if (waitCounter > 6) {
          navigate(routes.login)
        } else {
          setTimeout(() => {
            setWaitCounter(waitCounter + 1)
          }, 500)
        }
      } else if (loginState === 'LOGGED_OUT') {
        navigate(routes.login)
      }
    } else if (!user.termsAccepted && !user.gamePackages && !user.isGradingInstructor && !user.isStudentTeacher) { //Game package users, student instructors & evaluators not taken to wizard
      navigate(routes.onboardingWizard)
    } else if (user.isStudentTeacher || user.isGradingInstructor) {
      const userNameParts = user.name.split('-')
      const gameId = userNameParts[userNameParts.length - 1]
      // NOTE: should we look at preferred editor? Or always force ux3 since permissions might differ?
      navigate(routes.createGameEditorWithId(gameId, false))
    }
  }, [loginState, navigate, user, waitCounter])

  if (loginState === 'LOGGED_OUT' || loginState === 'PENDING') {
    return (
      <div className={styles.container}>
        <img src={logoUrl} alt='Logo' width={200} />
        <h2>{t('splash.session_status', 'Checking session status, please wait')}</h2>
        <Spinner remSize={4} />
      </div>
    )
  }

  return <>{children}</>
}
