import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useStorage } from '../hooks/useStorage'
import { ColorPalette, getColorPalette } from '../util/colors'
import { noop } from '../util/functional'
import { safeIsNullOrEmpty } from '../util/string'

const THEME_KEY = 'seppo-theme'

const DEFAULT_PRIMARY_COLOR_PALETTE: ColorPalette = {
  background: '#FFEBF5',
  light: '#FFD6EB',
  normal: '#CC3E76',
  dark: '#9F385D',
}

const DEFAULT_SECONDARY_COLOR_PALETTE: ColorPalette = {
  background: '#9ED6FF',
  light: '#38ACFF',
  normal: '#007ACF',
  dark: '#003E6B',
}

export const DEFAULT_THEME_SETTINGS: ThemeSettings = {
  logoUrl: '/Seppo_logo.png',
  faviconUrl: '/favicon.ico',
  colorPrimary: DEFAULT_PRIMARY_COLOR_PALETTE.normal,
  colorSecondary: DEFAULT_SECONDARY_COLOR_PALETTE.normal,
  tabName: 'Seppo',
}

type ThemeSettings = {
  logoUrl: string
  faviconUrl: string
  colorPrimary: string
  colorSecondary: string
  tabName: string
}

type ThemeContextValue = ThemeSettings & {
  updateThemeSettings: (settings: Partial<ThemeSettings>) => void
}

const INITIAL_VALUES: ThemeContextValue = {
  ...DEFAULT_THEME_SETTINGS,
  updateThemeSettings: noop,
}

const ThemeContext = createContext<ThemeContextValue>(INITIAL_VALUES)

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { storedValue, storeValue } = useStorage<ThemeSettings>(THEME_KEY, DEFAULT_THEME_SETTINGS)
  const [settings, setSettings] = useState<ThemeSettings>(storedValue ?? DEFAULT_THEME_SETTINGS)

  const updateThemeSettings = useCallback(
    ({ colorPrimary, colorSecondary, faviconUrl, logoUrl, tabName }: Partial<ThemeSettings>) => {
      setSettings((prev) => ({
        ...prev,
        ...(colorPrimary != null && { colorPrimary }),
        ...(colorSecondary != null && { colorSecondary }),
        ...(!safeIsNullOrEmpty(logoUrl) && { logoUrl }),
        ...(!safeIsNullOrEmpty(faviconUrl) && { faviconUrl }),
        ...(!safeIsNullOrEmpty(tabName) && { tabName }),
      }))
    },
    [],
  )

  useLayoutEffect(() => {
    const colors =
      settings.colorPrimary === DEFAULT_PRIMARY_COLOR_PALETTE.normal
        ? DEFAULT_PRIMARY_COLOR_PALETTE
        : getColorPalette(settings.colorPrimary)
    document.body.style.setProperty('--primary-background', colors.background)
    document.body.style.setProperty('--primary-light', colors.light)
    document.body.style.setProperty('--primary-normal', colors.normal)
    document.body.style.setProperty('--primary-dark', colors.dark)
    document.body.style.setProperty(
      '--welcome-block-dark',
      colors.normal === DEFAULT_PRIMARY_COLOR_PALETTE.normal ? '#e83e8c' : colors.normal,
    )
    document.body.style.setProperty(
      '--welcome-block-light',
      colors.normal === DEFAULT_PRIMARY_COLOR_PALETTE.normal ? '#f49fc6' : colors.light,
    )
    document.body.style.setProperty('--grey-500', '#c7c7c7')
  }, [settings.colorPrimary])

  useLayoutEffect(() => {
    const colors =
      settings.colorSecondary === DEFAULT_SECONDARY_COLOR_PALETTE.normal
        ? DEFAULT_SECONDARY_COLOR_PALETTE
        : getColorPalette(settings.colorSecondary)
    document.body.style.setProperty('--secondary-background', colors.background)
    document.body.style.setProperty('--secondary-light', colors.light)
    document.body.style.setProperty('--secondary-normal', colors.normal)
    document.body.style.setProperty('--secondary-dark', colors.dark)
  }, [settings.colorSecondary])

  useEffect(() => {
    const titleElement = document.querySelector('title')
    if (titleElement) titleElement.textContent = settings.tabName
  }, [settings.tabName])

  useEffect(() => {
    const faviconElement = document.querySelector('link[rel="icon"]')
    faviconElement?.setAttribute('href', settings.faviconUrl)
  }, [settings.faviconUrl])

  useEffect(() => {
    storeValue(settings)
  }, [storeValue, settings])

  return <ThemeContext.Provider value={{ ...settings, updateThemeSettings }}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) throw new Error('Expected to be wrapped in a ThemeContextProvider!')
  return context
}
