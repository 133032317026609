import { useTranslation } from 'react-i18next'
import { LibrarySource } from '../../../api/gameTypes'
import { MAX_COMMUNITY_COUNT_IN_COMBINED } from '../../../contexts/CombinedSearchContextProvider'
import { GamesSourceCount } from '../../../contexts/OwnGamesContextProvider'
import styles from '../Search.module.css'
import { LibrarySourceFilterItem } from './LibrarySourceFilterItem'
import { ALL_SELECT_OPTION_VALUE } from './constants'

type LibrarySourceFiltersProps = {
  gamesSourceCount: GamesSourceCount
  activeKey: LibrarySource | typeof ALL_SELECT_OPTION_VALUE
  onSelectSource: (source: LibrarySource | typeof ALL_SELECT_OPTION_VALUE) => void
  hasOrgLibrary?: boolean
  hasTemplates?: boolean
  hasCommunity?: boolean
}

export const LibrarySourceFilters: React.FC<LibrarySourceFiltersProps> = ({
  gamesSourceCount,
  activeKey,
  onSelectSource,
  hasOrgLibrary,
  hasCommunity,
  hasTemplates,
}) => {
  const { t } = useTranslation()

  const onSelectSourceInternal = (source: LibrarySource | typeof ALL_SELECT_OPTION_VALUE) => () => {
    onSelectSource(source)
  }

  const overMaxCommunity = gamesSourceCount.community > MAX_COMMUNITY_COUNT_IN_COMBINED

  return (
    <div className={styles.librarySourceFiltersContainer}>
      <LibrarySourceFilterItem
        label={
          overMaxCommunity
            ? t('search_page.library_source_filter.showing', {
                defaultValue: 'Showing %{games_count}',
                games_count:
                  gamesSourceCount.total -
                  gamesSourceCount.community +
                  MAX_COMMUNITY_COUNT_IN_COMBINED +
                  ' / ' +
                  gamesSourceCount.total,
              })
            : t('search_page.library_source_filter.all', {
                defaultValue: 'All (%{games_count})',
                games_count: gamesSourceCount.total,
              })
        }
        isActive={activeKey === ALL_SELECT_OPTION_VALUE}
        onClick={onSelectSourceInternal(ALL_SELECT_OPTION_VALUE)}
      />
      <LibrarySourceFilterItem
        label={t('search_page.library_source_filter.own_games', {
          defaultValue: 'Own games (%{games_count})',
          games_count: gamesSourceCount.own,
        })}
        isActive={activeKey === LibrarySource.OWN}
        onClick={onSelectSourceInternal(LibrarySource.OWN)}
      />
      {hasOrgLibrary && (
        <LibrarySourceFilterItem
          label={t('search_page.library_source_filter.org_library', {
            defaultValue: 'Org library (%{games_count})',
            games_count: gamesSourceCount.orgLibrary,
          })}
          isActive={activeKey === LibrarySource.ORG}
          onClick={onSelectSourceInternal(LibrarySource.ORG)}
        />
      )}
      {hasCommunity && (
        <LibrarySourceFilterItem
          label={t('search_page.library_source_filter.community', {
            defaultValue: 'Community (%{games_count})',
            games_count: gamesSourceCount.community,
          })}
          isActive={activeKey === LibrarySource.COMMUNITY}
          onClick={onSelectSourceInternal(LibrarySource.COMMUNITY)}
        />
      )}
      {hasTemplates && (
        <LibrarySourceFilterItem
          label={t('search_page.library_source_filter.templates', {
            defaultValue: 'Templates (%{games_count})',
            games_count: gamesSourceCount.template,
          })}
          isActive={activeKey === LibrarySource.TEMPLATE}
          onClick={onSelectSourceInternal(LibrarySource.TEMPLATE)}
        />
      )}
    </div>
  )
}
