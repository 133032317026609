import classNames from 'classnames'
import mapboxgl from 'mapbox-gl'
import styles from './PlayerMarker.module.css'
import { LocationInfo } from '../../../../contexts/GameContextHelper'

export const MAX_LOCATION_INFO_AGE = 1000 * 60 * 5 
const ACTIVE_LOCATION_INFO_AGE = 1000 * 60 * 1
const REFRESH_LOCATION_INFO_AGE = 1000 * 1.5
const DOT_SIZE = 16

const getIcon = (locationInfo: LocationInfo) => {
  const iconElement = document.createElement('div')
  iconElement.id = locationInfo.userId.toString()
  iconElement.title = locationInfo.nickName
  iconElement.style.width = `${DOT_SIZE}px`
  iconElement.style.height = `${DOT_SIZE}px`
  iconElement.style.borderRadius = `${DOT_SIZE / 2}px`
  iconElement.className = classNames(
    styles.icon,
    locationInfo.updatedAt < new Date(Date.now() - ACTIVE_LOCATION_INFO_AGE) && styles.icon_inactive,
  )
  return iconElement
}

let currentPlayerMarkers: mapboxgl.Marker[] = []
let playerMarkerTimers: { [key: string]: number } = {}

export const refreshPlayerMarkersToMap = (playerLocations: LocationInfo[], map: mapboxgl.Map) => {
  resetCurrentPlayerMarkers(playerLocations, map)
  getPlayerMarkersToRefresh(playerLocations).forEach((playerLocation) => {
    const marker = new mapboxgl.Marker(getIcon(playerLocation))
      .setLngLat([playerLocation.lng, playerLocation.lat])
      .addTo(map)
    marker.setDraggable(false)
    marker.setOffset([-DOT_SIZE / 2, -DOT_SIZE / 2])

    setTimeoutHandlers(
      marker,
      playerLocation.userId.toString(),
      playerLocation.updatedAt.getTime() - (Date.now() - ACTIVE_LOCATION_INFO_AGE),
      playerLocation.updatedAt.getTime() - (Date.now() - MAX_LOCATION_INFO_AGE),
    )

    currentPlayerMarkers.push(marker)
  })
}

//TimeoutHandlers will change marker state when it stops getting updates from some user,
//without having to loop through them elsewhere peridodically
const setTimeoutHandlers = (
  marker: mapboxgl.Marker,
  userId: string,
  timeToSetInactive: number,
  timeToRemove: number,
) => {
  window.clearTimeout(playerMarkerTimers[userId + 'inactive'])
  playerMarkerTimers[userId + 'inactive'] = window.setTimeout(() => {
    marker.getElement().className = marker.getElement().className + ' ' + classNames(styles.icon_inactive)
  }, timeToSetInactive)

  window.clearTimeout(playerMarkerTimers[userId + 'remove'])
  playerMarkerTimers[userId + 'remove'] = window.setTimeout(() => {
    marker.getElement().remove()
    marker.remove()
  }, timeToRemove)
}

const getPlayerMarkersToRefresh = (playerLocations: LocationInfo[]) => {
  //Avoid refreshing all player markers all the time, If there are hundreds of players visible flickering of dots might happen otherwise
  return playerLocations.filter((pl) => pl.updatedAt.getTime() > new Date().getTime() - REFRESH_LOCATION_INFO_AGE)
}

const resetCurrentPlayerMarkers = (playerLocations: LocationInfo[], map: mapboxgl.Map) => {
  //Delete markers that will get refreshed or are too old anyway
  const playerIdsToDelete = playerLocations
    .filter(
      (pl) =>
        pl.updatedAt.getTime() > new Date().getTime() - REFRESH_LOCATION_INFO_AGE ||
        pl.updatedAt.getTime() < new Date().getTime() - MAX_LOCATION_INFO_AGE,
    )
    .map((pl) => pl.userId.toString())
  currentPlayerMarkers = currentPlayerMarkers.filter((m) => {
    if (playerIdsToDelete.includes(m.getElement().id)) {
      m.getElement().remove()
      m.remove()
      return false
    } else {
      return true
    }
  })
}
