import React from 'react'
import { useTranslation } from 'react-i18next'
import { TGameCard } from '../../../../types/commonTypes'
import { StatusPill } from '../../../../common/components/StatusPill/StatusPill'
import styles from './Card.module.css'

type SimpleGameCardProps = {
  game: TGameCard
}

export const SimpleGameCard: React.FC<SimpleGameCardProps> = ({ game }) => {
  const { t } = useTranslation()

  const { thumbnail, status, gameName, modifiedAt, secondaryStatus } = game

  return (
    <div className={styles.simpleGameCardContainer}>
      <div className={styles.top} style={{ backgroundImage: `url('${thumbnail}')` }}>
        <StatusPill status={status} secondaryStatus={secondaryStatus} variant='card' />
      </div>
      <div className={styles.bot}>
        <h5 className={styles.gameTitle}>
          {gameName || t('game_info.missing_title_placeholder', 'Game title missing')}
        </h5>
        <span className='tiny'>{modifiedAt}</span>
      </div>
    </div>
  )
}
