import * as React from 'react'
import { SVGProps } from 'react'
const SvgVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30 8.6c-.3 0-.6.1-.9.2L22 12.4V11c0-2.8-2.2-5-5-5H5c-2.8 0-5 2.2-5 5v10c0 2.8 2.2 5 5 5h12c2.8 0 5-2.2 5-5v-1.1l7.1 3.3c.3.1.6.2.9.2.7 0 1.4-.4 1.8-1.1.1-.3.2-.6.2-.9V10.6c0-1.1-.9-2-2-2zm0 12.8-8.6-4c-.1 0-.1 0-.2-.1H20.6c-.1 0-.1.1-.2.1s-.1.1-.2.1c-.1.1-.1.1-.1.2l-.1.1V21c0 1.7-1.3 3-3 3H5c-1.7 0-3-1.3-3-3V11c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3v3.2c0 .1 0 .1.1.2l.1.1c0 .1.1.1.1.2 0 0 .1.1.2.1s.1.1.2.1h.5c.1 0 .1 0 .2-.1l8.6-4.2z'
      fill='currentColor'
    />
  </svg>
)
export default SvgVideoIcon

