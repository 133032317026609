import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import i18next from 'i18next'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Trans, useTranslation } from 'react-i18next'
import { AddEntityFieldButton } from '../../../../common/components/Form/AddEntityFieldButton/AddEntityFieldButton'
import { InputFormField } from '../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../common/components/Form/SelectFormField/SelectFormField'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { ModalPortal } from '../../../../common/components/Portal/Portal'
import { SelectVariant } from '../../../../common/components/Select/Select'
import { Button } from '../../../../common/components/button/Button'
import { CloseButton } from '../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../common/components/icons/utils'
import { ThreeDots } from '../../../../common/components/loaders/ThreeDots/ThreeDots'
import { useFadeAnimation } from '../../../../hooks/FadeAnimation/useFadeAnimation'
import { useDisableBodyScroll } from '../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { DeepPartial, FormErrorType, MemberRole, TAddMember } from '../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { getMemberRoleOptions } from '../helpers'
import styles from './AddMembersModal.module.css'

type AddMembersModalProps = {
  organizationName: string
  onSubmit: (values: AddMembersFormValues) => Promise<boolean>
  onClose: () => void
}

export type AddMembersFormValues = {
  members: TAddMember[]
}

const validate = (values: DeepPartial<AddMembersFormValues>): FormErrorType<AddMembersFormValues> => {
  return {
    members: (values.members || []).map((member) => ({
      name: safeIsNullOrEmpty(member?.name) ? i18next.t('validation_errors.required', 'Required') : undefined,
      email: safeIsNullOrEmpty(member?.email) ? i18next.t('validation_errors.required', 'Required') : undefined,
    })),
  }
}

const initialValues = { members: [{ name: '', email: '', role: MemberRole.INSTRUCTOR }] }

export const AddMembersModal: React.FC<AddMembersModalProps> = ({ onSubmit, organizationName, onClose }) => {
  const { t } = useTranslation()
  const trapFocusRef = useTrapFocus<HTMLDivElement>()
  useDisableBodyScroll()

  const { fadeTransition, closeWithDelay } = useFadeAnimation()

  const onCloseInternal = () => {
    closeWithDelay(350, onClose)
  }

  const onSubmitInternal = async (values: AddMembersFormValues) => {
    const isSuccess = await onSubmit(values)
    if (isSuccess) {
      onCloseInternal()
    }
  }

  return (
    <ModalPortal>
      <div className={fadeTransition}>
        <div className={styles.backdrop}>
          <div className={styles.container} ref={trapFocusRef}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  <span>{getIcon('settingsGradient')}</span>
                  <span className='medium'>
                    {t('settings_organization.members.add_members.title', {
                      organization_name: organizationName,
                      defaultValue: 'Add members to your organisation %{organization_name}',
                    })}
                  </span>
                </div>
                <CloseButton autoFocus onClick={onCloseInternal} />
              </div>
              <div className={classNames(styles.description, 'medium')}>
                <Trans i18nKey='settings_organization.members.add_members.description' components={{ 1: <br /> }}>
                  {
                    'Adding members to your organisation allows them to create and share content. They will receive an invitation email after you have added them.'
                  }
                </Trans>
              </div>
              <Form<AddMembersFormValues>
                onSubmit={onSubmitInternal}
                validate={validate}
                mutators={{
                  ...arrayMutators,
                }}
                initialValues={initialValues}
              >
                {({ handleSubmit, form, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    {submitting && (
                      <>
                        <div className={styles.loaderBackgroundOverlay} />
                        <div className={styles.loaderContainer}>
                          <ThreeDots />
                        </div>
                      </>
                    )}
                    <div className={styles.mainFormContainer}>
                      <div className={styles.rowsContainer}>
                        <FieldArray name='members'>
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div key={`member_${index}`} className={styles.addMemberRow}>
                                <InputFormField
                                  name={`${name}.name`}
                                  label={t('settings_organization.members.add_members.labels.name', 'Name')}
                                  placeholder={t(
                                    'settings_organization.members.add_members.placeholders.name',
                                    'Type name',
                                  )}
                                />
                                <InputFormField
                                  name={`${name}.email`}
                                  type='email'
                                  label={t('settings_organization.members.add_members.labels.email', 'Email')}
                                  placeholder={t(
                                    'settings_organization.members.add_members.placeholders.email',
                                    'Enter E-mail',
                                  )}
                                />
                                <div className={styles.lastColumn}>
                                  <SelectFormField
                                    name={`${name}.role`}
                                    label={t('settings_organization.members.add_members.labels.role', 'Role')}
                                    options={getMemberRoleOptions(t)}
                                    variant={SelectVariant.XLarge}
                                  />
                                  {fields?.length && fields.length > 1 && (
                                    <div className={styles.actionMenu}>
                                      <PopupActionMenu id='add-members-action-menu'>
                                        <PopupActionMenuItem
                                          icon='trash'
                                          text={t('common.delete', 'Delete')}
                                          onClick={() => fields.remove(index)}
                                        />
                                      </PopupActionMenu>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </div>
                      <AddEntityFieldButton
                        onClick={() => form.mutators.push('members', { role: MemberRole.INSTRUCTOR })}
                        disabled={submitting}
                      >
                        {t('settings_organization.members.add_members.button_text.add_member', 'Add member')}
                      </AddEntityFieldButton>
                    </div>
                    <div className={styles.footer}>
                      <Button variant='outline-normal' onClick={onCloseInternal} disabled={submitting}>
                        {t('settings_organization.members.add_members.button_text.cancel', 'Cancel')}
                      </Button>
                      <Button type='submit' disabled={submitting}>
                        {t('settings_organization.members.add_members.button_text.save', 'Save')}
                      </Button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
