import { TFunction } from 'i18next'
import SvgWelcomeDay01Icon from '../../common/components/icons/WelcomeDay01Icon'
import SvgWelcomeDay02Icon from '../../common/components/icons/WelcomeDay02Icon'
import SvgWelcomeDay04Icon from '../../common/components/icons/WelcomeDay04Icon'
import SvgWelcomeDay06Icon from '../../common/components/icons/WelcomeDay06Icon'
import SvgWelcomeDay08Icon from '../../common/components/icons/WelcomeDay08Icon'
import SvgWelcomeDay10Icon from '../../common/components/icons/WelcomeDay10Icon'
import SvgWelcomeDay12Icon from '../../common/components/icons/WelcomeDay12Icon'
import SvgWelcomeDay14Icon from '../../common/components/icons/WelcomeDay14Icon'
import { IconName } from '../../common/components/icons/utils'
import { TUser } from '../../types/commonTypes'
import {
  getCorporatePaying,
  getCorporateTrial,
  getEducationPaying,
  getEducationTrial,
  getGamePackagesWelcomeBlock,
  getSponsoredGamesWelcomeBlock,
} from './constants'
import { SEPPO_GROUPING_BUSINESS } from '../../api/typeConverters'

export type WelcomeBlockButton = {
  label: string
  icon?: IconName
  type: 'toggle-help' | 'ninchat-form' | 'link-internal' | 'link-external'
  link?: string
  linkFinnish?: string
}

export type WelcomeBlockData = {
  id: string
  title: string
  body: string
  primaryButton?: WelcomeBlockButton
  secondaryButton?: WelcomeBlockButton
}

const oneDayInMs = 86400000

export const getUserActiveDays = (createdAt: string) => {
  const createdAtTimeInMs = new Date(createdAt.toString()).getTime()
  const currentTimeInMs = new Date().getTime()
  return (currentTimeInMs - createdAtTimeInMs) / oneDayInMs
}

export const hasTrialPeriodExpired = (createdAt: string) => {
  return getUserActiveDays(createdAt) >= 15
}

export const welcomeBlockDataSelector = (
  { createdAt, isEducation, hasTemplates, isTrial, isSponsoredUser, activeBusiness }: TUser,
  t: TFunction,
) => {
  if (isSponsoredUser) {
    return getSponsoredGamesWelcomeBlock(t)
  }
  if (activeBusiness.industry === SEPPO_GROUPING_BUSINESS) {
    return getGamePackagesWelcomeBlock(t)
  }
  // If industry type is NOT Education - show Corporate
  // (there is a different industry type - show as corporate)
  // If user is NOT Trial - show Paying
  // (there is a DEMO user type - we show it as paying)
  const welcomeBlockData = isTrial
    ? isEducation || !hasTemplates
      ? getEducationTrial(t)
      : getCorporateTrial(t)
    : isEducation || !hasTemplates
    ? getEducationPaying(t)
    : getCorporatePaying(t)

  const lastKey: number = parseInt(Object.keys(welcomeBlockData)[Object.keys(welcomeBlockData).length - 1])

  const activeDays = getUserActiveDays(createdAt)
  if (activeDays < 2) {
    return welcomeBlockData[1]
  } else if (activeDays < 4) {
    return welcomeBlockData[2]
  } else if (activeDays < 6) {
    return welcomeBlockData[4]
  } else if (activeDays < 8) {
    return welcomeBlockData[6]
  } else if (activeDays < 10) {
    return welcomeBlockData[8]
  } else if (activeDays < 12) {
    return welcomeBlockData[10]
  } else if (activeDays < 14) {
    return welcomeBlockData[12]
  } else if (activeDays < 15) {
    return welcomeBlockData?.[14] ?? welcomeBlockData[lastKey]
  } else {
    return welcomeBlockData?.[15] ?? welcomeBlockData[lastKey]
  }
}

export const isMessageHidden = (id: string, stored: string[]) => {
  return stored.some((el) => el === id)
}

export const hideDistinctMessage = (
  id: string,
  storedValue: string[] | null,
  storeValue: (value: string[]) => void,
) => {
  let stored: string[] = []
  if (storedValue != null) {
    stored = [...storedValue, id]
    storeValue(stored)
  } else {
    storeValue([id])
  }
}

export const welcomeBannerIconSelector = (activeDays: number): JSX.Element => {
  if (activeDays < 2) {
    return <SvgWelcomeDay01Icon />
  } else if (activeDays < 4) {
    return <SvgWelcomeDay02Icon />
  } else if (activeDays < 6) {
    return <SvgWelcomeDay04Icon />
  } else if (activeDays < 8) {
    return <SvgWelcomeDay06Icon />
  } else if (activeDays < 10) {
    return <SvgWelcomeDay08Icon />
  } else if (activeDays < 12) {
    return <SvgWelcomeDay10Icon />
  } else if (activeDays < 14) {
    return <SvgWelcomeDay12Icon />
  } else {
    return <SvgWelcomeDay14Icon />
  }
}
