import classNames from 'classnames'
import React from 'react'
import { getIcon, IconName } from '../icons/utils'
import styles from './Tooltip.module.css'
import { Tooltip, TooltipProps } from './Tooltip'

type IconTooltipProps = Omit<TooltipProps, 'children'> & {
  iconName?: IconName
  iconColor?: string
  iconClassName?: string
}

export const IconTooltip: React.FC<IconTooltipProps> = ({
  iconName = 'info',
  iconColor = 'var(--primary-normal)',
  iconClassName,
  ...rest
}) => {
  return (
    <Tooltip timeoutMs={200} {...rest}>
      {({id, onMouseEnter, onMouseLeave}) => (
        <span
          tabIndex={0}
          className={classNames(styles.icon, iconClassName)}
          style={{ color: iconColor }}
          id={id}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onMouseEnter}
          onBlur={onMouseLeave}
        >
          {getIcon(iconName)}
        </span>
      )}
    </Tooltip>
  )
}
