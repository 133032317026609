import classNames from 'classnames'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useCallback, useMemo } from 'react'
import { Marker } from 'react-leaflet'
import { useGame } from '../../../../contexts/GameContext'
import { BoardCoords } from '../BoardSection/BoardImage'
import styles from './DraggableDoor.module.css'
import { DoorIconRaw, getDoorIdClass } from './DoorHelper'
import { Door, GameBoard } from '../../types'
import { Tooltipper } from '../BoardSection/BoardsOverview/Tooltipper'
import { DoorPopup } from './DoorPopup'

export const DOOR_WIDTH = 69
export const DOOR_HEIGHT = 69
export const DOOR_ANCHOR_Y_FIX = 8

const getIcon = (door: Door) => {
  return new L.DivIcon({
    html: DoorIconRaw,
    iconSize: [DOOR_WIDTH, DOOR_HEIGHT],
    iconAnchor: [DOOR_WIDTH / 2, DOOR_HEIGHT / 2],
    className: classNames(
      styles.icon,
      getDoorIdClass(door.id),
    ),
  })
}

type DraggableDoorProps = {
  door: Door
  board: GameBoard
  boardCoords: BoardCoords
}

export const DraggableDoor: React.FC<DraggableDoorProps> = ({ door, board, boardCoords }) => {
  const { updateExplorationDoor } = useGame()

  const getX = useCallback(
    (x: number) => {
      return boardCoords.xZero + boardCoords.xMultiplier * x
    },
    [boardCoords.xMultiplier, boardCoords.xZero],
  )

  const getY = useCallback(
    (y: number) => {
      return boardCoords.yMax - (boardCoords.yZero + boardCoords.yMultiplier * y) + boardCoords.yZero
    },
    [boardCoords.yMax, boardCoords.yMultiplier, boardCoords.yZero],
  )

  const reverseX = useCallback(
    (x: number) => {
      return (x - boardCoords.xZero) / boardCoords.xMultiplier
    },
    [boardCoords.xMultiplier, boardCoords.xZero],
  )

  const reverseY = useCallback(
    (y: number) => {
      return 100 - (y - boardCoords.yZero) / boardCoords.yMultiplier
    },
    [boardCoords.yMultiplier, boardCoords.yZero],
  )

  const eventHandlers = useMemo(
    () => ({
      dragend(evt: any) {
        updateExplorationDoor(board, {
          ...door,
          longitude: reverseX(evt.target._latlng.lng),
          latitude: reverseY(evt.target._latlng.lat),
        })
      },
    }),
    [board, door, updateExplorationDoor, reverseX, reverseY],
  )

  return (
    <>
      <Marker
        alt={door.id + ''}
        key={door.id}
        draggable={true}
        icon={getIcon(door)}
        position={[getY(door.latitude), getX(door.longitude)]}
        eventHandlers={eventHandlers}
      />
      <Tooltipper
        classIdentifier={getDoorIdClass(door.id)}
        large
        center
        sticky
        autoActivate={door.leadsToBoardIndex === -1}
        tooltipElement={<DoorPopup door={door} />}
      />
    </>
  )
}


