import * as React from 'react'
import { SVGProps } from 'react'
const SvgGoogleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 25 24' {...props}>
    <g clipPath='url(#google_icon_svg__a)'>
      <path
        fill='#FBBB00'
        d='m5.485 14.503-.835 3.119-3.053.064A11.946 11.946 0 0 1 .167 12c0-1.99.483-3.867 1.341-5.519l2.72.498 1.19 2.702A7.133 7.133 0 0 0 5.033 12c0 .88.16 1.725.452 2.503Z'
      />
      <path
        fill='#518EF8'
        d='M23.957 9.758a12.028 12.028 0 0 1-.053 4.747 11.998 11.998 0 0 1-4.225 6.853v-.001l-3.425-.175-.484-3.025a7.152 7.152 0 0 0 3.077-3.652H12.43V9.758H23.957Z'
      />
      <path
        fill='#28B446'
        d='M19.678 21.357h.001A11.95 11.95 0 0 1 12.167 24c-4.57 0-8.543-2.554-10.57-6.313l3.888-3.184a7.135 7.135 0 0 0 10.285 3.654l3.908 3.2Z'
      />
      <path
        fill='#F14336'
        d='M19.826 2.763 15.94 5.945a7.137 7.137 0 0 0-10.52 3.736l-3.91-3.2A11.998 11.998 0 0 1 12.166 0a11.95 11.95 0 0 1 7.66 2.763Z'
      />
    </g>
    <defs>
      <clipPath id='google_icon_svg__a'>
        <path fill='#fff' d='M.167 0h24v24h-24z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgGoogleIcon

