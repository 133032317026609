import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_2_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-4.166 17v-1.68l4.32-4.08c.342-.31.592-.587.752-.832s.267-.47.32-.672c.064-.203.096-.39.096-.56 0-.448-.154-.79-.464-1.024-.298-.245-.741-.368-1.328-.368-.47 0-.906.09-1.312.272a2.55 2.55 0 0 0-1.008.848l-1.888-1.216c.427-.64 1.024-1.147 1.792-1.52s1.654-.56 2.656-.56c.832 0 1.558.139 2.176.416.63.267 1.115.645 1.456 1.136.352.49.528 1.077.528 1.76 0 .363-.048.725-.144 1.088-.085.352-.266.725-.544 1.12-.266.395-.661.837-1.184 1.328l-2.582 2.432h4.822V18zm-7.497 1.15-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-4.166 17v-1.68l4.32-4.08c.342-.31.592-.587.752-.832s.267-.47.32-.672c.064-.203.096-.39.096-.56 0-.448-.154-.79-.464-1.024-.298-.245-.741-.368-1.328-.368-.47 0-.906.09-1.312.272a2.55 2.55 0 0 0-1.008.848l-1.888-1.216c.427-.64 1.024-1.147 1.792-1.52s1.654-.56 2.656-.56c.832 0 1.558.139 2.176.416.63.267 1.115.645 1.456 1.136.352.49.528 1.077.528 1.76 0 .363-.048.725-.144 1.088-.085.352-.266.725-.544 1.12-.266.395-.661.837-1.184 1.328l-2.582 2.432h4.822V18zm-7.497 1.15-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_2_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel2Icon

