import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { cloneLibraryGame, copyOwnGame, deleteOwnGame, getCombinedSearch, PAGE_SIZE } from '../api/gameApiService'
import { LibrarySource } from '../api/gameTypes'
import { parseGamesResponseToGameCards } from '../api/typeConverters'
import { PaginationSelectOption } from '../composites/GamesOverview/components/PaginationControls'
import { useGameNotifications } from '../hooks/useGameNotifications'
import { TGameCard } from '../types/commonTypes'
import { noop } from '../util/functional'
import {
  GameDataProviderInterface,
  GameFilters,
  GamesSourceCount,
  useGameData as useOwnGameData,
} from './OwnGamesContextProvider'

const initialGamesCount: GamesSourceCount = {
  total: 0,
  community: 0,
  orgLibrary: 0,
  own: 0,
  template: 0,
}

export const MAX_COMMUNITY_COUNT_IN_COMBINED = 50

// TODO: Add last
const CombinedSearchContext = createContext<GameDataProviderInterface>({
  currentPage: 1,
  pageSize: PAGE_SIZE,
  pageData: [] as TGameCard[],
  loading: false,
  lastPage: 1,
  allPageOptions: [] as PaginationSelectOption[],
  selectPageSize: noop,
  prev: noop,
  next: noop,
  setPage: noop,
  copyGame: noop,
  deleteGame: noop,
  importGame: noop,
  refreshGames: noop,
  filters: {} as GameFilters,
  setFilters: noop,
  gamesSourceCount: initialGamesCount,
})

export const CombinedSearchContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState<TGameCard[]>([])
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)
  const [loading, setLoading] = useState(false)
  const [lastPage, setLastPage] = useState<number>(1)
  const [allPageOptions, setAllPageOptions] = useState<PaginationSelectOption[]>([{ value: 1, label: '1' }])
  const [filters, setFilters] = useState<GameFilters>({ search: '' } as GameFilters)
  const [gamesSourceCount, setGamesSourceCount] = useState<GamesSourceCount>(initialGamesCount)
  const ownGameData = useOwnGameData()
  const [refreshToken, setRefreshToken] = useState<number>(0)

  const {
    notifyGameImportStart,
    notifyGameImportFinish,
    notifyGameDuplicateStart,
    notifyGameDuplicateFinish,
    notifyGameDeleteStart,
    notifyGameDeleteFinish,
    notifyFetchingGamesFailed,
  } = useGameNotifications()

  const next = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState + 1)
  }, [loading])

  const prev = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState - 1)
  }, [loading])

  const selectPageSize = useCallback(
    (newPageSize: number) => {
      if (loading) {
        return
      }

      setCurrentPage(1)
      setPageSize(newPageSize)
    },
    [loading],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (loading) {
        return
      }

      setCurrentPage(newPage)
    },
    [loading],
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [filters.search, filters.source])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    if (!filters.search || filters.search.trim().length < 3) {
      return
    }
    getCombinedSearch({
      search: filters.search,
      subject: filters.subject,
      language: filters.language,
      age: filters.age,
      updatedAt: filters.updatedAt,
      approvedBySeppo: filters.approvedBySeppo != null,
      source: filters.source,
      perPage: pageSize,
      page: currentPage,
    })
      .then((combinedGamesResponse) => {
        if (combinedGamesResponse.success) {
          const { community_count, own_count, org_count, template_count, all_games } = combinedGamesResponse.value
          const totalCount = community_count + own_count + org_count + template_count
          const effectiveCount =
            filters.source === LibrarySource.COMMUNITY
              ? community_count
              : filters.source === LibrarySource.ORG
              ? org_count
              : filters.source === LibrarySource.OWN
              ? own_count
              : filters.source === LibrarySource.TEMPLATE
              ? template_count
              : community_count <= MAX_COMMUNITY_COUNT_IN_COMBINED
              ? totalCount
              : totalCount - community_count + MAX_COMMUNITY_COUNT_IN_COMBINED
          setGamesSourceCount({
            total: totalCount,
            own: own_count,
            orgLibrary: org_count,
            community: community_count,
            template: template_count,
          })
          setLastPage(Math.ceil(effectiveCount / pageSize))
          setPageData(
            parseGamesResponseToGameCards({
              my_games: all_games,
              count: effectiveCount,
              active_count: 0,
            }),
          )
        } else {
          notifyFetchingGamesFailed()
          console.error(combinedGamesResponse.error)
        }
      })
      .finally(() => setLoading(false))
    return () => abortController.abort()
  }, [currentPage, pageSize, filters, notifyFetchingGamesFailed, refreshToken])

  useEffect(() => {
    const calculateAllPageOptions = Array.from({ length: lastPage }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }))
    setAllPageOptions(calculateAllPageOptions)
  }, [lastPage])

  const importGame = useCallback(
    async (gameId: number, gameName: string) => {
      const notifyId = notifyGameImportStart(gameName)
      const cloneResponse = await cloneLibraryGame({ gameId })
      if (cloneResponse.success) {
        ownGameData.refreshGames()
        setRefreshToken((prev) => prev + 1)
      } else {
        console.error(cloneResponse.error)
      }
      notifyGameImportFinish(notifyId, gameName, cloneResponse.success)
    },
    [notifyGameImportStart, notifyGameImportFinish, ownGameData],
  )

  const copyGame = useCallback(
    async (gameId: number, gameName: string) => {
      const notifyId = notifyGameDuplicateStart(gameName)
      const copyResponse = await copyOwnGame({ gameId })
      if (copyResponse.success) {
        ownGameData.refreshGames()
        setRefreshToken((prev) => prev + 1)
      } else {
        console.error(copyResponse.error)
      }
      notifyGameDuplicateFinish(notifyId, gameName, copyResponse.success)
    },
    [notifyGameDuplicateFinish, notifyGameDuplicateStart, ownGameData],
  )

  const deleteGame = useCallback(
    async (gameId: number, gameName: string, force = false) => {
      const notifyId = notifyGameDeleteStart(gameName)
      const deleteResponse = await deleteOwnGame({ gameId, force })
      if (deleteResponse.success) {
        ownGameData.refreshGames()
        setRefreshToken((prev) => prev + 1)
      } else {
        console.error(deleteResponse.error)
      }
      notifyGameDeleteFinish(notifyId, gameName, deleteResponse.success)
    },
    [ownGameData, notifyGameDeleteStart, notifyGameDeleteFinish],
  )

  const updateFilters = useCallback((newFilters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => {
    setFilters((prev) => (isPartial ? { ...prev, ...newFilters } : (newFilters as GameFilters)))
  }, [])

  return (
    <CombinedSearchContext.Provider
      value={{
        currentPage,
        pageSize,
        pageData,
        lastPage,
        allPageOptions,
        loading,
        setPage,
        selectPageSize,
        next,
        prev,
        copyGame,
        deleteGame,
        importGame,
        refreshGames: noop,
        filters,
        setFilters: updateFilters,
        gamesSourceCount,
      }}
    >
      {children}
    </CombinedSearchContext.Provider>
  )
}

export const useGameData = () => {
  const context = useContext(CombinedSearchContext)

  return context
}
