import { useCallback, useMemo, useState } from 'react'
import { SingleValue } from 'react-select'
import { Select, SelectElement } from '../../../common/components/Select/Select'
import styles from '../Search.module.css'
import { RemoveFilterButton } from './RemoveFilterButton'
import { SelectFilterOption, SelectFilterProps } from './types'

export const SelectFilter: React.FC<SelectFilterProps> = ({
  icon,
  label,
  onChange,
  options,
  initialValue,
  onRemove,
  isSearchable,
}) => {
  const [defaultValue] = useState<SingleValue<SelectFilterOption>>(() => {
    return options.find((option) => option?.value === initialValue) ?? null
  })

  const onChangeInternal = useCallback((selectOption: SingleValue<SelectFilterOption>) => {
    onChange(selectOption?.value ?? null)
  }, [onChange])

  const onRemoveInternal = useCallback(() => {
    onRemove?.()
    onChange(null)
  }, [onRemove, onChange])

  const prefixElement = useMemo<SelectElement | undefined>(() => {
    return icon != null ? ({
      type: 'icon',
      icon: {
        name: icon,
      },
      styles: {
        paddingBottom: '0.1rem',
      },
    } as SelectElement) : undefined
  }, [icon])

  return (
    <div className={styles.singleFilterContainer}>
      <Select
        options={options as any}
        defaultValue={defaultValue as any}
        isSearchable={isSearchable}
        onChange={onChangeInternal}
        isClearable
        placeholder={label}
        prefixElement={prefixElement}
        className={onRemove != null ? styles.removableSelect : undefined}
      />
      { onRemove != null && <RemoveFilterButton onClick={onRemoveInternal}/> }
    </div>
  )
}
